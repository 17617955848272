import React, { useContext, useEffect, useState } from 'react';
import './dodatneOpcije.scss';
import { Context } from '../../../../contexts/Store';
import actionTypes from '../../../constants/actionTypes';
import { useSadrzajEvent } from '../../../../services/api';
import { API } from '../../../constants/api';
import { toast } from 'react-toastify';
import Loader from '../../Loader';

const linesToRender = [
    {
        text: 'Komentarisanje',
        type: 'komentari'
    },
    {
        text: 'Prikaži ime autora',
        type: 'imeAutora'
    },
    {
        text: 'Sponzorisan tekst',
        type: 'sponzorisaniTekst'
    }
];
export default () => {
    const [state, dispatch] = useContext(Context);

    const isKolumna = state.showModal.data && state.showModal.data.isKolumna;
    const [opcije, setOpcije] = useState({
        komentari: state.sadrzaj_teksta_dodatne_opcije ? state.sadrzaj_teksta_dodatne_opcije.komentari : true,
        imeAutora: isKolumna
            ? true
            : state.sadrzaj_teksta_dodatne_opcije
            ? state.sadrzaj_teksta_dodatne_opcije.imeAutora
            : !state.user.sakrijIme,
        procitajSledece: state.sadrzaj_teksta_dodatne_opcije
            ? state.sadrzaj_teksta_dodatne_opcije.procitajSledece
            : true,
        sponzorisaniTekst: state.sadrzaj_teksta_dodatne_opcije ? state.sadrzaj_teksta_dodatne_opcije.sponzorisaniTekst : false
    });
    const [updateEvent, setUpdateEvent] = useState(false);

    const renderLine = ({ text, type }) => {
        return (
            <div className={`inline-txt ${isKolumna && type === 'imeAutora' ? 'inline-txt--kolumna' : ''}`} key={text}>
                <span>{text}:</span>
                <input
                    type={'checkbox'}
                    checked={opcije[type]}
                    onChange={event => {
                        setOpcije({ ...opcije, [type]: event.target.checked });
                    }}
                />
            </div>
        );
    };

    const { response: responseForUpdate, error: updateError, loading: loadingForUpdate } = useSadrzajEvent(
        API.SADRZAJ.UPDATE.ROOT +
            API.SADRZAJ.UPDATE.ID.replace(':id', state.isEditingTekst.id) +
            API.SADRZAJ.UPDATE.DODATNE_OPCIJE,
        'POST',
        updateEvent,
        {
            dodatneOpcije: {
                imeAutora: opcije.imeAutora,
                komentari: opcije.komentari,
                procitajSledece: opcije.procitajSledece,
                sponzorisaniTekst: opcije.sponzorisaniTekst
            }
        },
        false
    );

    useEffect(() => {
        if (responseForUpdate || updateError) {
            toast(responseForUpdate ? 'Dodatne opcije sačuvane.' : 'Greška.');
            dispatchSave();
        }
    }, [responseForUpdate]);

    const dispatchSave = () => {
        dispatch({
            type: actionTypes.SADRZAJ_TEKSTA_SAVE_DODATNE_OPCIJE,
            payload: opcije
        });
    };
    return (
        <div className={'dodatne-opcije-pop'}>
            <h2>Dodatne opcije</h2>
            <hr />
            {linesToRender.map(el => renderLine(el))}
            {loadingForUpdate ? (
                <Loader />
            ) : (
                <>
                    <div style={{ textAlign: 'right' }}>
                        <button
                            className={'btn-save'}
                            onClick={() => {
                                if (state.isEditingTekst.hasValue) {
                                    setUpdateEvent(true);
                                    return;
                                }
                                dispatchSave();
                            }}>
                            Sačuvaj
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};
