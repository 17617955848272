import React from 'react';
import Modal from 'react-responsive-modal';
import { Bar, Doughnut } from 'react-chartjs-2';
import { chartTypes } from '../../../constants/constants';

const colors = ['#ee404d', '#f9aa1b', '#189fb3', '#413684', '#d7d9dd', '#6a6a6a', '#373737'];


export default React.memo(({ open = false, setPreviewAnketa, anketa }) => {
    const labels = anketa.odgovori.map(el => {
        return el.value;
    });

    const partValue = 1 / labels.length;
    const data = Array(labels.length).fill(partValue);

    const inputDataPieChart = {
        labels: labels,
        datasets: [{ data: data, backgroundColor: colors }],
    };

    return (
        <Modal
            open={open}
            onClose={() => setPreviewAnketa(false)}
            classNames={{
                modal: 'anketa-modal-preview'
            }}
            overlayId={'galery-preview-modal'}>
            <h2>{anketa.pitanje.value}</h2>

            {anketa.chartType === chartTypes.PIE_CHART ? (
                <Doughnut data={inputDataPieChart   } />
            ) : (
                <Bar
                    data={inputDataPieChart}
                    options={{
                        maintainAspectRatio: true,
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    color: "rgba(0, 0, 0, 0)",
                                }
                            }],
                            yAxes: [{
                                gridLines: {
                                    color: "rgba(0, 0, 0, 0)",
                                }
                            }]
                        }
                    }}
                    legend={''}

                />
            )}
        </Modal>
    );
});
