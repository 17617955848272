import './IstrazivaliSmo.scss';
import React from 'react';
import SectionTitle from '../../../common/components/SectionTitle';
import BigCard from '../../../common/components/BigCard';
import LeaderboardCard from '../../../common/components/LeaderboardCard';
import { IMAGE_SIZES_BIG_CARD, rubrikeTypes } from '../../../common/helpers/strings';

export default () => {
    return (
        <div className={'istrazivali-smo-container'}>
            <SectionTitle title={'Izdvajamo'} />

            <div className={'istrazivali-smo-container__content'}>
                <LeaderboardCard cardColor={'black'} position={'1'} rubrikaType={rubrikeTypes.ISTRAZIVALI_SMO} />
                <BigCard
                    imageModifier={IMAGE_SIZES_BIG_CARD.HEIGHT_120}
                    position={'2'}
                    rubrikaType={rubrikeTypes.ISTRAZIVALI_SMO}
                />
            </div>
        </div>
    );
};
