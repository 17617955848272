import React from 'react';
import LeaderboardCard from '../../../../common/components/LeaderboardCard';
import TwoBigThreeSmallCards from '../../../../common/components/TwoBigThreeSmallCards';
import SectionTitle from '../../../../common/components/SectionTitle';

export default ({title, podrubrikaId}) => {
    return (
        <div >
            <SectionTitle title={title}/>
            <LeaderboardCard rubrikaType={podrubrikaId} position={'1'}/>
            <div className={'infos-container__section-bottom'}>
                <TwoBigThreeSmallCards hasPicture={false} bigPositions={['2', '3']} rubrikaType={podrubrikaId} smallPositions={['4','5','6']}/>
            </div>
        </div>
    );
};
