import actionTypes from '../common/constants/actionTypes';
import { chartTypes } from '../common/constants/constants';

const Reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.RESET_GLOBAL_STATE:
      return {
        posts: [],
        error: null,
        userToken: state.userToken,
        showModal: false,
        user: state.user,
        ucitaneRubrike : JSON.parse(localStorage.getItem('ucitaneRubrike')) || null,
        isEditingTekst: {
          hasValue: false,
          id:-1,
        },
      };
    case actionTypes.UCITAJ_RUBRIKE:
      localStorage.setItem('ucitaneRubrike', JSON.stringify(action.payload))
      return {
        ...state,
        ucitaneRubrike: action.payload
      }
    case 'UPDATE_ZAPOSLENI':
      return {
        ...state,
        updatedZaposleni: action.payload
      }
    case actionTypes.DELETE_ZAPOSLENI_CONFIRM:
      return {
        ...state,
        deleteZaposleni: action.payload
      }
    case actionTypes.BAN_KORISNIKA_UPDATE_STATE:
      return {
        ...state,
        ban_korisnika_update_state: action.payload
      }
    case actionTypes.KOMENTAR_KORISNIKA_UPDATE_STATE:
      return {
        ...state,
        komentar_korisnika_update: action.payload
      }
    case actionTypes.KOMENTAR_KORISNIKA_UPDATE_DELETE_STATUS:
      return {
        ...state,
        komentar_korisnika_delete: action.payload
      }
    case actionTypes.SADRZAJ_TEKSTA_SAVE_SHEDULE_DATE:
      return {
        ...state,
        showModal:false,
        sadrzaj_teksta_schedule_date: action.payload
      }
    case actionTypes.SADRZAJ_TEKSTA_SAVE_PHOTO:
      return {
        ...state,
        showModal: false,
        sadrzaj_teksta_photo: action.payload,
      }
    case actionTypes.SADRZAJ_TEKSTA_SAVE_GALLERY:
      return {
        ...state,
        showModal: false,
        sadrzaj_teksta_gallery: action.payload,
      }
    case actionTypes.SADRZAJ_TEKSTA_SAVE_DODATNE_OPCIJE:
      return {
        ...state,
        showModal: false,
        sadrzaj_teksta_dodatne_opcije: action.payload,
      }
    case actionTypes.SADRZAJ_TEKSTA_SAVE_ANKETA:
      return {
        ...state,
        showModal: false,
        sadrzaj_teksta_anketa: action.payload,
      }
    case actionTypes.SADRZAJ_TEKSTA_SAVE_KONTAKT_US:
      return {
        ...state,
        showModal: false,
        sadrzaj_teksta_kontakt_us: action.payload,
      }
    case actionTypes.SADRZAJ_TEKSTA_LOAD_RESPONSE_INTO_STATE:
      return {
          ...state,
          isEditingTekst: {
            hasValue: true,
            id:action.payload.id,
          },
          showModal: false,
          sadrzaj_teksta_anketa: action.payload.anketa ? {
            pitanje: action.payload.anketa.pitanje,
            chartType: action.payload.anketa.chartType === 0 ? chartTypes.BAR_CHART : chartTypes.PIE_CHART,
            odgovori: action.payload.anketa.odgovori.map(el => {return {...el,odgovor:el.odgovor}})
          } : null,
          sadrzaj_teksta_dodatne_opcije: {
              komentari: action.payload.allowComments,
              imeAutora: action.payload.showAutor,
              procitajSledece: action.payload.procitajSledece,
              sponzorisaniTekst: action.payload.sponzorisaniTekst
          },
          sadrzaj_teksta_gallery: action.payload.galerija && action.payload.galerija.length> 0 ?  action.payload.galerija.map(el => {
              return { ...el, img: el.slika };
          }) : null,
          sadrzaj_teksta_photo: action.payload.naslovnaFotografija ? { ...action.payload.naslovnaFotografija, img: action.payload.naslovnaFotografija.slika } : null
      };
    case actionTypes.SHOW_MODAL:
      return {
        ...state,
        showModal: action.payload
      }
    case actionTypes.REFRESH_DATA:
      return {
        ...state,
        refreshData: new Date().getTime()
      }
    case 'HIDE_MODAL':
      return {
        ...state,
        showModal: false
      }
    case 'LOGIN':
      if (action.payload !== undefined) {
        localStorage.setItem('userToken', action.payload)
      }
      return {
        ...state,
        userToken: action.payload
      }
    case 'LOGOUT':
      localStorage.clear();
      return {
        ...state,
        userToken: null
      }
    case 'SET_POSTS':
      return {
        ...state,
        posts: action.payload
      }
    case 'ADD_POST':
      return {
        ...state,
        posts: state.posts.concat(action.payload)
      }
    case 'REMOVE_POST':
      return {
        ...state,
        posts: state.posts.filter(post => post.id !== action.payload)
      }
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload
      }
      // C
    default:
      return state
  }
}

export default Reducer
