import React, { useContext } from 'react';
import actionTypes from '../../../../common/constants/actionTypes';
import { Context } from '../../../../contexts/Store';

export default React.memo(props => {
    /* render using props */

    const [state, dispatch] = useContext(Context);

    const handleClick = ev => {
        dispatch({ type: actionTypes.SHOW_MODAL, payload: { name: props.opcija.MODAL_TYPE, data: props.data } });
    };
    return (
        <div className={'inline-txt'}>
            <span>
                <b>{props.opcija.TXT}</b>
            </span>
            <button className={'add-btn'} onClick={handleClick}>{`${props.value ? '✓' : '➕'}`}</button>
            {props.opcija.error.length > 0 && <span className={'error'}>{props.opcija.error}</span>}
        </div>
    );
});
