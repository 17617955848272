import React, { useContext, useEffect, useState } from 'react';

import { useTable } from 'react-table';
import { toast } from 'react-toastify';

import { Context } from '../../contexts/Store';
import { API } from '../../common/constants/api';

import actionTypes from '../../common/constants/actionTypes';
import Loader from '../../common/components/Loader';

import { useBannerEvent, useSadrzajEvent } from '../../services/api';

import './banners.scss';

const Banners = () => {
  const [data, setData] = useState([]);
  const [bannerStatusState, setBannerStatusState] = useState({
    value: false,
    ts: null,
  });
  const [state, dispatch] = useContext(Context);
  const [deleteBanner, setDeleteBanner] = useState(undefined);


  const { response, loading } = useBannerEvent(
    API.BANERI,
    'GET',
    state.refreshData || location,
  );

  const { response: bannerStatusResponse } = useBannerEvent(
    '/sadrzaj/get-banner-status',
    'GET',
    true,
  );

  const { response: editStatusResponse } = useSadrzajEvent(
    '/edit-banner-status',
    'POST',
    bannerStatusState.ts,
    {
      status: bannerStatusState.value,
    },
  );

  const { response: deleteResponse } = useSadrzajEvent(
    API.BANERI_SOLO + '/delete',
    'POST',
    deleteBanner,
    {
      id: deleteBanner,
    },
  );

  useEffect(() => {
    if (bannerStatusResponse?.data) {
      setBannerStatusState({
        ...bannerStatusState,
        value: bannerStatusResponse.data.status,
      });
    }
  }, [bannerStatusResponse]);

  useEffect(() => {
    if (deleteResponse) {
      toast('Kampanja obrisana.');
      setData(data.filter(el => el.id !== deleteBanner));
    }
  }, [deleteResponse]);

  const onDeleteClick = (id) => {
    if (window.confirm('Da li ste sigurni')) {
      setDeleteBanner(id);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Lista kampanja',
        columns: [
          {
            Header: 'Ime kampanje',
            accessor: 'title',
          },
          {
            Header: 'Broj klikova',
            accessor: 'clickCount',
            Cell: props => <span>{props.row.original.clickCount ?? 0}</span>,
          },
          {
            Header: 'Opcije',
            accessor: 'id',
            shouldSort: false,
            sortType: 'options',
            Cell: props => <span className="options" style={{ maxWidth: '120px', margin: '0 auto' }}>
              <a onClick={() => {
                dispatch({
                  type: 'SHOW_MODAL',
                  payload: { name: actionTypes.MANAGE_BANNER, data: props.row.original },
                });
              }}>Izmijeni</a>
              <a onClick={() => onDeleteClick(props.row.original.id)}>Obriši</a>
            </span>,
          },
        ],
      },
    ],
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      data,
      columns,
    });

  useEffect(() => {
    if (response?.data) {
      setData(response?.data.list);
    }
  }, [response]);


  if (loading) return <Loader
    type="Oval"
    color="#00BFFF"
    height={32}
    width={32}/>;

  return (
    <div>
      <div className={'banners-head'}>
        <div className={'banners__content'}>
          <button onClick={() => {
            dispatch({ type: 'SHOW_MODAL', payload: { name: actionTypes.MANAGE_BANNER, data: null } });
          }}>Nova kampanja
          </button>

          <div className={'banners__status'}>
            <input type={'checkbox'}
                   checked={bannerStatusState.value}
                   onChange={event => {
                     setBannerStatusState({
                       value: event.target.checked,
                       ts: new Date().getTime(),
                     });
                   }}/>
            <span>Prikaži poslednju kampanju na sajtu</span>
          </div>
        </div>

      </div>
      <table {...getTableProps()} className={'tabela'}>
        <thead>
        {headerGroups.map((headerGroup, index) => (
          <tr key={'banners-hedaer' + index} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, colIndex) => (
              <th key={'banners-col-ondex' + index + '_' + colIndex} {...column.getHeaderProps()}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {rows.map(
          (row, i) => {
            prepareRow(row);
            return (
              <tr key={'banners-body-' + i} {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  return <td key={'banners-td-' + i + '_' + index} {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
              </tr>
            );
          },
        )}
        </tbody>
      </table>
    </div>
  );
};

export default Banners;
