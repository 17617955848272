import React, { useContext, useEffect } from 'react';
import Select from '../../../../common/components/Select';
import { useRubrikeEvent } from '../../../../services/api';
import { API } from '../../../../common/constants/api';
import { METHOD_TYPES } from '../../../../common/helpers/api';
import actionTypes from '../../../../common/constants/actionTypes';
import { Context } from '../../../../contexts/Store';
import Loader from '../../../../common/components/Loader';

export const rubrikeToSkip = (rubrika) => {
    return !rubrika.path.match(/istrazivali_smo|bnews/);
}
export default ({ sadrzaj, setSadrzaj }) => {
    const [state, dispatch] = useContext(Context);

    const { response: rubrikeResponse } = useRubrikeEvent(
        API.RUBRIKE.SVE,
        METHOD_TYPES.GET,
        state.ucitaneRubrike === undefined || state.ucitaneRubrike === null,
        null,
        false
    );

    useEffect(() => {
        if (rubrikeResponse) {
            dispatch({ type: actionTypes.UCITAJ_RUBRIKE, payload: rubrikeResponse.data });
            setSadrzaj({
                ...sadrzaj,
                selectedRubrika: rubrikeResponse.data[0],
                selectedPodrubrika: rubrikeResponse.data[0].podrubrika[0]
            });
        }
    }, [rubrikeResponse]);

    const rubrikaChangeEvent = event => {
        const newRubrika = state.ucitaneRubrike.find(el => el.name === event.target.value);
        setSadrzaj({ ...sadrzaj, selectedRubrika: state.ucitaneRubrike.find(el => el.name === event.target.value),
        selectedPodrubrika: newRubrika.podrubrika[0]
        });
    };

    const podrubrikaChangeEvent = event => {
        const selectedPodrubrikaName = event.target.value;
        const selectedPodrubrika = sadrzaj.selectedRubrika.podrubrika.find(el => el.name === selectedPodrubrikaName);

        setSadrzaj({ ...sadrzaj, selectedPodrubrika: selectedPodrubrika });
    };

    if(!state.ucitaneRubrike){
        return <Loader/>
    }

    return (
        <>
            <div className={'inline-txt'}>
                <span>
                    <b>Rubrika: </b>
                </span>
                <Select
                    data={state.ucitaneRubrike.filter(rubrika => rubrikeToSkip(rubrika))}
                    changeEvent={rubrikaChangeEvent}
                    selectedItemId={sadrzaj.selectedRubrika ? sadrzaj.selectedRubrika.id : null}
                />
            </div>

            <div className={'inline-txt'}>
                <span>
                    <b>Podrubika:</b>
                </span>
                <Select
                    data={sadrzaj.selectedRubrika && sadrzaj.selectedRubrika.podrubrika}
                    changeEvent={podrubrikaChangeEvent}
                    selectedItemId={sadrzaj.selectedPodrubrika ? sadrzaj.selectedPodrubrika.id : null}
                />
            </div>
        </>
    );
};
