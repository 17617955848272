import React from 'react';
import { API } from '../../common/constants/api';
import { useHistory } from 'react-router';

const destinationType = {
    POZICIONIRAJ: API.UREDI_SADRZAJ.ROOT + API.UREDI_SADRZAJ.POZICIONIRAJ,
    REPOZICIONIRAJ: API.UREDI_SADRZAJ.ROOT + API.UREDI_SADRZAJ.REPOZICIONIRAJ
};

export default () => {
    const router = useHistory();

    const goTo = path => {
        router.push(path);
    };
    return (
        <div>
            <button className={'btn-save'} onClick={() => goTo(destinationType.POZICIONIRAJ)}>
                Pozicioniraj sadržaj
            </button>
            <button className={'btn-save'} onClick={() => goTo(destinationType.REPOZICIONIRAJ)}>
                Repozicioniraj/Izbriši pozicije
            </button>
        </div>
    );
};
