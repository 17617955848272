import { STATUS_SADRZAJA } from '../../../../common/constants/constants';

export default (sadrzaj,setSadrzaj, statusSadrzaja) =>{
    if (sadrzaj.naslov.value.length < 2) {
        const msg = 'Morate unijeti naslov';
        setSadrzaj({
            ...sadrzaj,
            naslov: { value: sadrzaj.naslov.value, error: msg }
        });
        return {result: false, error: msg};
    }
    if(statusSadrzaja === STATUS_SADRZAJA.DRAFT){
        return {result: true};
    }

    if (sadrzaj.podnaslov.value.length < 2) {
        const msg = 'Morate unijeti podnaslov';
        setSadrzaj({
            ...sadrzaj,
            podnaslov: { value: sadrzaj.podnaslov.value, error: msg }
        });
        return {result: false, error: msg};

    }

    if (sadrzaj.autori.values.length === 0) {
        const msg = 'Morate unijeti autora';

        setSadrzaj({
            ...sadrzaj,
            autori: { values: sadrzaj.autori.values, error: msg }
        });

        return {result: false, error: msg};
    }

    if(!sadrzaj.NASLOVNA.value){
        const msg = 'Izaberite naslovnu fotografiju';

        setSadrzaj({
            ...sadrzaj,
            NASLOVNA: {...sadrzaj.NASLOVNA,error: msg}
        });
        return {result: false, error: msg};
    }

    return {result: true};
}
