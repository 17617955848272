import './TwoHalfRowCards.scss';
import BigCard from './BigCard';
import React from 'react';

export default ({positions = ['1','2'], rubrikaType}) => {
    return (
        <div className={'two-big-cards'}>
            <BigCard  position={positions[0]} rubrikaType={rubrikaType}/>
            <BigCard  position={positions[1]} rubrikaType={rubrikaType}/>
        </div>
    );
};
