import useApi from '../hooks/useApi';
import { API } from '../common/constants/api';
import { generateFormData } from '../common/helpers/api';


export function useLoginEvent (route, trigger = undefined, dataObj) {
  const { response, error, loading } = useApi({
    method: 'POST',
    url: `${API.BASE}${route}`,
    trigger: trigger,
    options: {
      data: dataObj
    }
  })
  return { response, error, loading }
}

export function useSelfEvent (route, trigger = undefined, dataObj) {
  const { response, error, loading } = useApi({
    method: 'POST',
    url: `${API.BASE}${route}`,
    trigger: trigger,
    options: {
      data: dataObj
    }
  })
  return { response, error, loading }
}


export function useZaposleniEvent (route, method = 'GET', trigger = undefined, dataObj, postMultiPart = false) {
  const { response, error, loading } = useApi({
    method: method,
    url: `${API.BASE}${API.ZAPOSLENI.ROOT}${route}`,
    trigger: trigger,
    options: {
      data: postMultiPart ? generateFormData(dataObj) : dataObj,
    },
    postMultiPart:postMultiPart
  });
  return { response, error, loading }
}

export function useBannerEvent (route, method = 'GET', trigger = undefined, dataObj, postMultiPart = false) {
  const { response, error, loading } = useApi({
    method: method,
    url: `${API.BASE}${route}`,
    trigger: trigger,
    options: {
      data: postMultiPart ? generateFormData(dataObj) : dataObj,
    },
    postMultiPart:postMultiPart
  });
  return { response, error, loading }
}

export function useSadrzajEvent (route, method = 'GET', trigger = undefined, dataObj, postMultiPart = false) {
  const { response, error, loading } = useApi({
    method: method,
    url: `${API.BASE}${API.SADRZAJ.ROOT}${route}`,
    trigger: trigger,
    options: {
      data: postMultiPart ? generateFormData(dataObj) : dataObj,
    },
    postMultiPart:postMultiPart
  });
  return { response, error, loading }
}


export function useRubrikeEvent (route, method = 'GET', trigger = undefined, dataObj, postMultiPart = false) {
  const { response, error, loading } = useApi({
    method: method,
    url: `${API.BASE}${API.RUBRIKE.ROOT}${route}`,
    trigger: trigger,
    options: {
      data: postMultiPart ? generateFormData(dataObj) : dataObj,
    },
    postMultiPart:postMultiPart
  });
  return { response, error, loading }
}


export function useCommentsEvent (route, method = 'GET', trigger = undefined, dataObj) {
  const { response, error, loading } = useApi({
    method: method,
    url: `${API.BASE}${API.KOMENTARI.ROOT}${route}`,
    trigger: trigger,
    options: {
      data: dataObj
    }
  });
  return { response, error, loading }
}

export function useNotifikacijeEvent (route, method = 'POST', trigger = undefined, dataObj) {
  const {response, error, loading} = useApi({
    method: method,
    url: `${API.BASE}${API.NOTIFIKACIJE.ROOT}${route}`,
    trigger: trigger,
    options: {
      data: dataObj
    }
  });
  return {response, error, loading}
}
export function usePozicijeEvent (route, method = 'GET', trigger = undefined, dataObj) {
  const { response, error, loading } = useApi({
    method: method,
    url: `${API.BASE}${API.SADRZAJ.ROOT+API.SADRZAJ.POZICIJE.ROOT}${route}`,
    trigger: trigger,
    options: {
      data: dataObj
    }
  });
  return { response, error, loading }
}

export function useAuthorDetailsEvent (method = 'GET', id) {
  const { response, error, loading } = useApi({
    method: method,
    url: `${API.BASE}${API.ZAPOSLENI.ROOT+API.ZAPOSLENI.DETAILS.replace(":id",id)}`,
    trigger: true
  });
  return { response, error, loading }
}
