import React, { useContext, useEffect, useState } from 'react';
import './sign.scss';
import logo from '../../common/images/logo_vertical.svg';
import { Context } from '../../contexts/Store';
import { useLoginEvent } from '../../services/api';
import { API } from '../../common/constants/api';
import Loader from 'react-loader-spinner';

export default () => {
    const [state,dispatch] = useContext(Context);

    const [loginInfo, setLoginInfo] = useState({
        username: {
            value: '',
            error: ''
        },
        password: {
            value: '',
            error: ''
        },
        request: undefined,
        mainErrorMsg: '',
        zapamti: true
    });

    const tryLogin = () => {
        if (loginInfo.username.value === '') {
            setLoginInfo({ ...loginInfo, username: { error: 'Morate unijeti username' } });
            return;
        }
        if (loginInfo.password.value === '') {
            setLoginInfo({ ...loginInfo, password: { error: 'Morate unijeti password' } });
            return;
        }
        setLoginInfo({ ...loginInfo, request: true });
    };

    const { response, error, loading } = useLoginEvent(API.LOGIN, loginInfo.request, {
        usernameOrEmail: loginInfo.username.value,
        password: loginInfo.password.value
    });

    useEffect(() => {
        if (error) {
            setLoginInfo({ ...loginInfo, request: undefined, mainErrorMsg: 'Pogrešni login podaci' });
            return;
        }

        if (response && loginInfo.request) {
            if (error) {
                setLoginInfo({ ...loginInfo, request: undefined, mainErrorMsg: 'Pogrešni login podaci' });
                return;
            }
            const token = error || response.data.access_token;
            dispatch({ type: 'LOGIN', payload: token });
        }
    }, [response, error]);

    return (
        <div className={'sign-in'}>
            <img src={logo} width={250} className={'sign-in__logo'} />

            <div className={'sign-in__container'}>
                <h2>Ulogujte se</h2>
                <h4 className={'sign-in__h4'}>
                    Korisničko ime / email <span>{loginInfo.username.error}</span>
                </h4>
                <input
                    type={'text'}
                    className={'sign-in__input'}
                    value={loginInfo.username.value}
                    onChange={e =>
                        setLoginInfo({
                            ...loginInfo,
                            username: { value: e.target.value },
                            mainErrorMsg: ''
                        })
                    }
                    onKeyPress={event => {
                        if (event.key === 'Enter') {
                            tryLogin();
                        }
                    }}
                />
                <h4 className={'sign-in__h4'}>
                    Password <span>{loginInfo.password.error}</span>
                </h4>
                <input
                    type={'password'}
                    className={'sign-in__input'}
                    value={loginInfo.password.value}
                    onChange={e =>
                        setLoginInfo({
                            ...loginInfo,
                            password: { value: e.target.value },
                            mainErrorMsg: ''
                        })
                    }
                    onKeyPress={event => {
                        if (event.key === 'Enter') {
                            tryLogin();
                        }
                    }}
                />
                <div style={{ textAlign: 'right', paddingRight: '68px' }}>
                    <h3 style={{ display: 'inline-block', marginRight: '8px' }}>Zapamti podatke</h3>
                    <input
                        type={'checkbox'}
                        checked={loginInfo.zapamti}
                        onChange={e => setLoginInfo({ ...loginInfo, zapamti: e.target.checked })}
                    />
                </div>

                {loading && <Loader type="Oval" color="#00BFFF" height={32} width={32} />}
                <button className={'sign-in__login'} onClick={tryLogin}>
                    Login
                </button>
                <h2 style={{ color: 'red' }}>{loginInfo.mainErrorMsg}</h2>
            </div>
        </div>
    );
};
