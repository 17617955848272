import React, { useContext } from 'react';

import './SmallCard.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { checkPositionSelected, IMAGE_SIZES, MARGIN_BOTTOM_CLASSES, rubrikeTypes } from '../helpers/strings';
import placeholder from '../images/naslovna_placeholder.jpg';
import { ContextPozicije } from '../../contexts/Pozicioniranje';
import actionTypes from '../constants/actionTypes';

export default ({
    includeImage = false,
    imageModifier = IMAGE_SIZES.WIDTH_113,
    marginBottom = MARGIN_BOTTOM_CLASSES.MARGIN_2,
    additionalClassNames = null,
    txtContainerClassName = null,
    position = '1',
    rubrikaType
}) => {
    const [state, dispatch] = useContext(ContextPozicije);

    let aTagClassNmae = (includeImage ? 'small-card small-card--with-image' : 'small-card') + ' ' + marginBottom;
    if (additionalClassNames !== null) {
        additionalClassNames.map(elClass => {
            aTagClassNmae += ' ' + elClass;
        });
    }
    return (
        <a className={aTagClassNmae}>
            {includeImage ? (
                <LazyLoadImage
                    effect="opacity"
                    className={'small-card__img small-card__img' + imageModifier}
                    src={placeholder}
                />
            ) : null}

            <div
                className={`small-card__txt-container ${
                    checkPositionSelected(state, position, rubrikaType) ? ' wireframeSelected' : ''
                }`}
                onClick={() => {
                    dispatch({
                        type: actionTypes.SELECTED_WIREFRAME_POSITION,
                        payload: {
                            position: position,
                            rubrika: rubrikaType
                        }
                    });
                }}>
                {position}
            </div>
        </a>
    );
};
