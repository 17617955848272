import React, { useContext, useEffect, useState } from 'react';
import { useSadrzajEvent } from '../../../services/api';
import { API } from '../../../common/constants/api';
import { METHODS, TIPOVI_PRETRAGE, tipSadzajaFromBackendToText } from '../../../common/constants/constants';
import { defaultSearchState, emptyRubrika } from '../../arhiv';
import ReactPaginate from 'react-paginate';
import { useTable } from 'react-table';
import moment from 'moment';
import actionTypes from '../../../common/constants/actionTypes';
import { ContextPozicije } from '../../../contexts/Pozicioniranje';
import { useRouteMatch } from 'react-router';
import { Context } from '../../../contexts/Store';
import ArhivFilters from '../../arhiv/ArhivFilters';
import Loader from '../../../common/components/Loader';

export default () => {
    const rubrikaPodrubrika = useRouteMatch().params.rubrika;
    const [globalState] = useContext(Context);
    const [state, dispatch] = useContext(ContextPozicije);

    const [searchState, setSearchState] = useState({
        ...defaultSearchState,
        selectedRubrika:
            rubrikaPodrubrika === undefined
                ? emptyRubrika
                : globalState.ucitaneRubrike.find(el => el.path.indexOf(rubrikaPodrubrika) > -1),
        selectedPodrubrika: emptyRubrika,
        pageCount: 1,
        rubrike: globalState.ucitaneRubrike ? [emptyRubrika, ...globalState.ucitaneRubrike] : null,
        returnWithPagination: false,
        sortByNajnovije: true,
        filterPozicija: rubrikaPodrubrika === undefined ? 'pocetna' : rubrikaPodrubrika
    });

    const { error, response, loading } = useSadrzajEvent(
        API.ARHIV,
        METHODS.POST,
        searchState.doRequest,
        {
            ...searchState,
        },
        false
    );

    useEffect(() => {
        if (state.insertSinglePozicija === null) {
            setSearchState(prevState => {
                return {
                    ...prevState,
                    recivedData: prevState.recivedData.map(sadrzaj => {
                        return { ...sadrzaj, checked: false };
                    })
                };
            });
        }
    }, [state.insertSinglePozicija]);

    useEffect(() => {
        if (state.removeCurrentSelectedSadrzaj) {
            setSearchState({
                ...searchState,
                recivedData: searchState.recivedData.filter(el => el.id !== state.selectedSadrzaj)
            });
            dispatch({
                type: actionTypes.RESET_SELECTED_WIREFRAME_POSITION,
                payload: null
            });
        }
    }, [state.removeCurrentSelectedSadrzaj]);
    useEffect(() => {
        if (error) {
            setSearchState({ ...searchState, doRequest: false });
            return;
        }
        //code
        if (response) {
            setSearchState({
                ...searchState,
                recivedData: response.data.list ? response.data.list.map(el => {
                    return { ...el, checked: false };
                }): [],
                pageCount: response.data.pageCount ? response.data.pageCount : 0,
                doRequest: false
            });
        }
    }, [response, error]);

    const columns = React.useMemo(
        () => [
            {
                Header: ` `,
                columns: [
                    {
                        Header: 'Naslov',
                        accessor: 'naslov'
                    },
                    {
                        Header: 'Vrsta sadržaja',
                        accessor: 'tipSadrzaja',
                        Cell: props => {
                            return <span>{tipSadzajaFromBackendToText[props.row.values.tipSadrzaja]}</span>;
                        }
                    },
                    {
                        Header: 'Rubrika',
                        accessor: 'rubrika.name'
                    },
                    {
                        Header: 'Podrubrika',
                        accessor: 'podrubrika.name'
                    },
                    {
                        Header: 'Datum objave',
                        accessor: 'createdAt',
                        Cell: props => <span>{moment(props.row.values.createdAt).format('DD-MM-YYYY HH:mm')}</span>
                    },
                    {
                        Header: '',
                        accessor: 'checked'
                    },
                    {
                        Header: '',
                        accessor: 'id',
                        Cell: props => (
                            <div className={`checks ${props.row.values.checked}`}>
                                <input
                                    type={'checkbox'}
                                    checked={props.row.values.checked}
                                    onChange={event => checkBoxClick(props.row.values.id)}
                                />
                            </div>
                        ) // Custom cell components!
                    }
                ]
            }
        ],
        []
    );
    const checkBoxClick = sadrzajId => {
        setSearchState(prevState => {
            return {
                ...prevState,
                recivedData: prevState.recivedData.map(sadrzaj => {
                    return { ...sadrzaj, checked: sadrzaj.id === sadrzajId ? !sadrzaj.checked : false };
                })
            };
        });
        //sadrzajId
        dispatch({
            type: actionTypes.SELECTED_WIREFRAME_SADRZAJ,
            payload: sadrzajId
        });
    };
    const data = searchState.recivedData;
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        data,
        columns
    });

    const handlePageClick = event => {
        setSearchState({ ...searchState, currentPage: event.selected, doRequest: true });
    };

    return (
        <div className={'najnovije-vijesti'}>
            <ArhivFilters
            searchState={searchState}
            setSearchState={setSearchState}
            selectedTipPretrage={TIPOVI_PRETRAGE.OBJAVLJENI}
            />
            {loading && <Loader />}
            <table {...getTableProps()} className={'tabela'}>
                <thead>
                    {headerGroups.map((headerGroup, index) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={'hg-' + index}>
                            {headerGroup.headers.map((column, columnIndex) => (
                                <th key={'column-index-' + columnIndex} {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} key={'row-' + i}>
                                {row.cells.map((cell, index) => {
                                    return (
                                        <td key={'row-key-' + index} {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <ReactPaginate
                previousLabel={'Prošla'}
                nextLabel={'Sledeća'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={searchState.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                forcePage={searchState.currentPage}
            />
        </div>
    );
};
