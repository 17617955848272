import React, {useEffect, useState} from 'react'

import {toast} from 'react-toastify';

import Loader from "../../common/components/Loader";
import {useNotifikacijeEvent} from "../../services/api";
import {API} from "../../common/constants/api";
import {METHODS} from "../../common/constants/constants";


export default ({resultsArray = []}) => {
    const [selected, setSelected] = useState(null);
    const [makeRequest, setMakeRequest] = useState(false)
    const {error, response, loading} = useNotifikacijeEvent(
        API.NOTIFIKACIJE.NEW,
        METHODS.POST,
        makeRequest,
        {id: selected && selected.id},
        false
    );

    useEffect(() => {
        if (response) {
            toast("Notifikacija poslata.", {
                type: "default"
            });
            setMakeRequest(false)
        }
    }, [response]);


    const clickEvent = (el) => {
        setSelected(el)
    }

    const sentNotification = (id) => {
        setMakeRequest(true)
    }


    const renderResults = () => {
        return resultsArray.map(el => {
            return <div key={el.id}
                        className={`item ${selected !== null && (el.id === selected.id) ? 'item--selected' : ''}`}
            >
                <p onClick={() => clickEvent(el)}>{el.naslov}</p>
                {
                    selected !== null && (el.id === selected.id) ?
                        <button onClick={() => sentNotification(el.id)}>Pošalji</button> : null
                }
            </div>
        })
    }

    return <div className={'push-notifikacije__results-container'}>
        {loading && <Loader />}
        <div className={'push-notifikacije__results'}>{resultsArray && renderResults() || <Loader/>}</div>
    </div>

};
