import React from 'react';
import { Link } from 'react-router-dom';
import { API } from '../../../common/constants/api';

const root = API.NOVI_SADRZAJ.ROOT + API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.ROOT
const links = [
  {
    name: 'Tekst',
    url: root + API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.TEKST
  },
  {
    name: 'Foto',
    url: root + API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.FOTO
  },
  {
    name: 'Video',
    url: root + API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.VIDEO
  },
  {
    name: 'Live blog',
    url: root + API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.LIVEBLOG
  },
  {
    name: 'Kolumna',
    url: root + API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.KOLUMNA
  }
]

const tipSadrzaja = {
  width: '200px',
  background: '#ffcc00',
  borderRadius: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '24px'
}
export default () => {
  return <div className={'navigation-step1'}>
    <h2 style={tipSadrzaja}>Tip sadržaja</h2>
    {
      links.map( el => <Link className={'btn-save'} key={el.name} to={el.url}>{el.name} </Link>)
    }

  </div>
}
