import './ImageSlider.less';
import ImageGallery from 'react-image-gallery';
import React from 'react';

export default ({ additionalClass = '' , images = []}) => {

    const formattedImages = images.map( img => {
        return {...img,original: img, thumbnail: img}
    });
        return (
        <ImageGallery
            items={formattedImages}
            lazyLoad={true}
            showFullscreenButton={false}
            showPlayButton={false}
            additionalClass={additionalClass}
            infinite={true}
            disableSwipe={false}
        />
    );
};
