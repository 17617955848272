import React, { memo, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import CKEditor from 'ckeditor4-react';
import { ckEditorConfig } from '../../../../common/constants/misc';
CKEditor.editorUrl = '/ckeditor/ckeditor.js';

export default memo(({ sadrzaj, setSadrzaj }) => {
    const myRef = useRef(null);

    const handleChange = event => {
        setSadrzaj(prevsState => {
            return { ...prevsState, liveBlogNewTekst: event.editor.getData() };
        });
    };

  const addListener = (ckeditor) => {
    ckeditor.on('beforeCommandExec', function(event) {
      if (event.data.name === 'paste') {
        event.editor._.forcePasteDialog = true;
      }
      if (event.data.name === 'pastetext' && event.data.commandData.from === 'keystrokeHandler') {
        event.cancel();
      }
    });
  };

    const renderLiveBlogRows = () => {
        return sadrzaj.liveBlog.liveBlogRows.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((row, index) => {
            return (
                <div className={'blogRow'}  key={'live-blog-k-' + index} >
                    <span className={'timestamp'}>{moment(row.createdAt).format('DD-MM-YYYY HH:mm')} </span>
                    <div dangerouslySetInnerHTML={{ __html: row.info }}/>
                </div>
            );
        });
    };

    return (
        <>
            <CKEditor
                onBeforeLoad={CKEDITOR => (CKEDITOR.disableAutoInline = true)}
                onInstanceReady={instance => {
                  addListener(instance.editor)
                }}
                type="classic"
                ref={myRef}
                data={sadrzaj.tekst.liveBlogNewTekst}
                onChange={handleChange}
                config={ckEditorConfig(300)}
            />

            <div className="editor-preview">
                <h2 style={{ marginTop: '12px', marginBottom: '12px' }}>Objavljeni live info</h2>
                <div className={'live-info-container'}>
                    {sadrzaj.liveBlog ? renderLiveBlogRows() : null}
                </div>
            </div>
        </>
    );
});
