import React, { useContext } from 'react';
import SectionTitle from '../../../common/components/SectionTitle';
import actionTypes from '../../../common/constants/actionTypes';
import { ContextPozicije } from '../../../contexts/Pozicioniranje';
import { checkPositionSelected, rubrikeTypes } from '../../../common/helpers/strings';
import BigCard from '../../../common/components/BigCard';

import './BreakingNews.scss';

export default ({ positions }) => {
  const [state, dispatch] = useContext(ContextPozicije);

  const clickEvent = (value) => {
    dispatch({
      type: actionTypes.SELECTED_WIREFRAME_POSITION,
      payload: {
        position: value,
        rubrika: rubrikeTypes.BREAKING_NEWS,
      },
    });
  };

  return (
    <div className={'bnews'}>
      <SectionTitle title={'Breaking news'}/>
      <h3 style={{ marginTop: '12px' }}>Slajder</h3>
      <div className={'bnews__slider'}>
        <div
          className={`square ${checkPositionSelected(
            state,
            '1',
            rubrikeTypes.BREAKING_NEWS,
          ) ? 'square--selected' : ''}`}
          onClick={() => clickEvent('1')}>
          1
        </div>
        <div
          className={`square ${checkPositionSelected(
            state,
            '2',
            rubrikeTypes.BREAKING_NEWS,
          ) ? 'square--selected' : ''}`}
          onClick={() => clickEvent('2')}>
          2
        </div>
        <div
          className={`square ${checkPositionSelected(
            state,
            '3',
            rubrikeTypes.BREAKING_NEWS,
          ) ? 'square--selected' : ''}`}
          onClick={() => clickEvent('3')}>
          3
        </div>
      </div>
      <div className={'bnews__top'}>
        <div
          className={`square ${checkPositionSelected(
            state,
            '4',
            rubrikeTypes.BREAKING_NEWS,
          ) ? 'square--selected' : ''}`}
          onClick={() => clickEvent('4')}>
          4
        </div>
        <div
          className={`square ${checkPositionSelected(
            state,
            '5',
            rubrikeTypes.BREAKING_NEWS,
          ) ? 'square--selected' : ''}`}
          onClick={() => clickEvent('5')}>
          5
        </div>
        <div
          className={`square ${checkPositionSelected(
            state,
            '6',
            rubrikeTypes.BREAKING_NEWS,
          ) ? 'square--selected' : ''}`}
          onClick={() => clickEvent('6')}>
          6
        </div>
      </div>

      <div className={'bnews__bottom'}>

        <BigCard position={'7'} rubrikaType={rubrikeTypes.BREAKING_NEWS}/>

        <BigCard position={'8'} rubrikaType={rubrikeTypes.BREAKING_NEWS}/>
        <div className={'bnews__bottom__group'}>
          <div
            className={`rectangle rectangle--medium ${checkPositionSelected(
              state,
              '9',
              rubrikeTypes.BREAKING_NEWS,
            ) ? 'rectangle--selected' : ''}`}
            onClick={() => clickEvent('9')}>
            9.
          </div>
          <div
            className={`rectangle rectangle--medium ${checkPositionSelected(
              state,
              '10',
              rubrikeTypes.BREAKING_NEWS,
            ) ? 'rectangle--selected' : ''}`}
            onClick={() => clickEvent('10')}>
            10.
          </div>
          <div
            className={`rectangle rectangle--medium ${checkPositionSelected(
              state,
              '11',
              rubrikeTypes.BREAKING_NEWS,
            ) ? 'rectangle--selected' : ''}`}
            onClick={() => clickEvent('11')}>
            11.
          </div>
        </div>
      </div>
    </div>
  );
};
