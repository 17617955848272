  import React, { useContext, useEffect, useState } from 'react';
import './pregled.scss';
import { useSortBy, useTable } from 'react-table';
import { useZaposleniEvent } from '../../../services/api';
import { API } from '../../../common/constants/api';
import Loader from 'react-loader-spinner';
import { Context } from '../../../contexts/Store';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import moment from 'moment';
import actionTypes from '../../../common/constants/actionTypes';

export default () => {
  const [data, setData] = useState([])
  const [state, dispatch] = useContext(Context)
  const [selectedZaposleni, seSelectedZaposleni] = useState(undefined)

  const columns = React.useMemo(
    () => [
      {
        Header: 'Lista zaposlenih',
        columns: [
          {
            Header: 'Ime i prezime',
            accessor: 'username'
          },
          {
            Header: 'Rola',
            accessor: 'role.name'
          },
          {
            Header: 'Status',
            accessor: 'active.data[0]',
            Cell: props => <span> {props.row.values['active.data[0]'] === 1 ? 'Aktivan' : 'Neaktivan'} </span>
          },
          // {
          //   Header: 'Datum isteka licence',
          //   accessor: 'datumIsteka',
          //   Cell: props => <span> {moment(props.row.values.datumIsteka).format('DD-MM-YYYY')} </span>
          // },
          // {
          //   Header: 'Datum aktivacije',
          //   accessor: 'datumAktivacije',
          //   Cell: props => <span> {moment(props.row.values.datumAktivacije).format('DD-MM-YYYY')} </span>
          // },
          {
            Header: 'Opcije',
            accessor: 'id',
            shouldSort: false,
            sortType: 'options',
            Cell: props => <span className='options'>
                            <a>#</a>

              <a onClick={ () => {
                seSelectedZaposleni(props.row.original.id)
                dispatch({ type: 'SHOW_MODAL', payload: { name: actionTypes.EDIT_USER, data: props.row.original } })
              }}>Izmijeni</a>
              {/*<a onClick={() => {*/}
              {/*  seSelectedZaposleni(props.row.original.id)*/}
              {/*  dispatch({ type: 'SHOW_MODAL', payload: { name: actionTypes.DELETE_USER, data: null } })*/}
              {/*}*/}
              {/*}>Obriši</a>*/}
              <Link to={API.ZAPOSLENI.ROOT + '/' + props.row.original.id}>Analitika</Link>
            </span> // Custom cell components!
          }
        ]
      }
    ],
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      data,
      columns
    },
    useSortBy)

  const { response, loading } = useZaposleniEvent(
    API.ZAPOSLENI.PREGLED,
    'GET',
    true
  )

  const { response: deleteResponse, loading: deleteLoading } = useZaposleniEvent(
    API.ZAPOSLENI.SINGLE.replace(':id', selectedZaposleni),
    'DELETE',
    state.deleteZaposleni && selectedZaposleni
  )

  const zaposleniData = response ? response.data : null

  useEffect(() => {
    if (zaposleniData) {
      setData(zaposleniData)
    }
  }, [response])

  useEffect(() => {
    if (deleteResponse) {
      toast('Korisnik uklonjen.')
      setData(data.filter(zaposleni => zaposleni.id !== selectedZaposleni))
      seSelectedZaposleni(undefined)
      dispatch({ type: actionTypes.DELETE_ZAPOSLENI_CONFIRM, payload: false })
    }
  }, [deleteResponse])

  useEffect(() => {
    if (state.updatedZaposleni) {
      const updatedZaposleni = state.updatedZaposleni.data
      setData(data.map(user => {
        if (user.id === updatedZaposleni.id) {
          return {
            ...user,
            username: updatedZaposleni.username.value,
            email: updatedZaposleni.email.value,
            datumAktivacije: updatedZaposleni.datumAktivacije.toDateString(),
            datumIsteka: updatedZaposleni.datumIsteka.value.toDateString(),
            role: { name: updatedZaposleni.rola },
            active: { type: 'buffer', data: [updatedZaposleni.active] }
          }
        } else {
          return user
        }
      }))
    }
  }, [state.updatedZaposleni])

  return (
    loading || deleteLoading ? <Loader
      type="Oval"
      color="#00BFFF"
      height={32}
      width={32}
    /> : <table {...getTableProps()} className={'tabela'}>
      <thead>
        {headerGroups.map((headerGroup,index) => (
          <tr key={'zaposlini-hedaer'+index} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column,colIndex) => (
              <th key={'zaposleni-col-ondex'+index+"_"+colIndex} {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
                {
                  ['Lista zaposlenih', 'Opcije'].includes(column.Header) ? null : <span>
                    {column.isSorted ? (column.isSortedDesc ? ' ▼' : ' ▲') : ' '}
                  </span>
                }
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(
          (row, i) => {
            prepareRow(row)
            return (
              <tr key={'zaposleni-body-'+i} {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  return <td key={'zaposleni-td-'+i+"_"+index} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          }
        )}
      </tbody>
    </table>

  )
}
