import React from 'react';
import './App.css';
import './common/styles/_global.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import Routes from './routes';
import { Router } from 'react-router-dom';
import history from './services/history';
import Store from './contexts/Store';

function App() {
    return (
        <Store>
            <Router history={history}>
                <Routes/>
            </Router>
        </Store>

    );
}

export default App;
