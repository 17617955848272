import React, { useEffect, useRef, useState } from 'react';
import { useTable } from 'react-table';
import ReactPaginate from 'react-paginate';
import './ArhivResults.scss';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { API } from '../../common/constants/api';
import { useSadrzajEvent } from '../../services/api';
import { METHOD_TYPES } from '../../common/helpers/api';
import Loader from '../../common/components/Loader';
import { toast } from 'react-toastify';
import { TIPOVI_PRETRAGE, tipSadzajaFromBackendToRoute } from '../../common/constants/constants';

export default ({ searchState, setSearchState, selectedTipPretrage }) => {
    const history = useHistory();

    const [markedForDeletion, setMarkedForDeletion] = useState(null);
    const columns = React.useMemo(
        () => [
            {
                Header: ' ',
                columns: [
                    {
                        Header: 'Naslov',
                        accessor: 'naslov',
                        Cell: props => {
                            return (
                              <a
                                style={{color: 'black'}}
                                href={API.NOVI_SADRZAJ.ROOT +
                              API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.ROOT +
                              tipSadzajaFromBackendToRoute[props.row.values.tipSadrzaja] +
                              API.SADRZAJ.SINGLE.replace(':id', props.row.values.id)} >
                                  {props.row.values.naslov}
                              </a>
                            );
                        }
                    },
                    {
                        Header: 'Autor(i)',
                        accessor: 'autori',
                        Cell: props => {
                            return (
                                <div className={'autori-column'}>
                                    {props.row.values.autori && props.row.values.autori.map((autor, index) => {
                                        return <span key={index + 120}>{autor.username}</span>;
                                    })}
                                </div>
                            );
                        }
                    },
                    {
                        Header: 'Datum objave ('+moment(searchState.dateFrom).format('DD.MM.Y')+'  -   '+moment(searchState.dateTo).format('DD.MM.Y')+')',
                        accessor: 'createdAt',//2020-02-26T22:18:02.000Z
                        Cell: props => <span>{moment(props.row.values.createdAt).format('DD-MM-YYYY HH:mm')}</span>
                    },
                    {
                        Header: 'Rubrika',
                        accessor: 'rubrika.name'
                    },
                    {
                        Header: '',
                        accessor: 'tipSadrzaja',
                        Cell: props => <span/>
                    },
                    {
                        Header: 'Akcija',
                        accessor: 'id',
                        Cell: props => (
                            <div className={'opcije'}>
                                <span>Akcije</span>
                                <input type={'checkbox'} />
                                <div className={'opcije__dropdown'}>
                                    {selectedTipPretrage !== TIPOVI_PRETRAGE.Izbrisani ? (
                                        <span
                                            onClick={() => {
                                                history.push(
                                                    API.NOVI_SADRZAJ.ROOT +
                                                        API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.ROOT +
                                                        tipSadzajaFromBackendToRoute[props.row.values.tipSadrzaja] +
                                                        API.SADRZAJ.SINGLE.replace(':id', props.row.values.id)
                                                );
                                            }}>
                                            Izmjeni
                                        </span>
                                    ) : null}

                                    <span
                                        onClick={event => {
                                            event.target.parentNode.parentNode.children[1].checked = false;
                                            //event.target.parentNode.style.display = "none";
                                            setMarkedForDeletion(props.row.values.id);
                                        }}>
                                        Obriši
                                    </span>
                                    {selectedTipPretrage !== TIPOVI_PRETRAGE.DRAFTS ? (
                                        <span
                                            onClick={() => {
                                                history.push(
                                                    API.NOVI_SADRZAJ.ROOT +
                                                        API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.ROOT +
                                                        API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.TEKST +
                                                        API.SADRZAJ.SINGLE.replace(':id', props.row.values.id)
                                                );
                                            }}>
                                            Ponovo objavi
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                        ) // Custom cell components!
                    }
                ]
            }
        ],
        []
    );
    //searchState.recivedData
    const data = searchState.recivedData;
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        data,
        columns
    });

    const handlePageClick = event => {
        setSearchState({ ...searchState, currentPage: event.selected, doRequest: true });
    };

    const { response: deleteResponse, loading: deleteLoading, error: deleteError } = useSadrzajEvent(
        API.SADRZAJ.SINGLE.replace(':id', markedForDeletion),
        METHOD_TYPES.DELETE,
        markedForDeletion,
        null,
        false
    );

    useEffect(() => {
        if (deleteError) {
            setSearchState({
                ...searchState,
                doRequest: false
            });
            setMarkedForDeletion(null);
            return;
        }
        if (deleteResponse) {
            setSearchState({
                ...searchState,
                recivedData: searchState.recivedData.filter(el => el.id !== markedForDeletion),
                doRequest: false
            });
            setMarkedForDeletion(null);
            toast('Sadržaj obrisan.');
        }
    }, [deleteResponse, deleteError]);

    return (
        <div className={'arhiv-results'}>
            {deleteLoading && <Loader />}
            <table {...getTableProps()} className={'tabela'}>
                <thead>
                    {headerGroups.map((headerGroup,indexHeader) => (
                        <tr key={"headaer-index"+indexHeader} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column,index) => (
                                <th key={"column-"+index} {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr key={"row-index"+i} {...row.getRowProps()}>
                                {row.cells.map((cell, index) => {
                                    return <td key={"index-row-col"+index} {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <ReactPaginate
                previousLabel={'Prošla'}
                nextLabel={'Sledeća'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={searchState.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                forcePage={searchState.currentPage}
            />
        </div>
    );
};
