import React, { useContext, useEffect, useState } from 'react';
import './Novi.scss';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useZaposleniEvent } from '../../../services/api';
import { API } from '../../../common/constants/api';
import Loader from 'react-loader-spinner';
import validator from './validator';
import { toast } from 'react-toastify';
import { Context } from '../../../contexts/Store';

const roles = [
  'Novinar',
  'Urednik',
  'Administrator'
]
const currentDate = new Date()
const futureDate = new Date();
futureDate.setDate(futureDate.getDate() + 1000);

export default ({ title = 'Dodaj novog zaposlenog', extraClass = '', actionName = 'Dodaj korisnika', action = 'NEW_USER' }) => {
  const [state, dispatch] = useContext(Context)

  const editingUser = state.showModal ? state.showModal.data : null

  const [data, setData] = useState({
    ready: false,
    id: editingUser ? editingUser.id : null,
    username: {
      value: editingUser ? editingUser.username : '',
      error: ''
    },
    rola: editingUser ? editingUser.role.name : 'novinar',
    password: {
      value: '',
      error: ''
    },
    email: {
      value: editingUser ? editingUser.email : '',
      error: ''
    },
    datumAktivacije: editingUser ? new Date(editingUser.datumAktivacije) : currentDate,
    datumIsteka: {
      value: editingUser ? new Date(editingUser.datumIsteka) : futureDate,
      error: ''
    },
    active: editingUser ? editingUser.active.data[0] : 1
  })

  const apiURL = editingUser ? '/' + editingUser.id : API.ZAPOSLENI.NOVI

  const { response, error, loading } = useZaposleniEvent(
    apiURL,
    editingUser ? 'PUT' : 'POST',
    data.ready,
    {
      id: data.id,
      username: data.username.value,
      password: data.password.value,
      email: data.email.value,
      rola: data.rola,
      datumAktivacije: data.datumAktivacije,
      datumIsteka: data.datumIsteka.value,
      active: data.active
    }
  )

  useEffect(() => {
    if (response) {
      toast(editingUser ? 'Izmjene sačuvane' : 'Korisnik dodat.')
      setData({ ...data, ready: false })
      if (editingUser) {
        dispatch({ type: 'UPDATE_ZAPOSLENI', payload: { name: 'ADMIN_EDITED_USER', data: data } })
      }
    }
    if (error) {
      toast('Greška.')
    }
  }, [response, error])

  const saveUser = () => {
    const isValid = validator(setData, data, !!editingUser)
    if (isValid) {
      setData({ ...data, ready: true })
    }
  }

  return <div className={'zaposleni-novi ' + extraClass}>
    <h1>{title}</h1>
    <hr/>
    <div className={'inline-txt'}>
      <span><b>Ime i prezime:</b></span>
      <input type={'text'} value={data.username.value}
        onChange={(e) => {
          setData({ ...data, username: { value: e.target.value } })
        }}/>
      <span className={'error'}> {data.username.error} </span>
    </div>

    <div className={'zaposleni-novi__rola-container'}>
      <span className={'zaposleni-novi__rola'}><b>Rola:</b></span>
      {
        roles.map(role => <div key={role}><input type={'radio'} onChange={
          (event) => {
            setData({ ...data, rola: event.currentTarget.value })
          }
        } checked={role.toLowerCase() === data.rola.toLowerCase()} value={role}/> <span>{role}</span></div>)
      }
    </div>

    <div className={'inline-txt'}>
      <span><b>Password:</b></span>
      <input type={'password'} value={data.password.value}
        onChange={(e) => {
          setData({ ...data, password: { value: e.target.value } })
        }}/>
      <span className={'error'}> {data.password.error} </span>
    </div>

    <div className={'inline-txt'}>
      <span><b>Email:</b></span>
      <input type={'email'}
        value={data.email.value}
        onChange={(e) => {
          setData({ ...data, email: { value: e.target.value } })
        }}
      />
      <span className={'error'}> {data.email.error} </span>
    </div>

    <hr/>

    <div className={'inline-txt'}>
      <div className={'licences'} style={{display: "none"}}>
        <span><b>Datum aktiviranja licence:</b></span>
        <DatePicker
          selected={data.datumAktivacije}
          onChange={(date) => {
            setData({ ...data, datumAktivacije: date })
          }}
          dateFormat="dd.MM.Y"
        />
        <br/>
        <span><b>Datum isteka licence:</b></span>
        <DatePicker
          selected={data.datumIsteka.value}
          dateFormat="dd.MM.Y"
          onChange={(date) => {
            setData({ ...data, datumIsteka: { value: date } })
          }}
        />
      </div>
      <span className={'error'}> {data.datumIsteka.error} </span>
    </div>

    <div className={'inline-txt'}>
      <span><b>Status:</b></span> <select value={data.active ? 'Aktivan' : 'Neaktivan'}
        onChange={(el) => {
          setData({ ...data, active: el.target.value === 'Aktivan' ? 1 : 0 })
        }}>
        <option>Aktivan</option>
        <option>Neaktivan</option>
      </select>
    </div>

    <div className={'loader-container'}>
      <button className={'zaposleni-novi__add'} onClick={saveUser}>
        {actionName}
      </button>

      {
        loading ? <Loader
          type="Oval"
          color="#00BFFF"
          height={32}
          width={32}
        /> : null
      }

    </div>

  </div>
}
