import React from 'react';
import './Sport.scss';
import SectionTitle from '../../../common/components/SectionTitle';
import LeaderboardCard from '../../../common/components/LeaderboardCard';
import FixedCardList from '../../../common/components/FixedCardList';
import BigCard from '../../../common/components/BigCard';
import { rubrikeTypes } from '../../../common/helpers/strings';

export default ( { title , rubrikaType}) =>{
  return (
    <div className={'sport-container'}>
      <SectionTitle title={title} showHref={true}/>
      <div className={'sport-container__top'}>
        <LeaderboardCard cardColor={'gray-white'} position={'1'} rubrikaType={rubrikaType}/>
      </div>
      <div className={'sport-container__bottom'}>
        <FixedCardList rows={['3','4','5']} hasPicture={true} rubrikaType={rubrikaType}/>
        <BigCard position={'2'} rubrikaType={rubrikaType}/>
        <FixedCardList rows={['6','7','8','9']} hasPicture={false} rubrikaType={rubrikaType}/>
      </div>
    </div>
  )
}
