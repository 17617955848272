import React from 'react';
import { rubrikeTypes } from '../../../../common/helpers/strings';
import BigCard from '../../../../common/components/BigCard';
import TwoHalfRowCards from '../../../../common/components/TwoHalfRowCards';
import FixedCardList from '../../../../common/components/FixedCardList';
import '../Fostav.scss';
import SectionTitle from '../../../../common/components/SectionTitle';
import LeaderBoardWithBigCard from '../../../../common/components/LeaderBoardWithBigCard';
import ThreeSmallOneBigOneMedium from '../../../../common/components/ThreeSmallOneBigOneMedium';

export default ({ title, podrubrikaId }) => {
    return (
        <div className={'svijet-container'}>
            <SectionTitle title={title} showHref={true}  bottomBorder={false}/>
            <LeaderBoardWithBigCard  positionOne={'1'} positionTwo={'2'} rubrikaType={podrubrikaId}/>
            <div className={'svijet-container__bottom'}>
                <ThreeSmallOneBigOneMedium smallPositions={['3','4','5']} rubrikaType={podrubrikaId}/>
            </div>
        </div>

    );
};
