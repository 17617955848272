import { API } from './api';

export const chartTypes = {
    'BAR_CHART' : 'barchart',
    'PIE_CHART' :'piechart',
};

export const TIPOVI_PRETRAGE = {
    OBJAVLJENI: 'Objavljeni',
    DRAFTS: 'Drafts',
    Izbrisani: 'Izbrisani',
};

export const tipSadzajaFromBackendToText = [
    'Tekst',
    'Foto',
    'Video',
    'Live Blog',
    'Kolumna'
];

export const tipSadzajaFromBackendToRoute =[
    API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.TEKST,
    API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.FOTO,
    API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.VIDEO,
    API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.LIVEBLOG,
    API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.KOLUMNA,
];

export const sadrzajType = {
    TEKST: 'TEKST',
    KOLUMNA: 'KOLUMNA',
    FOTO: 'FOTO',
    VIDEO: 'VIDEO',
    LIVE_BLOG :'LIVE BLOG'
};

export const anketaTypes = {
    TEKST : 'TEKST',
    SEKUNDARDNI_SADRZAJ: 'SEKUNDARDNI_SADRZAJ'
};

export const METHODS = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE'
};


export const STATUS_SADRZAJA =  {
    OBJAVLJEN : 0,
    DRAFT: 1,
    MARKIRANO_ZA_BRISANJE: 2,
};

export const STATUS_SADRZAJA_REVERSED = {
    0: 'OBJAVLJEN',
    1: 'DRAFT',
    2: 'MARKIRANO_ZA_BRISANJE'
}
