import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../../../contexts/Store';
import Loader from '../../Loader';
import { useCommentsEvent, useSadrzajEvent } from '../../../../services/api';
import { API } from '../../../constants/api';
import { getTempImage } from '../../../helpers/api';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { toast } from 'react-toastify';

const Kampanja = () => {
  const [state, dispatch] = useContext(Context);


  useEffect(() => {
    if (state.showModal?.data) {
      const { bigImage, id, redirectURL, smallImage, title } = state.showModal.data;
      setSelectedBanner({
        bigImage: {
          ...banner.bigImage,
          preview: bigImage,
        },
        smallImage: {
          ...banner.smallImage,
          preview: smallImage,
        },
        id: id,
        redirectURL: {
          ...banner.redirectURL,
          value: redirectURL,
        },
        title: {
          ...banner.title,
          value: title,
        },
      });
    }
  }, [state.showModal]);

  const [banner, setSelectedBanner] = useState({
    id: null,
    bigImage: {
      value: null,
      error: '',
      preview: null,
    },
    smallImage: {
      value: null,
      error: '',
      preview: null,
    },
    title: {
      value: '',
      error: '',
    },
    redirectURL: {
      value: '',
      error: '',
    },
    executeRequest: false,
  });
  const onChangeHandler = (event, imageAttr) => {
    setSelectedBanner({
      ...banner,
      state: 'loading',
      [imageAttr]: { value: event.target.files[0], error: '', preview: null },
    });
  };

  const { response: saveResponse, loading: saveLoading, error } = useSadrzajEvent(
    API.BANERI_SOLO + '/new',
    'POST',
    banner.executeRequest,
    {
      id: banner.id,
      title: banner.title.value,
      bigImage: banner.bigImage.preview ? banner.bigImage.preview : banner.bigImage.value,
      smallImage: banner.smallImage.preview ? banner.smallImage.preview : banner.smallImage.value,
      redirectUrl: banner.redirectURL.value,
    }, false,
  );

  useEffect(() => {
    if (saveResponse || error) {
      if (error) {
        setSelectedBanner({ ...banner, executeRequest: false });
        toast('Greška - ' + error);
      } else {
        dispatch({
          type: 'HIDE_MODAL',
        });
        dispatch({
          type: 'REFRESH_DATA',
        });
        toast('Kampanja sačuvana.');
      }
    }
  }, [saveResponse, error]);

  const { response, loading } = useSadrzajEvent(
    API.SADRZAJ.UPLOAD_MULTIPLE_PICTURES,
    'POST',
    banner.bigImage.value,
    {
      file: [banner.bigImage.value],
    },
    true,
  );

  const { response: smallImageResponse, loading: smallImageLoading } = useSadrzajEvent(
    API.SADRZAJ.UPLOAD_MULTIPLE_PICTURES,
    'POST',
    banner.smallImage.value,
    {
      file: [banner.smallImage.value],
    },
    true,
  );

  useEffect(() => {
    if (response) {
      setSelectedBanner({
        ...banner,
        bigImage: { value: null, error: '', preview: response.data[0], state: null },
      });
    }
  }, [response]);

  useEffect(() => {
    if (smallImageResponse) {
      setSelectedBanner({
        ...banner,
        smallImage: { value: null, error: '', preview: smallImageResponse.data[0], state: null },
      });
    }
  }, [smallImageResponse]);


  return (
    <div>
      <h2>Kampanja </h2>
      <hr/>
      <div className={'inline-txt'}>
        <p>Ime kampanje</p>
        <input
          className={'w-100'}
          type={'text'}
          placeholder={'Ime kampanje...'}
          value={banner.title.value}
          onChange={event =>
            setSelectedBanner({ ...banner, title: { error: '', value: event.target.value } })
          }
        />
        <span className={'error'}> {banner.title.error}</span>
      </div>
      <div className={'inline-txt'}>
        <p>Click URL</p>
        <input
          className={'w-100'}
          type={'text'}
          placeholder={'URL...'}
          value={banner.redirectURL.value}
          onChange={event =>
            setSelectedBanner({ ...banner, redirectURL: { error: '', value: event.target.value } })
          }
        />
      </div>
      <div className={'inline-txt'}>
                <span>
                    Veliki baner: <span className={'error'}> {banner.bigImage.error}</span>
                </span>
        <input type={'file'} accept="image/*" onChange={event => onChangeHandler(event, 'bigImage')}/>
      </div>

      {banner.bigImage.preview && (
        <div className={'new-photo__quick-preview'}>
          <LazyLoadImage width={150} height={150} src={getTempImage('/' + banner.bigImage.preview)}/>
          <span
            onClick={() => {
              setSelectedBanner({
                ...banner,
                bigImage: {
                  value: null,
                  error: '',
                  preview: null,
                },
              });
            }}
          >
                        X
                    </span>
        </div>
      )}


      <div className={'inline-txt'}>
                <span>
                    Mali baner: <span className={'error'}> {banner.smallImage.error}</span>
                </span>
        <input type={'file'} accept="image/*" onChange={event => onChangeHandler(event, 'smallImage')}/>
        {(loading || saveLoading || smallImageLoading) && <Loader/>}
      </div>

      {banner.smallImage.preview && (
        <div className={'new-photo__quick-preview'}>
          <LazyLoadImage width={150} height={150} src={getTempImage('/' + banner.smallImage.preview)}/>
          <span
            onClick={() => {
              setSelectedBanner({
                ...banner,
                smallImage: {
                  value: null,
                  error: '',
                  preview: null,
                },
              });
            }}
          >
                        X
                    </span>
        </div>
      )}


      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <button
          className={'btn-save'}
          onClick={() => {
            if (banner.title.value.length < 2) {
              setSelectedBanner({
                ...banner,
                title: {
                  ...banner.title,
                  error: 'Unesite ime kampanje',
                },
              });
              return;
            }
            setSelectedBanner({ ...banner, executeRequest: true });
          }}>
          Sačuvaj
        </button>
        <button
          className={'btn-close'}
          onClick={() => {
            dispatch({ type: 'HIDE_MODAL' });
          }}>
          Zatvori
        </button>
      </div>
    </div>
  );
};

export default Kampanja;
