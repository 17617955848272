import React from 'react';
import { useHistory, useParams } from 'react-router';
import { useAuthorDetailsEvent } from '../../../../services/api';

import './analitika.scss';
import Loader from '../../../../common/components/Loader';
import moment from 'moment';

export default () => {
  const history = useHistory();
  const { id } = useParams();

  //FETCHING THE SADRZAJ FOR EDITING
  const { loading, response } = useAuthorDetailsEvent(
    'GET',
    id,
  );

  if (loading || !response) {
    return <Loader/>;
  }

  const { firstName, lastName, role, active, datumAktivacije, datumIsteka, brTekstova, brTekstovaDanas, brTekstovaTekuceNedjelje, brTekstovaTekucegMjeseca, brTekstovaPrethodnogMjeseca, procenatPoRubrikama , prosjecnaCitanostPoClanku, maxCitanostPoClanku} = response.data;
  return <div className={'zaposleni-analitika'}>

    <div className={'zaposleni-analitika__header'}>
      <h1>{firstName} {lastName}</h1>
      <button onClick={() => history.goBack()} className={'btn-close'}>Zatvori</button>
    </div>

    <div className={'zaposleni-analitika__details'}>
      <p><b>Rola:</b> {role.name.toUpperCase()}</p>
      <p><b>Status:</b> {active.data[0] === 1 ? 'Aktivan' : 'Neaktivan'}</p>
      <p><b>Aktiviran:</b> {moment(datumAktivacije).format('DD-MM-YYYY')}</p>
      <p><b>Ističe licenca:</b> {moment(datumIsteka).format('DD-MM-YYYY')}</p>
    </div>

    <div className={'zaposleni-analitika__stats'}>
      <div className={'zaposleni-analitika__stats__header'}>
        <span>Ukupan br. tekstova</span>
        <span>Br. tekstova danas</span>
        <span>Br. tekstova tekuće nedjelje</span>
        <span>Br. tekstova tekućeg mjeseca</span>
        <span>Br. tekstova prethodnog mjeseca</span>
      </div>
      <div className={'zaposleni-analitika__stat-values'}>
        <span>{brTekstova}</span>
        <span>{brTekstovaDanas}</span>
        <span>{brTekstovaTekuceNedjelje}</span>
        <span>{brTekstovaTekucegMjeseca}</span>
        <span>{brTekstovaPrethodnogMjeseca}</span>
      </div>
    </div>

    <div className={'zaposleni-analitika__stats'}>
      <div className={'zaposleni-analitika__stats__header'}>
        <span>Procenat članaka po rubrikama</span>
        <span>Prosječna čitanost po članku</span>
        <span>Najveća dostignuta čitanost po članku</span>
      </div>
      <div className={'zaposleni-analitika__stat-values'}>
        <div className={'zaposleni-analitika__stat-values__group'}>
          {procenatPoRubrikama && procenatPoRubrikama.length > 0 && procenatPoRubrikama.map((el,index) => {
            return <span key={'analitika-key'+index+"_"+el.name}>{el.name}: <b>{el.value}%</b></span>
          })}
        </div>
        <span>{prosjecnaCitanostPoClanku}</span>
        <span>{maxCitanostPoClanku}</span>
      </div>
    </div>

  </div>;
}
