import React, { useContext } from 'react';

import Modal from 'react-responsive-modal';

import { Context } from '../../contexts/Store';
import modalTypes from './modalTypes';

import './modal.scss';
import 'react-responsive-modal/styles.css';

export default ({ openDefault = false }) => {
    const [state, dispatch] = useContext(Context);

    return (
        <Modal
            open={!!state.showModal}
            onClose={() => dispatch({ type: 'HIDE_MODAL' })}
            classNames={{
                modal: 'custom-modal'
            }}>
            {modalTypes[state.showModal.name]}
        </Modal>
    );
};
