import _ from 'lodash';
import FormData from 'form-data';
import { API } from '../constants/api';

function buildFormData(formData, data, parentKey) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;
    formData.append(parentKey, value);
  }
}

export const generateFormData = (dataObj) => {
  const formData = new FormData();
  buildFormData(formData, dataObj);
  return formData
};

export const getUserAvatar =(img) => {
  return API.BASE+API.AVATARS+img
};

export const getTempImage =(img) => {
  return API.BASE  + API.IMAGES.TMP + img
};

export const getLiveImage =(img) => {
  return API.BASE  + API.IMAGES.ROOT + img
};

export const METHOD_TYPES = {
  GET : 'GET',
  POST : 'POST',
  PUT :'PUT',
  DELETE : 'DELETE'
};

/**
 * Gets the browser's cookie.
 *
 * @param {String} cname The name of the cookie.
 * @returns {(String|undefined)} The cookie value, or undefined if not found.
 */
export function getCookie (cname) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${cname}=`)

  if (parts.length === 2) {
    return JSON.parse(
      decodeURIComponent(
        parts
          .pop()
          .split(';')
          .shift()
      )
    )
  }

  return null
}

/**
 * Sets the browser's cookie.
 *
 * @param {String} cname The name of the cookie.
 * @param {String} value The value of the cookie.
 * @param {Number} days The expiration delay in days.
 */
export function setCookie (cname, value, days) {
  let expires = ''
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie =
        cname + '=' + (encodeURIComponent(JSON.stringify(value)) || '') + expires + '; path=/'
}

/**
 * Deletes the requested cookie.
 * @param {String} cname  The cookie name to delete.
 */
export function deleteCookie (cname) {
  document.cookie = cname + '=; Max-Age=-99999999;'
}

// #region User
export const getCurrentUser = () => getCookie('user')

export const setUserCookies = (value, days = 30) => {
  const expires = new Date()
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000)
  setCookie('user', value, {
    expires
  })
}

export const isUserTokenExpired = () => {
  const user = getCurrentUser()
  if (!user) return false

  const expires = user.expires
  const now = +new Date()

  return expires - now <= 10000
}
