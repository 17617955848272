import { API } from './api';

export const ckEditorConfig = ( height = 450 ) => {
    return {
        // filebrowserUploadUrl:
        //     '/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Files',
        filebrowserImageUploadUrl: API.BASE+API.SADRZAJ.ROOT+API.SADRZAJ.UPLOAD_TYPE_TOOL,
        fileTools_requestHeaders: {
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `Bearer ${localStorage.getItem('userToken')}`
        },
        //  '/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Images',
        height: height,
        embed_provider: '//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}&api_key=e6fbb63036599055230fd4',
        language: 'hr',
        removeButtons:'',
        extraPlugins: 'videoembed'
    }
}
