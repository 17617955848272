import actionTypes from '../common/constants/actionTypes';
import { chartTypes } from '../common/constants/constants';

const PozicioniranjeReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.AUTOMATSKO_POZICIONIRANJE:
            return {
                ...state,
                automatskoPozicioniranje: action.payload
            }
        case actionTypes.SAVE_SINGLE_POZICIJA:
            return {
                ...state,
                insertSinglePozicija: {
                    rubrika: state.selectedWireFrameRubrika,
                    sadrzajId: state.selectedSadrzaj,
                    value: state.selectedWireFramePosition,
                    automatskoPozicioniranje: state.automatskoPozicioniranje
                }
            };
        case actionTypes.REMOVE_SELECTED_SADRZAJ_FROM_NAJNOVIJE:
            return {
                ...state,
                removeCurrentSelectedSadrzaj: true
            };
        case actionTypes.RESET_SELECTED_WIREFRAME_POSITION:
            return {
                ...state,
                selectedWireFramePosition: null,
                selectedWireFrameRubrika: null,
                selectedSadrzaj: null,
                insertSinglePozicija: null,
                removeCurrentSelectedSadrzaj: null
            };
        case actionTypes.SELECTED_WIREFRAME_SADRZAJ:
            return {
                ...state,
                selectedSadrzaj: action.payload
            };
        case actionTypes.SELECTED_WIREFRAME_POSITION:
            return {
                ...state,
                selectedWireFramePosition: action.payload.position,
                selectedWireFrameRubrika: action.payload.rubrika
            };

            // const doesSelectedPairExist = state.selectedPairs
            //     ? state.selectedPairs.find(
            //           el => el.position === action.payload.position && el.rubrika === action.payload.rubrika
            //       )
            //     : false;
            // if (doesSelectedPairExist) {
            //     const newStatePairs = state.selectedPairs.filter(el => el !== doesSelectedPairExist);
            //     return {
            //         ...state,
            //         selectedPairs: newStatePairs,
            //         selectedWireFramePosition: action.payload.position,
            //         selectedWireFrameRubrika: action.payload.rubrika
            //     };
            // } else {
            //
            //     const newPair = { position: action.payload.position, rubrika: action.payload.rubrika, sadrzaj: null };
            //     const selectedPairs = state.selectedPairs ? [...state.selectedPairs, newPair] : [newPair];
            //
            //     return {
            //         ...state,
            //         selectedPairs: selectedPairs,
            //         selectedWireFramePosition: action.payload.position,
            //         selectedWireFrameRubrika: action.payload.rubrika
            //     };
            // }
        default:
            return state;
    }
};

export default PozicioniranjeReducer;
