import './BigCard.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import React, { useContext } from 'react';
import placeholder from '../images/naslovna_placeholder.jpg';
import { checkPositionSelected } from '../helpers/strings';
import { ContextPozicije } from '../../contexts/Pozicioniranje';
import actionTypes from '../constants/actionTypes';
export default ({ imageModifier = null, borderTop = true, position = 1, rubrikaType }) => {
    const [state, dispatch] = useContext(ContextPozicije);
    return (
        <a className={` big-card ${borderTop ? 'big-card--no-border-top' : ''}`}>
            <LazyLoadImage
                wrapperClassName={'big-card__img-container'}
                className={'big-card__img ' + (imageModifier !== null ? 'big-card__img' + imageModifier : '')}
                effect={'opacity'}
                src={placeholder}
            />

            <div
                className={`big-card__content ${imageModifier !== null ? '  big-card__content' + imageModifier : ''} ${
                    checkPositionSelected(state, position, rubrikaType) ? 'wireframeSelected' : ''
                }`}
                onClick={() => {
                    dispatch({
                        type: actionTypes.SELECTED_WIREFRAME_POSITION,
                        payload: {
                            position: position,
                            rubrika: rubrikaType
                        }
                    });
                }}>
                {position}
            </div>
        </a>
    );
};
