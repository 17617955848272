import React, {useCallback, useEffect, useState} from 'react';

import {useSadrzajEvent} from "../../services/api";
import {API} from "../../common/constants/api";
import {METHODS} from "../../common/constants/constants";
import {defaultSearchState, emptyRubrika} from "../arhiv";
import Results from './results';
import {debounce} from "../../utils/commonFunctions";

import './push-notifikacije.scss';

export default () => {

    const [searchState, setSearchState] = useState({
        ...defaultSearchState,
        rubrike: null,
        selectedRubrika: null,
        selectedPodrubrika: null,
        returnWithPagination: true,
        sortByNajnovije: true
    });

    const {error, response, loading} = useSadrzajEvent(
        API.ARHIV,
        METHODS.POST,
        searchState.doRequest,
        searchState,
        false
    );

    const responseList = response && response.data && response.data.pretragaList

    useEffect(() => {
        if (response && response.data) {
            setSearchState({...searchState, doRequest: false})
        }
    }, [response]);

    const apiSearch = (value) => {
        setSearchState({...searchState, naslov: value, doRequest: true})
    };

    const searchHandler = useCallback(debounce(apiSearch, 1000), []);


    return <div style={{margin: '12px'}} className={'push-notifikacije'}>
        <h2 style={{textAlign: 'center'}}>Push notifikacije</h2>
        <hr/>
        <h3 style={{textAlign: "center"}}>Odaberite tekst, a zatim pritisnite dugme <b>Pošalji</b></h3>
        <h3>Vijesti:</h3>
        <div>
            <label>Pretraga po naslovu</label> <input type={"text"}
                                                      onChange={(event => searchHandler(event.target.value))}/>
        </div>
        <Results resultsArray={responseList}/>
    </div>;
};
