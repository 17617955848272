import React from 'react';

import moment from 'moment';
import { toast } from 'react-toastify';
import { useTable } from 'react-table';

import { tipSadzajaFromBackendToRoute, tipSadzajaFromBackendToText } from '../../../common/constants/constants';
import { API } from '../../../common/constants/api';

export default ({ imeRubrike, data, setPozicijeData, pozicijeData, maxPositions }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: `Rubrika: ${imeRubrike}`,
        columns: [
          {
            Header: 'Aktivna Pozicija',
            accessor: 'value',
          },
          {
            Header: 'Naslov',
            accessor: 'sadrzaj.naslov',
            Cell: props => {
              return (
                <a
                  style={{ color: 'black' }}
                  href={API.NOVI_SADRZAJ.ROOT +
                  API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.ROOT +
                  tipSadzajaFromBackendToRoute[props.row.values['sadrzaj.tipSadrzaja']] +
                  API.SADRZAJ.SINGLE.replace(':id', props.row.values['sadrzaj.id'])}
                  target={'_blank'}
                  rel="noreferrer"
                >
                  {props.row.values['sadrzaj.naslov']}
                </a>
              );
            },
          },
          {
            Header: 'Vrsta sadržaja',
            accessor: 'sadrzaj.tipSadrzaja',
            Cell: props => {
              return <span>{tipSadzajaFromBackendToText[props.row.values['sadrzaj.tipSadrzaja']]}</span>;
            },
          },
          {
            Header: 'Datum objave',
            accessor: 'sadrzaj.createdAt',
            Cell: props => (
              <span>{moment(props.row.values['sadrzaj.createdAt']).format('DD-MM-YYYY HH:mm')}</span>
            ),
          },
          {
            Header: 'Izbriši',
            accessor: 'actionDelete',
            Cell: props => (
              <button
                className={'btn-delete'}
                onClick={() => {
                  setPozicijeData(prevState => {
                    return {
                      ...prevState,
                      value: prevState.value.filter(pos => pos.id !== props.row.original.id),
                      toDelete: [...prevState.toDelete, prevState.value.find(el => el.id === props.row.original.id)],
                    };
                  });
                }}>
                X
              </button>
            ),
          },
          {
            Header: 'Odaberi novu poziciju',
            accessor: 'id',
            Cell: props => (
              <input
                type={'number'}
                className={'new-pos'}
                style={{ width: '100px', height: '25px', borderRadius: '4px', textAlign: 'center' }}
                value={props.row.original.newValue}
                onKeyUp={event => {
                  if (event.key === 'Enter') {
                    setPozicijeData(prevState => {
                      return {
                        ...prevState,
                        value: prevState.value.map(pozicija => {
                          return {
                            ...pozicija,//
                            value: pozicija.newValue,
                          };
                        }),
                      };
                    });
                  }
                }}
                onChange={event => {
                  const val = parseInt(event.target.value);
                  if (val > maxPositions || val < 1) {
                    toast('Max vrijednost pozicije je ' + maxPositions, { type: 'error' });
                    return;
                  }
                  setPozicijeData(prevState => {
                    return {
                      ...prevState,
                      value: prevState.value.map(pozicija => {
                        return {
                          ...pozicija,//
                          newValue: pozicija.id !== props.row.values.id ? pozicija.newValue : val,
                        };
                      }),
                    };
                  });

                }}
              />
            ),
          },
          {
            Header: 'Sadrzaj id',
            accessor: 'sadrzaj.id',
            Cell: props => {
              return <span>{props.row.values['sadrzaj.id']}</span>;
            },
          },
        ],
      },
    ],
    [],
  );
  const initialState = { hiddenColumns: ['sadrzaj.id'] };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    data,
    columns,
    initialState
  });

  return (
    <div className={'najnovije-vijesti '} style={{ marginBottom: '84px' }}>
      <table {...getTableProps()} className={'tabela tabela-header-left'}>
        <thead>
        {headerGroups.map((headerGroup, index) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={'hg-' + index}>
            {headerGroup.headers.map((column, columnIndex) => (
              <th key={'column-index-' + columnIndex} {...column.getHeaderProps()}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} key={'row-' + i}>
              {row.cells.map((cell, index) => {
                return (
                  <td key={'row-key-' + index} {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
        </tbody>
      </table>
    </div>
  );
};
