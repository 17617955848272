import React from 'react';

export const checkVowels = str => ['a', 'o', 'e', 'i', 'u'].includes(str.substr(-1));

export const generateImageEndString = (imagePath, zig, kolumna = false) => {
    if (kolumna) {
        return imagePath.replace('.', zig ? '-uz.' : '-u.');
    } else {
        return imagePath.replace('.', zig ? '-z.' : '.');
    }
};

export const getSlugsFromURL = url => {
    return url.substring(url.lastIndexOf('/') + 1, url.includes('?') ? url.indexOf('?') : url.length);
};



export const IMAGE_SIZES = {
    WIDTH_101: '--101',
    WIDTH_113: '--113',
    WIDTH_186: '--186',
    WIDTH_95: '--95',
};

export const IMAGE_SIZES_BIG_CARD = {
    HEIGHT_101: '--101',
    HEIGHT_115: '--115',
    HEIGHT_120: '--120',
    HEIGHT_166: '--166',
    HEIGHT_213: '--213',
};

export const MARGIN_BOTTOM_CLASSES = {
    MARGIN_1: 'mb-1',
    MARGIN_2: 'mb-2',
    MARGIN_3: 'mb-3',
    MARGIN_4: 'mb-4',
};

export const rubrikeTypes = {
    BREAKING_NEWS : 'BREAKING_NEWS',
    ISTRAZIVALI_SMO: 'ISTRAZIVALI_SMO',
    INFOS: 'INFOS',
    SPORT: 'SPORT',
    DIJASPORA: 'DIJASPORA',
    MAGAZIN: 'MAGAZIN',
    SVIJET: 'SVIJET',
}

export const checkPositionSelected = (state, position, rubrikaType ) => {
    return state.selectedWireFramePosition === position && state.selectedWireFrameRubrika === rubrikaType;
};


export const checkSelectedRubrikaInPozicioniranje = (state, rubrikaType ) => {
    return state.selectedWireFrameRubrika === rubrikaType;
};

