import React from 'react';

export default ({ data, changeEvent, selectedItemId }) => {
    if (!data) {
        return <div />;
    }
    const selectedItem = data.find(el => el.id === selectedItemId);
    const defaultValueName = selectedItem ? selectedItem.name : data[0].name;

    return (
        <select onChange={changeEvent} value={defaultValueName}>
            {data &&
                data.map((el, index) => {
                    return <option key={el.name}>{el.name}</option>;
                })}
        </select>
    );
};
