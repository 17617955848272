import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';

import moment from 'moment';
import { toast } from 'react-toastify';

import { Context } from '../../../../contexts/Store';
import { useSadrzajEvent } from '../../../../services/api';
import { API } from '../../../../common/constants/api';
import {
  sadrzajType,
  STATUS_SADRZAJA,
  STATUS_SADRZAJA_REVERSED,
  tipSadzajaFromBackendToRoute,
} from '../../../../common/constants/constants';
import { METHOD_TYPES } from '../../../../common/helpers/api';
import actionTypes from '../../../../common/constants/actionTypes';

import MultiLineInput from './MultiLineInput';
import OpcijaDodaj from './OpcijaDodaj';
import validator from './validator';
import TypeEditor from './TypeEditor';
import Kategorije from './Kategorije';
import Autori from './Autori';
import SadrzajInfo from './SadrzajInfo';
import generateSendReqeust from './generateSendReqeust';
import Loader from '../../../../common/components/Loader';
import LiveTypeEditor from './LiveTypeEditor';

import './tekst.scss';
import TimeUpdate from './TimeUpdate';
import useUnload from '../../../../hooks/useUnload';

export default ({ tipSadrzaja = sadrzajType.TEKST }) => {
  const [state, dispatch] = useContext(Context);
  const urlParams = useRouteMatch();
  const router = useHistory();

  useUnload(e => {
    e.preventDefault();
    e.returnValue = '';
  });

  useEffect(() => {
    return () => {
      dispatch({
        type: actionTypes.RESET_GLOBAL_STATE,
        payload: null,
      });
    };
  }, []);
  const [markedForDeletion, setMarkedForDeletion] = useState(null);
  const [sadrzaj, setSadrzaj] = useState({
    liveBlogNewTekst: '',
    liveBlog: null,
    tipSadrzaja: tipSadrzaja,
    statusSadrzaja: STATUS_SADRZAJA.DRAFT,
    isTekstEdit: urlParams.params.id ? urlParams.params.id : -1,
    confirmSave: false,
    selectedRubrika: state.ucitaneRubrike ? state.ucitaneRubrike[0] : null,
    selectedPodrubrika: state.ucitaneRubrike ? state.ucitaneRubrike[0].podrubrika[0] : null,
    shouldRedirectAfterSave: false,
    shouldReloadPage: false,
    NASLOVNA: {
      TXT: 'Dodaj naslovnu fotografiju',
      value: {
        img: '',
        izvor: '',
        opis: '',
        zig: null,
      },
      MODAL_TYPE: actionTypes.SADRZAJ_NEW_FOTO,
      error: '',
    },
    GALERIJA: {
      TXT: 'Dodaj galeriju',
      value: state.sadrzaj_teksta_gallery,
      MODAL_TYPE: actionTypes.SADRZAJ_GALERIJA,
      error: '',
    },
    DODATNE_OPCIJE: {
      TXT: 'Dodatne opcije',
      value: state.sadrzaj_teksta_dodatne_opcije,
      MODAL_TYPE: actionTypes.SADRZAJ_DODATNE_OPCIJE,
      error: '',
    },
    ANKETA: {
      TXT: 'Dodaj anketu',
      value: state.sadrzaj_teksta_anketa,
      MODAL_TYPE: actionTypes.SADRZAJ_ANKETA_NEW,
      error: '',
    },
    SCHEDULE: {
      MODAL_TYPE: actionTypes.SADRZAJ_SCHEDULE_DATE,
      value: null,
    },
    nadnaslov: {
      value: '',
      error: '',
    },
    naslov: {
      value: '',
      error: '',
    },
    podnaslov: {
      value: '',
      error: '',
    },
    tekst: {
      value: '',
      error: '',
    },
    teme: {
      values: [],
      error: '',
    },
    kljucneRijeci: {
      values: [],
      error: '',
    },
    autori: {
      values: [],
      error: '',
    },
  });

  useEffect(() => {
    setSadrzaj({
      ...sadrzaj,
      NASLOVNA: {
        ...sadrzaj.NASLOVNA,
        value: state.sadrzaj_teksta_photo,
        error: state.sadrzaj_teksta_photo ? '' : sadrzaj.NASLOVNA.error,
      },
      GALERIJA: {
        ...sadrzaj.GALERIJA,
        value: state.sadrzaj_teksta_gallery,
        error: state.sadrzaj_teksta_gallery ? '' : sadrzaj.GALERIJA.error,
      },
      DODATNE_OPCIJE: {
        ...sadrzaj.DODATNE_OPCIJE,
        value: state.sadrzaj_teksta_dodatne_opcije,
        error: state.sadrzaj_teksta_dodatne_opcije ? '' : sadrzaj.DODATNE_OPCIJE.error,
      },
      ANKETA: {
        ...sadrzaj.ANKETA,
        value: state.sadrzaj_teksta_anketa,
        error: state.sadrzaj_teksta_anketa ? '' : sadrzaj.ANKETA.error,
      },
      SCHEDULE: { ...sadrzaj.SCHEDULE, value: state.sadrzaj_teksta_schedule_date },
    });
  }, [state]);

  //FETCHING THE SADRZAJ FOR EDITING
  const { loading: editingLoad, response: responseForEditing } = useSadrzajEvent(
    API.SADRZAJ.SINGLE.replace(':id', urlParams.params.id ? urlParams.params.id : ''),
    METHOD_TYPES.GET,
    urlParams.params && urlParams.params.id,
    null,
    false,
  );

  const applyResponseForEditing = resData => {
    if (!state.ucitaneRubrike) {
      setTimeout(applyResponseForEditing(resData), 150);
      return;
    }
    const selectedRubrika = state.ucitaneRubrike.find(el => el.id === resData.rubrika.id);
    const selectedPodrubrika = selectedRubrika.podrubrika.find(el => el.id === resData.podrubrika.id);

    setSadrzaj({
      ...sadrzaj,
      liveBlog: resData.liveBlog,
      nadnaslov: { ...sadrzaj.nadnaslov, value: resData.nadnaslov },
      naslov: { ...sadrzaj.nadnaslov, value: resData.naslov },
      podnaslov: { ...sadrzaj.nadnaslov, value: resData.podnaslov },
      tekst: { ...sadrzaj.tekst, value: resData.content },
      teme: {
        ...sadrzaj.teme,
        values: resData.teme.map(el => {
          return el.tag;
        }),
      },
      kljucneRijeci: {
        ...sadrzaj.teme,
        values: resData.kljucneRijeci.map(el => {
          return el.tag;
        }),
      },
      autori: { ...sadrzaj, values: resData.autori },
      selectedRubrika: selectedRubrika,
      selectedPodrubrika: selectedPodrubrika,
      slug: resData.slug,
      isUpdating: true,
      createdAt: resData.createdAt,
      scheduleAt: resData.scheduleAt,
      updatedAt: resData.updatedAt ? resData.updatedAt : undefined,
      statusSadrzaja: resData.status
    });
    dispatch({ type: actionTypes.SADRZAJ_TEKSTA_LOAD_RESPONSE_INTO_STATE, payload: resData });
  };

  useEffect(() => {
    if (responseForEditing) {
      applyResponseForEditing(responseForEditing.data);
    }
  }, [responseForEditing]);

  //SAVING THE SADRZAJ
  const { error, loading, response } = useSadrzajEvent(
    state.isEditingTekst.hasValue
      ? API.SADRZAJ.UPDATE.ROOT +
      API.SADRZAJ.UPDATE.ID.replace(':id', state.isEditingTekst.id) +
      API.SADRZAJ.UPDATE.MAIN_DATA
      : API.SADRZAJ.NEW,
    METHOD_TYPES.POST,
    sadrzaj.confirmSave,
    generateSendReqeust(sadrzaj),
    false,
  );

  useEffect(() => {
    if (error) {
      toast('Greška..' + error.toString());
      setSadrzaj({ ...sadrzaj, confirmSave: false });
    }
    if (response) {
      setSadrzaj({ ...sadrzaj, confirmSave: false });
      toast('Sadržaj sačuvan.');
      if (tipSadrzaja === sadrzajType.LIVE_BLOG) {
        if (state.isEditingTekst.hasValue) {
          setTimeout(() => {
            window.location.reload();
          }, 500);
          return;
        } else {
          router.push(
            API.NOVI_SADRZAJ.ROOT +
            API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.ROOT +
            API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.LIVEBLOG +
            API.SADRZAJ.SINGLE.replace(':id', response.data.id),
          );
          return;
        }
      }

      if(sadrzaj.shouldRedirectAfterSave){
        const responseData = response.data;
        router.push(
          API.NOVI_SADRZAJ.ROOT +
          API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.ROOT +
          tipSadzajaFromBackendToRoute[responseData.tipSadrzaja] +
          API.SADRZAJ.SINGLE.replace(':id', responseData.id)
        );

        window.open(`${API.SITE_URL}${responseData.rubrika.path}${responseData.podrubrika.path}/${responseData.slug}?extras=${state.userToken}`, '_blank');
        return;
      }
      if(sadrzaj.shouldReloadPage){
        window.location.reload()
        return;
      }
      const responseData = response.data;
      router.push(
        API.NOVI_SADRZAJ.ROOT +
        API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.ROOT +
        tipSadzajaFromBackendToRoute[responseData.tipSadrzaja] +
        API.SADRZAJ.SINGLE.replace(':id', responseData.id)
      );
    }
  }, [response, error]);

  const objaviSadrzaj = (statusSadrzaja, shouldRedirectAfterSave = false, shouldReloadPage = false) => {
    const validatorCheck = validator(sadrzaj, setSadrzaj, statusSadrzaja);
    if (validatorCheck.result) {
      setSadrzaj({ ...sadrzaj, confirmSave: true, statusSadrzaja: statusSadrzaja,  shouldRedirectAfterSave: shouldRedirectAfterSave, shouldReloadPage: shouldReloadPage});
    } else {
      toast(validatorCheck.error, { type: 'error' });
    }
  };

  const previewSadrzaj= () => {
    objaviSadrzaj(STATUS_SADRZAJA.DRAFT, true)
  }

  const deleteTextAction = () => {
    if (confirm('Jeste li sigurni da želite da obrišete tekst?')) {
      setMarkedForDeletion(true)
    }
  }

  const { response: deleteResponse, error: deleteError } = useSadrzajEvent(
    API.SADRZAJ.SINGLE.replace(':id', urlParams.params.id  ? urlParams.params.id : null),
    METHOD_TYPES.DELETE,
    markedForDeletion,
    null,
    false
  );

  useEffect(()=>{
    if(deleteError){
        toast('Greška prilikom brisanja sadržaja.', { type: 'error' });
    }
    if(deleteResponse){
      toast('Sadržaj obrisan.');
      router.push(API.ARHIV);
    }
  },[deleteResponse]);

  return (
    <div className={'new-tekst'}>
      <div className={'new-tekst__left'}>
        <h2 style={{ marginBottom: '16px', marginTop: '-24px' }}>
          Tip sadržaja: <b className={'new-tekst__tip-sadrzaja'}>{tipSadrzaja}</b>
        </h2>
        <h2 style={{marginBottom: "12px"}}>Status: <b  style={{
          padding: '6px',
          background: '#ffcc00',
        }}>{STATUS_SADRZAJA_REVERSED[sadrzaj.statusSadrzaja]}</b></h2>
        {state.isEditingTekst.hasValue && sadrzaj.slug ? (
          <h3 style={{ marginTop: '12px', marginBottom: '4px', display: 'flex', alignItems: 'center' }}>
            Url teksta: <b style={{
            padding: '6px',
            background: '#ffcc00',
          }}>{`https://fosmedia.me${sadrzaj.selectedRubrika.path}${sadrzaj.selectedPodrubrika.path}/${sadrzaj.slug}`}</b>
          </h3>
        ) : null}
        <SadrzajInfo sadrzaj={sadrzaj} setSadrzaj={setSadrzaj}/>

        <h2 style={{ marginBottom: '12px' }}>Glavni tekst</h2>
        {!editingLoad ? <TypeEditor setSadrzaj={setSadrzaj} sadrzaj={sadrzaj}/> : null}

        {tipSadrzaja === sadrzajType.LIVE_BLOG && !editingLoad ? (
          <>
            <h2 style={{ marginBottom: '12px' }}> Update Live Info</h2>
            <LiveTypeEditor setSadrzaj={setSadrzaj} sadrzaj={sadrzaj}/>
          </>
        ) : null}

        {tipSadrzaja === sadrzajType.LIVE_BLOG ? (
          <>
            <div className={'inline-txt'} style={{ marginTop: '32px' }}>
              <button className={'btn-save'} onClick={() => objaviSadrzaj(STATUS_SADRZAJA.OBJAVLJEN)}>
                Ažuriraj
              </button>
            </div>
            {sadrzaj.liveBlog && sadrzaj.liveBlog.active ? (
              <button
                style={{ textDecoration: 'underline' }}
                onClick={() => {
                  setSadrzaj({ ...sadrzaj, confirmSave: true, endLiveBlog: true });
                }}>
                Završi Live
              </button>
            ) : null}
            <button
              style={{ textDecoration: 'underline', marginLeft: '12px' }}
              onClick={() => {
                router.push('/arhiv');
              }}>
              Izađi
            </button>
          </>
        ) : (
          <>
            <div className={'inline-txt'} style={{ marginTop: '32px' }}>
              <button className={'btn-save btn-save--green'} onClick={() => objaviSadrzaj(STATUS_SADRZAJA.OBJAVLJEN)}>
                {state.isEditingTekst.hasValue ? "Sačuvaj" : "Objavi" }
              </button>
              {state.isEditingTekst.hasValue ? <button className={'btn-save btn-save--red'} onClick={()=>deleteTextAction()}>Obriši tekst</button> : null}
              {
                !state.isEditingTekst.hasValue || sadrzaj.statusSadrzaja === STATUS_SADRZAJA.DRAFT ? <a
                  onClick={()=>previewSadrzaj()}
                  className={'btn-save'}
                  target={'_blank'}>
                  Preview
                </a> : ''
              }
            </div>
            <button
              className={'btn-save'}
              onClick={() => objaviSadrzaj(STATUS_SADRZAJA.DRAFT, true,true)}>
              Sačuvaj Draft
            </button>
            <span>
                            <button
                              className={'btn-save'}
                              onClick={() => {
                                dispatch({
                                  type: actionTypes.SHOW_MODAL,
                                  payload: {
                                    name: actionTypes.SADRZAJ_SCHEDULE_DATE,
                                    data: sadrzaj.SCHEDULE.value,
                                  },
                                });
                              }}>
                                Zakaži objavu{' '}
                            </button>
                            <span>{sadrzaj.SCHEDULE.value !== undefined
                            || sadrzaj.scheduleAt
                              ? ` (Zakazano) ${sadrzaj.SCHEDULE.value ? moment(sadrzaj.SCHEDULE.value.dateValue).format('DD-MM-YYYY HH:mm') : moment(sadrzaj.scheduleAt).format('DD-MM-YYYY HH:mm')}` : ''}</span>
                        </span>
            {
              state.isEditingTekst.hasValue ? null : <button
                className={'btn-save'}
                onClick={()=> {
                  router.push(API.NOVI_SADRZAJ.ROOT)
                }
                }>
                Poništi
              </button>
            }
          </>
        )}

        { loading && <Loader/>}
      </div>

      <div className={'new-tekst__right'}>
        <MultiLineInput
          object={'teme'}
          setSadrzaj={setSadrzaj}
          sadrzaj={sadrzaj}
          naslov={'Teme:'}
          placeholder={'Unesite temu i pretisnite ENTER'}
        />
        <MultiLineInput
          object={'kljucneRijeci'}
          setSadrzaj={setSadrzaj}
          sadrzaj={sadrzaj}
          naslov={'Ključne riječi:'}
          placeholder={'Unesite riječ i pretisnite ENTER'}
        />

        <Autori sadrzaj={sadrzaj} setSadrzaj={setSadrzaj}/>

        <Kategorije sadrzaj={sadrzaj} setSadrzaj={setSadrzaj}/>

        <OpcijaDodaj
          opcija={sadrzaj.NASLOVNA}
          value={state.sadrzaj_teksta_photo}
          data={{ tipSadrzaja: tipSadrzaja }}
        />
        <OpcijaDodaj
          opcija={sadrzaj.GALERIJA}
          value={state.sadrzaj_teksta_gallery}
          data={{ tipSadrzaja: tipSadrzaja }}
        />
        <OpcijaDodaj
          opcija={sadrzaj.DODATNE_OPCIJE}
          value={state.sadrzaj_teksta_dodatne_opcije}
          data={{ tipSadrzaja: sadrzajType }}
        />
        <OpcijaDodaj
          opcija={sadrzaj.ANKETA}
          value={state.sadrzaj_teksta_anketa}
          data={{ tipSadrzaja: sadrzajType }}
        />
        <TimeUpdate selector={'createdAt'} title={'Prikaz datuma objave'} sadrzaj={sadrzaj} setSadrzaj={setSadrzaj}/>
        <TimeUpdate selector={'updatedAt'} title={'Prikaz datuma izmjene'} sadrzaj={sadrzaj} setSadrzaj={setSadrzaj}/>

        {/*<OpcijaDodaj*/}
        {/*  opcija={sadrzaj.KONTAKT}*/}
        {/*  value={state.sadrzaj_teksta_kontakt_us}*/}
        {/*  data={{ tipSadrzaja: sadrzajType }}*/}
        {/*/>*/}
      </div>
    </div>
  );
};
