import React, { useContext, useEffect } from 'react';
import BreakingNews from '../gridovi/BreakingNews';
import IstrazivaliSmo from '../gridovi/IstrazivaliSmo';
import Sport from '../gridovi/Sport';
import Fostav from '../gridovi/Fostav';
import Magazin from '../gridovi/Magazin';
import Svijet from '../gridovi/Svijet';
import { API } from '../../../common/constants/api';
import { Link } from 'react-router-dom';
import { ContextPozicije } from '../../../contexts/Pozicioniranje';
import actionTypes from '../../../common/constants/actionTypes';
import { usePozicijeEvent, useRubrikeEvent } from '../../../services/api';
import { toast } from 'react-toastify';
import Grid1 from '../gridovi/podrubrike/Grid1';
import Grid2 from '../gridovi/podrubrike/Grid2';
import Grid3 from '../gridovi/podrubrike/Grid3';
import GridNav from '../gridNav';
import { useRouteMatch } from 'react-router';
import { Context } from '../../../contexts/Store';
import { METHOD_TYPES } from '../../../common/helpers/api';
import PocetnaCommonGrid2 from '../gridovi/PocetnaCommonGrid2';
import { rubrikeTypes } from '../../../common/helpers/strings';
import PocetnaCommonGrid1 from '../gridovi/PocetnaCommonGrid1';

export const wireframeRubrike = [
  {
    value: 'Početna',
    path: '/',
  },
  {
    value: 'Infos',
    path: '/infos',
  },
  {
    value: 'Sport',
    path: '/sport',
  },
  {
    value: 'Dijaspora',
    path: '/dijaspora',
  },
  {
    value: 'Magazin',
    path: '/magazin',
  },
  {
    value: 'Svijet',
    path: '/svijet',
  },
];

export default ({ positions, setPositions }) => {
  const [state, dispatch] = useContext(ContextPozicije);
  const [globalState, dispatchGlobal] = useContext(Context);
  const rubrika = useRouteMatch().params.rubrika;
  const isPocetna = !useRouteMatch().params.rubrika;

  const { response: rubrikeResponse } = useRubrikeEvent(
    API.RUBRIKE.SVE,
    METHOD_TYPES.GET,
    globalState.ucitaneRubrike === undefined || globalState.ucitaneRubrike === null,
    null,
    false,
  );

  useEffect(() => {
    if (rubrikeResponse) {
      dispatchGlobal({ type: actionTypes.UCITAJ_RUBRIKE, payload: rubrikeResponse.data });
    }
  }, [rubrikeResponse]);

  const { response, error, loading } = usePozicijeEvent(
    API.SADRZAJ.POZICIJE.INSERT_SINGLE,
    'POST',
    state.insertSinglePozicija,
    state.insertSinglePozicija,
  );

  useEffect(() => {
    if (response) {
      dispatch({
        type: actionTypes.REMOVE_SELECTED_SADRZAJ_FROM_NAJNOVIJE,
        payload: null,
      });
      toast('Pozicija sačuvana.');
    }
  }, [response]);

  const renderPocetna = () => {
    return (
      <>
        <BreakingNews/>
        <IstrazivaliSmo/>
        <PocetnaCommonGrid2 title={'InFOS'} rubrikaType={rubrikeTypes.INFOS}/>
        <PocetnaCommonGrid1 title={'Sport'} rubrikaType={rubrikeTypes.SPORT}/>
        <PocetnaCommonGrid1 title={'Dijaspora'} rubrikaType={rubrikeTypes.DIJASPORA}/>
        <PocetnaCommonGrid2 title={'Magazin'} rubrikaType={rubrikeTypes.MAGAZIN}/>
        <PocetnaCommonGrid1 title={'Svijet'} rubrikaType={rubrikeTypes.SVIJET}/>
      </>
    );
  };

  const renderRubrika = () => {
    const selectedPodrubrike = globalState.ucitaneRubrike.find(el => el.path.includes(rubrika)).podrubrika;
    return selectedPodrubrike.map((podrubrika, index) => {
      switch (index) {
        case 0:
        case 1:
          return <Grid1 title={podrubrika.name} podrubrikaId={podrubrika.id} key={`rubrika-${index}`}/>;
        case 2:
          return <Grid2 title={podrubrika.name} podrubrikaId={podrubrika.id} key={`rubrika-${index}`}/>;
        case 3:
          return <Grid3 title={podrubrika.name} podrubrikaId={podrubrika.id} key={`rubrika-${index}`}/>;
        case 4:
          return <Grid2 title={podrubrika.name} podrubrikaId={podrubrika.id} key={`rubrika-${index}`}/>;
        case 5:
          return <Grid3 title={podrubrika.name} podrubrikaId={podrubrika.id} key={`rubrika-${index}`}/>;
        default:
          return <Grid3 title={podrubrika.name} podrubrikaId={podrubrika.id} key={`rubrika-${index}`}/>;
      }
    });
  };

  return (
    <div className={'wireframe'}>
      <div className={'wireframe__options'}>
        <button
          className={'btn-save btn-save--green'}
          onClick={() => {
            if (state.selectedSadrzaj === null) {
              toast('Morate izabrati sadržaj.', { type: 'error' });
              return;
            }
            if (state.selectedWireFramePosition === null) {
              toast('Morate izabrati poziciju.', { type: 'error' });
              return;
            }
            dispatch({
              type: actionTypes.SAVE_SINGLE_POZICIJA,
              payload: null,
            });
          }}>
            Sačuvaj
        </button>
        <button
          className={'btn-save'}
          onClick={() => {
            dispatch({
              type: actionTypes.RESET_SELECTED_WIREFRAME_POSITION,
              payload: null,
            });
          }}>
          Reset
        </button>
        <Link to={API.UREDI_SADRZAJ.ROOT + API.UREDI_SADRZAJ.REPOZICIONIRAJ}>
          <button className={'btn-save'}>Izbriši</button>
        </Link>
      </div>
      <h2 style={{ textAlign: 'center', marginTop: '12px', marginBottom: '12px' }}>Pozicioniraj sadržaj</h2>
      <div className={'wire-nav'} >
        <GridNav isPocetna={isPocetna}/>
      </div>
      <div className={'auto-position'}>
        <div>
          <input type={'checkbox'} checked={state.automatskoPozicioniranje} onChange={(e)=>{
            dispatch({
              type: actionTypes.AUTOMATSKO_POZICIONIRANJE,
              payload: e.target.checked
            });
          }}/> <h2 style={{ marginLeft: "6px" }}>Automatsko pozicioniranje ( Status : <b style={{color :state.automatskoPozicioniranje ? 'green': 'red'}}>{state.automatskoPozicioniranje ? "ON" : "OFF"}</b> )</h2>
        </div>
        <p>Ako je čekirano - Tekstovi se automatski pomjeraju za jednu vecu poziciju, od odabrane pa nadalje.</p>
        <p>Ako nije čekirano - Tekstovi se ne pomjeraju automatski, vec se stari tekst uklanja i mijenja odabranim.</p>
      </div>
      {isPocetna ? renderPocetna() : renderRubrika()}
    </div>
  );
};
