export const API = {
  BASE: process.env.REACT_APP_SERVER,
  SITE_URL: process.env.REACT_APP_FRONTEND,
  AVATARS: '/avatars/',
  LOGIN: '/auth/login',
  TOKENS: '/tokens',
  DASHBOARD: '/dashboard',
  KOMENTARI : {
    ROOT : '/comments',
    LOAD_30:'/read30',
    BAN_KORISNIKA: '/ban',
    UPDATE_KOMENTAR: '/update',
    DELETE_KOMENTAR: '/delete',
    PARAMS: {
      KORISNIK_ID :'userId=',
      SLUG: 'slug=',
    }
  },
  RUBRIKE:{
    ROOT: '/rubrike',
    SVE: '/all',
  },
  ZAPOSLENI: {
    ROOT: '/user',
    NOVI: '/novi',
    PREGLED: '/svi',
    SINGLE: '/:id',
    DETAILS: '/author-details/:id',
    SELF:'',
  },
  STATISTIKA: '/statistika',
  ARHIV: '/arhiv',
  BANERI_SOLO: '/baneri',
  BANERI: '/sadrzaj/baneri',
  NOTIFIKACIJE: {
    ROOT: '/notifications',
    NEW: '/new'
  },
  SADRZAJ : {
    ROOT: '/sadrzaj',
    SINGLE:'/:id',
    NEW:'/novi',
    POZICIJE: {
      ROOT: '/pozicije',
      INSERT_SINGLE: '/insertPozicija',
      GET_POZICIJE_BY_RUBRIKA:'/:rubrika',
      BULK_UPDATE: '/update'
    },
    UPDATE:{
      ROOT:'/update',
      ID:'/:id',
      NASLOVNA:'/naslovna',
      KONTANKT:'/kontakt',
      ANKETA:'/anketa',
      DODATNE_OPCIJE: '/dodatne_opcije',
      GALERIJA: '/galerija',
      MAIN_DATA: '/main_data'
    },
    CONFIRM_DELETE:'/confirm',
    UPLOAD_MULTIPLE_PICTURES : '/uploadPictures',
    UPLOAD_TYPE_TOOL : '/uploadForTypeTool',
    SEKUNDARDNI_SADRZAJ: {
      ROOT: '/sekundardni-sadrzaj',
      ANKETA: '/anketa',
      IZJAVA_DANA: '/izjava-dana'
    }
  },
  IMAGES: {
    ROOT: '/images',
    TMP : '/tmp',
  },
  GALLERY_PREVIEW: {
    ROOT :'/gallery-preview',
  },
  NOVI_SADRZAJ: {
    ROOT: '/sadrzaj',
    RUBRIKA: '/rubrika',
    PRIMARNI_SADRZAJ: {
      ROOT: '/primarni-sadrzaj',
      TEKST: '/tekst',
      FOTO: '/foto',
      VIDEO: '/video',
      KOLUMNA: '/kolumna',
      LIVEBLOG: '/liveBlog'
    },
    SEKUNDARDNI_SADRZAJ: {
      ROOT: '/sekundardni-sadrzaj'
    }

  },
  UREDI_SADRZAJ: {
    ROOT: '/uredi-sadrzaj',
    POZICIONIRAJ: '/pozicioniraj',
    REPOZICIONIRAJ: '/repozicioniraj',
    RUBRIKA: '/:rubrika',
  },
  KOMENT_PANEL: '/koment-panel',
  PUSH_NOTIFIKACIJE: '/push-notifikacije',
  SERVISI: '/servisi',
  MARKETING: '/marketing',
  OPCIJE: '/opcije',
  QUERY_PARAM:'?',
  PARAMS : {
    NAME : 'name=',
    PAGE : 'page=',
    PAGE_OFFSET: 'pageOffset=',
    AND: "&",
    SADRZAJ_ID:'sadrzaj_id=',
  }
}
