import React  from 'react';

import BigCard from './BigCard';
import LeaderboardCard from './LeaderboardCard';
import { IMAGE_SIZES_BIG_CARD } from '../helpers/strings';

import './LeaderBoardWithBigCard.scss';

export default ({positionOne,positionTwo, rubrikaType, isBigCardEnabled = true}) => {
    return (
        <div className={'leaderboard-with-big space-between'}>
            <LeaderboardCard cardColor={'white-gray'}  position={positionOne} rubrikaType={rubrikaType}/>
          {isBigCardEnabled && <BigCard imageModifier={IMAGE_SIZES_BIG_CARD.HEIGHT_101} position={positionTwo} rubrikaType={rubrikaType}/>}
        </div>
    );
};

