import React, { useContext, useState, useEffect } from 'react';
import { Context } from '../../../contexts/Store';
import actionTypes from '../../constants/actionTypes';
import { useCommentsEvent } from '../../../services/api';
import { API } from '../../constants/api';
import Loader from '../Loader';
import { toast } from 'react-toastify';

export default () => {
    const [state, dispatch] = useContext(Context);

    const [user, setUser] = useState({ ...state.showModal.data, ban: false });

    const { response, error, loading } = useCommentsEvent(API.KOMENTARI.BAN_KORISNIKA, 'POST', user.ban, user);

    useEffect(() => {
        if (response || error) {
            const status = response ? response.data : 'Greška - ' + error.toString();
            toast(status);
            if(error){
                setUser({...user,ban:false})
            }else {
                 dispatch({ type: 'HIDE_MODAL' });
                 dispatch({ type: actionTypes.BAN_KORISNIKA_UPDATE_STATE, payload: user.id });
            }
        }
    }, [response, error]);

    return (
        <div style={{ padding: '32px' }}>
            <p>Jeste li sigurni da želite banovati korisnika </p>
            <b>test</b>

            {loading ? (
                <Loader />
            ) : (
                <div>
                    <button
                        className={'custom-modal__btn-da'}
                        onClick={() => {
                            setUser({ ...user, ban: true });
                            // dispatch({ type: 'HIDE_MODAL' });
                            // dispatch({ type: actionTypes.DELETE_ZAPOSLENI_CONFIRM, payload: true });
                        }}
                        style={{ marginRight: '32px', marginTop: '22px' }}>
                        Da
                    </button>
                    <button
                        className={'custom-modal__btn-ne'}
                        onClick={() => {
                            dispatch({ type: 'HIDE_MODAL' });
                        }}>
                        Zatvori
                    </button>
                </div>
            )}
        </div>
    );
};
