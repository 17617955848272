import React from 'react';

import DeleteUser from './modals/deleteUser';
import ZaposleniEdit from '../../pages/zaposleni/novi/index';
import SaveRubrika from './modals/saveRubrika';
import SaveNewFotoTekst from './modals/sadrzaj/newPhoto';
import Galerija from './modals/sadrzaj/galerija';
import DodatneOpcijeTekst from './modals/sadrzaj/dodatneOpcije';
import AnketaTekst from './modals/sadrzaj/anketa';
import AnketaSekundardniSadrzaj from './modals/sadrzaj/anketa';
import IzjavaDana from './modals/sadrzaj/izjavaDana';
import KomentariBanKorisnika from './modals/banKorisnika'
import IzmjenaKomentara from './modals/izmjenaKomentara'
import DeleteKomentar from './modals/deleteKomentar'
import PreviewSadrzaj from './modals/sadrzaj/previewSadrzaj'
import ScheduleDate from './modals/sadrzaj/scheduleDate'


import { anketaTypes } from '../constants/constants';
import Kampanja from './modals/sadrzaj/kampanja';

export default {
  EDIT_USER: <ZaposleniEdit title={'Izmijenite podatke za korisnika'} extraClass={'zaposleni-novi--edit'}
    actionName={'Sačuvaj'} action={'UPDATE'}
  />,
  DELETE_USER: <DeleteUser/>,
  BAN_KORISNIKA : <KomentariBanKorisnika/>,
  IZMIJENI_KOMENTAR_KORISNIKA : <IzmjenaKomentara/>,
  DELETE_KOMENTAR_KORISNIKA : <DeleteKomentar/>,
  SAVE_RUBRIKA: <SaveRubrika/>,
  SADRZAJ_PREVIEW: <PreviewSadrzaj/>,
  SADRZAJ_NEW_FOTO: <SaveNewFotoTekst/>,
  SADRZAJ_GALERIJA: <Galerija />,
  MANAGE_BANNER: <Kampanja />,
  SADRZAJ_DODATNE_OPCIJE: <DodatneOpcijeTekst/>,
  SADRZAJ_ANKETA_NEW : <AnketaTekst/>,
  SADRZAJ_SCHEDULE_DATE : <ScheduleDate/>,
  SEKUNDARDNI_SADRZAJ_ANKETA : <AnketaSekundardniSadrzaj tipAnkete={anketaTypes.SEKUNDARDNI_SADRZAJ}/>,
  SEKUNDARDNI_SADRZAJ_IZJAVA_DANA : <IzjavaDana/>
}
