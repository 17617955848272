import React  from 'react';
import DatePicker from 'react-datepicker';

export default ({ title = 'Prikaz datuma objave', sadrzaj, setSadrzaj, selector }) => {
  return <div className={'inline-txt datum-objave'}>
    <span><b>{title}</b></span>
    <DatePicker
      showTimeSelect
      selected={sadrzaj[selector] !== undefined ? new Date(sadrzaj[selector]) : null}
      onChange={date => {
        setSadrzaj({ ...sadrzaj, [selector]: date });
      }}
      timeIntervals={5}
      timeCaption="time"
      timeFormat="HH:mm"
      dateFormat="dd.MM.Y h:mm aa"
    />
  </div>;
};
