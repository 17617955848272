import React from 'react';

import FixedCardList from './FixedCardList';
import BigCard from './BigCard';

import { IMAGE_SIZES, IMAGE_SIZES_BIG_CARD } from '../helpers/strings';

import './ThreeSmallOneBigOneMedium.scss';

const ThreeSmallOneBigOneMedium = ({smallPositions = ['5','6','7'], rubrikaType}) => {
    return (
        <div className={'threeSmallOneBigOneMed'}>
            <FixedCardList hasPicture={true} rows={smallPositions} imageModifier={IMAGE_SIZES.WIDTH_95} rubrikaType={rubrikaType}/>
            <BigCard imageModifier={IMAGE_SIZES_BIG_CARD.HEIGHT_166} position={'6'} rubrikaType={rubrikaType}/>
            <BigCard imageModifier={IMAGE_SIZES_BIG_CARD.HEIGHT_115} position={'7'} rubrikaType={rubrikaType}/>
        </div>
    );
}

export default ThreeSmallOneBigOneMedium;
