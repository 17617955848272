import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../../contexts/Store';
import actionTypes from '../../constants/actionTypes';
import { useCommentsEvent } from '../../../services/api';
import { API } from '../../constants/api';
import Loader from '../Loader';
import { toast } from 'react-toastify';

export default () => {
    const [state, dispatch] = useContext(Context);

    const [komentarObjekat, setKomentarObjekat] = useState({ ...state.showModal.data, shouldUpdate : false });

    const { response, error, loading } = useCommentsEvent(API.KOMENTARI.UPDATE_KOMENTAR, 'POST', komentarObjekat.shouldUpdate, komentarObjekat);

    useEffect(() => {
        if (response || error) {
            const status = response ? response.data : 'Greška - ' + error.toString();
            toast(status);
            if(error){
                setKomentarObjekat({...komentarObjekat,shouldUpdate:false})
            }else {
                 dispatch({ type: 'HIDE_MODAL' });
                 dispatch({ type: actionTypes.KOMENTAR_KORISNIKA_UPDATE_STATE, payload: komentarObjekat});
            }
        }
    }, [response, error]);

    return (
        <div style={{ padding: '32px',minWidth:'540px' }}>
            <h3>Update komentara </h3>

            {loading ? (
                <Loader />
            ) : (
                <div>
                    <textarea value={komentarObjekat.commentDetail} style={{width:'100%',minHeight:'180px'}}
                    onChange={(event => {
                        setKomentarObjekat({...komentarObjekat, commentDetail:event.target.value})
                    })}
                    />

                    <div>
                        <button
                            className={'custom-modal__btn-da'}
                            onClick={() => {
                                setKomentarObjekat({...komentarObjekat, shouldUpdate: true})
                            }}
                            style={{ marginRight: '32px', marginTop: '22px' }}>
                            Sačuvaj
                        </button>
                        <button
                            className={'custom-modal__btn-ne'}
                            onClick={() => {
                                dispatch({ type: 'HIDE_MODAL' });
                            }}>
                            Zatvori
                        </button>
                    </div>

                </div>
            )}
        </div>
    );
};
