import './Infos.scss';
import React from 'react';
import SectionTitle from '../../../common/components/SectionTitle';
import LeaderBoardWithBigCard from '../../../common/components/LeaderBoardWithBigCard';
import TwoBigThreeSmallCards from '../../../common/components/TwoBigThreeSmallCards';
import { rubrikeTypes } from '../../../common/helpers/strings';

export default ({ title = "InFOS", rubrikaType = rubrikeTypes.INFOS }) => {
  return (
    <div className={'infos-container'}>
      <SectionTitle title={title} />
      <div className={'infos-container__section-top'}>
        <LeaderBoardWithBigCard  positionOne={'1'} positionTwo={'2'} rubrikaType={rubrikaType}/>
      </div>
      <div className={'infos-container__section-bottom'}>
        <TwoBigThreeSmallCards hasPicture={false} bigPositions={['3','4']} rubrikaType={rubrikaType}/>
      </div>
    </div>
  );
};
