import React, { useContext, useEffect, useState } from 'react';
import './Komentari.scss';
import actionTypes from '../../common/constants/actionTypes';
import { Context } from '../../contexts/Store';
import moment from 'moment';
import { useTable } from 'react-table';
import { useCommentsEvent } from '../../services/api';
import { API } from '../../common/constants/api';
import Loader from '../../common/components/Loader';
import ReactPaginate from 'react-paginate';
import { getSlugsFromURL } from '../../common/helpers/strings';

export default () => {
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [state, dispatch] = useContext(Context);
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentPage, setSelectedPage] = useState(0);
  const [tekstUrl, setTekstUrl] = useState('');
  const [slug, setSlug] = useState('');

  useEffect(() => {
    if (state.komentar_korisnika_delete) {
      setData(data.filter(komentar => komentar.id !== state.komentar_korisnika_delete.id));
    }
  }, [state.komentar_korisnika_delete]);
  useEffect(() => {
    if (state.ban_korisnika_update_state) {
      setData(
        data.map(el => {
          return {
            ...el,
            user: {
              ...el.user,
              bannedAt: state.ban_korisnika_update_state === el.user.id ? true : el.user.bannedAt,
            },
          };
        }),
      );
    }
  }, [state.ban_korisnika_update_state]);
  useEffect(() => {
    if (state.komentar_korisnika_update) {
      setData(
        data.map(el => {
          return {
            ...el,
            commentDetail:
              el.id === state.komentar_korisnika_update.id
                ? state.komentar_korisnika_update.commentDetail
                : el.commentDetail,
          };
        }),
      );
    }
  }, [state.komentar_korisnika_update]);

  const { response, loading } = useCommentsEvent(
    API.KOMENTARI.LOAD_30 +
    API.QUERY_PARAM +
    API.PARAMS.PAGE_OFFSET +
    currentPage * 30 +
    (selectedUser ? API.PARAMS.AND + API.KOMENTARI.PARAMS.KORISNIK_ID + selectedUser.id : '') +
    (slug !== '' ? API.PARAMS.AND + API.KOMENTARI.PARAMS.SLUG + slug : ''),
    'GET',
    currentPage + (selectedUser ? selectedUser + selectedUser.id : selectedUser) + slug,
    null,
  );

  const handlePageClick = event => {
    setSelectedPage(event.selected);
  };

  useEffect(() => {
    if (response && response.data.comments) {
      setData(response.data.comments);
      setPageCount(response.data.pageCount);
    }
  }, [response]);

  const columns = React.useMemo(
    () => [
      {
        Header: ' ',
        columns: [
          {
            Header: 'Tekst komentara',
            accessor: 'commentDetail',
          },
          {
            Header: 'Datum',
            accessor: 'createdAt',
            Cell: props => <span> {moment(props.row.values.createdAt).format('DD-MM-YYYY HH:mm')} </span>,
          },
          {
            Header: 'Link',
            accessor: 'sadrzaj',
            Cell: props => (
              <a
                href={API.SITE_URL + props.row.values.sadrzaj.rubrika.path + (props.row.values.sadrzaj.podrubrika ? props.row.values.sadrzaj.podrubrika.path : '')+ '/' + props.row.values.sadrzaj.slug}
                target={'_blank'}
                rel="noreferrer"
              >

                Link
              </a>
            ),
          },
          {
            Header: 'Username',
            accessor: 'user.username',
            //                                        setSelectedUser(props.row.original.user);
            Cell: props => (
              <span className={'username'} onClick={() => setSelectedUser(props.row.original.user)}>
                                {props.row.original.user.username}
                            </span>
            ),
          },
          {
            Header: 'Status korisnika',
            accessor: 'user.bannedAt',
            Cell: props => (
              <div
                className={`user-status ${
                  props.row.original.user.bannedAt ? 'user-status--banned' : ''
                }`}>
                {props.row.original.user.bannedAt ? 'Banovan' : 'Aktivan'}
              </div>
            ),
            width: '100px',
          },
          {
            Header: 'Opcije',
            accessor: 'id',
            shouldSort: false,
            Cell: props => (
              <span className="opcije">
                                {!props.row.original.user.bannedAt && (
                                  <a
                                    className={'ban'}
                                    onClick={() => {
                                      dispatch({
                                        type: 'SHOW_MODAL',
                                        payload: {
                                          name: actionTypes.BAN_KORISNIKA,
                                          data: props.row.original.user,
                                        },
                                      });
                                    }}>
                                    Ban korisnika
                                  </a>
                                )}

                <a
                  className={'izmijeni'}
                  onClick={() => {
                    dispatch({
                      type: 'SHOW_MODAL',
                      payload: {
                        name: actionTypes.IZMIJENI_KOMENTAR_KORISNIKA,
                        data: props.row.original,
                      },
                    });
                  }}>
                                    Izmijeni
                                </a>

                                <a
                                  className={'obrisi'}
                                  onClick={() => {
                                    dispatch({
                                      type: 'SHOW_MODAL',
                                      payload: {
                                        name: actionTypes.DELETE_KOMENTAR_KORISNIKA,
                                        data: props.row.original,
                                      },
                                    });
                                  }}>
                                    Obriši
                                </a>
                            </span>
            ), // Custom cell components!
          },
        ],
      },
    ],
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    data,
    columns,
  });

  return (
    <div className={'komentari'}>
      <div className={'komentari__header'}>
        {!selectedUser && (
          <div className={'komentari__tekst-container'}>
            <input
              placeholder={'Komentari sa teksta (URL)'}
              value={tekstUrl}
              onChange={event => setTekstUrl(event.target.value)}
            />
            {tekstUrl.length > 0 && (
              <button
                className={'btn-save'}
                onClick={() => {
                  setSlug(getSlugsFromURL(tekstUrl));
                }}>
                Učitaj
              </button>
            )}
          </div>
        )}

        <h2>{selectedUser ? 'Istorija komentara' : 'Posljednih 30 komentara'}</h2>
        {selectedUser && (
          <span className={'selected-user'}>
                        Korisnik: <span style={{ fontWeight: 'bold' }}>{selectedUser.username}</span>
                    </span>
        )}
        {selectedUser && (
          <button
            className={'btn-save'}
            style={{ position: 'absolute', right: '62px' }}
            onClick={() => setSelectedUser(null)}>
            Nazad
          </button>
        )}
      </div>
      {loading ? (
        <Loader/>
      ) : (
        <>
          <table {...getTableProps()} className={'tabela'}>
            <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr key={'koment-header' + index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, colIndex) => (
                  <th key={'komenter-col' + colIndex} {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={'koment-row' + i} {...row.getRowProps()}>
                  {row.cells.map((cell, index) => {
                    return <td key={'coment-row-col' + index} {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
            </tbody>
          </table>

          <ReactPaginate
            previousLabel={'Prošla'}
            nextLabel={'Sledeća'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            forcePage={currentPage}
          />
        </>
      )}
    </div>
  );
};
