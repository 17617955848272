export default {
  SHOW_MODAL :'SHOW_MODAL',
  REFRESH_DATA: 'REFRESH_DATA',
  MANAGE_BANNER: 'MANAGE_BANNER',
  EDIT_USER: 'EDIT_USER',
  DELETE_USER: 'DELETE_USER',
  DELETE_TEKST_MODAL: 'DELETE_TEKST_MODAL',
  DELETE_ZAPOSLENI_CONFIRM: 'DELETE_ZAPOSLENI_CONFIRM',
  BAN_KORISNIKA: 'BAN_KORISNIKA',
  BAN_KORISNIKA_UPDATE_STATE: 'BAN_KORISNIKA_UPDATE_STATE',
  KOMENTAR_KORISNIKA_UPDATE_STATE: 'KOMENTAR_KORISNIKA_UPDATE_STATE',
  KOMENTAR_KORISNIKA_UPDATE_DELETE_STATUS: 'KOMENTAR_KORISNIKA_UPDATE_DELETE_STATUS',
  SAVE_RUBRIKA: 'SAVE_RUBRIKA',
  SADRZAJ_PREVIEW: 'SADRZAJ_PREVIEW',
  SADRZAJ_NEW_FOTO: 'SADRZAJ_NEW_FOTO',
  SADRZAJ_GALERIJA: 'SADRZAJ_GALERIJA',
  SADRZAJ_DODATNE_OPCIJE: 'SADRZAJ_DODATNE_OPCIJE',
  SADRZAJ_ANKETA_NEW: 'SADRZAJ_ANKETA_NEW',
  SADRZAJ_KONTAKT_US: 'SADRZAJ_KONTAKT_US',
  SADRZAJ_SCHEDULE_DATE: 'SADRZAJ_SCHEDULE_DATE',
  SADRZAJ_TEKSTA_SAVE_SHEDULE_DATE: 'SADRZAJ_TEKSTA_SAVE_SHEDULE_DATE',
  UCITAJ_RUBRIKE: 'UCITAJ_RUBRIKE',
  SADRZAJ_TEKSTA_SAVE_PHOTO: 'SADRZAJ_TEKSTA_SAVE_PHOTO',
  SADRZAJ_TEKSTA_SAVE_GALLERY: 'SADRZAJ_TEKSTA_SAVE_GALLERY',
  SADRZAJ_TEKSTA_SAVE_DODATNE_OPCIJE: 'SADRZAJ_TEKSTA_SAVE_DODATNE_OPCIJE',
  SADRZAJ_TEKSTA_SAVE_ANKETA:  'SADRZAJ_TEKSTA_SAVE_ANKETA',
  SADRZAJ_TEKSTA_SAVE_KONTAKT_US: 'SADRZAJ_TEKSTA_SAVE_KONTAKT_US',
  SADRZAJ_TEKSTA_LOAD_RESPONSE_INTO_STATE: 'SADRZAJ_TEKSTA_LOAD_RESPONSE_INTO_STATE',
  SEKUNDARDNI_SADRZAJ_ANKETA: 'SEKUNDARDNI_SADRZAJ_ANKETA',
  SEKUNDARDNI_SADRZAJ_SAVE_ANKETA: 'SEKUNDARDNI_SADRZAJ_SAVE_ANKETA',
  SEKUNDARDNI_SADRZAJ_IZJAVA_DANA: 'SEKUNDARDNI_SADRZAJ_IZJAVA_DANA',
  IZMIJENI_KOMENTAR_KORISNIKA: 'IZMIJENI_KOMENTAR_KORISNIKA',
  DELETE_KOMENTAR_KORISNIKA: 'DELETE_KOMENTAR_KORISNIKA',
  RESET_GLOBAL_STATE:'RESET_GLOBAL_STATE',


  //
  SELECTED_WIREFRAME_POSITION: 'SELECTED_WIREFRAME_POSITION',
  SELECTED_WIREFRAME_SADRZAJ: 'SELECTED_WIREFRAME_SADRZAJ',
  REMOVE_SELECTED_SADRZAJ_FROM_NAJNOVIJE: 'REMOVE_SELECTED_SADRZAJ_FROM_NAJNOVIJE',
  RESET_SELECTED_WIREFRAME_POSITION: 'RESET_SELECTED_WIREFRAME_POSITION',
  SAVE_SINGLE_POZICIJA: 'SAVE_SINGLE_POZICIJA',
  AUTOMATSKO_POZICIONIRANJE: 'AUTOMATSKO_POZICIONIRANJE'
}
