import React, { useContext, useEffect, useState } from 'react';
import { useSadrzajEvent } from '../../../../services/api';
import { API } from '../../../constants/api';
import Loader from '../../Loader';
import { getLiveImage, getTempImage } from '../../../helpers/api';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './galerija.scss';

import GaleryModalPreview from './GaleryModalPreview';
import actionTypes from '../../../constants/actionTypes';
import { Context } from '../../../../contexts/Store';
import { toast } from 'react-toastify';

export default () => {
  const [makeRequest, setCanMakeRequest] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState(null);
  const [previewGallery, setPreviewGallery] = useState(false);
  const [state, dispatch] = useContext(Context);
  const [updateEvent, setUpdateEvent] = useState(false);

  const [selectedFiles, setSelectedFiles] = useState(
    state.sadrzaj_teksta_gallery
      ? state.sadrzaj_teksta_gallery.map(el => {
        return { ...el, imgs: [el.img], zig: null };
      })
      : [],
  );

  const { response, loading } = useSadrzajEvent(
    API.SADRZAJ.UPLOAD_MULTIPLE_PICTURES,
    'POST',
    makeRequest,
    {
      file: filesToUpload,
    },
    true,
  );

  useEffect(() => {
    if (response) {
      setCanMakeRequest(false);

      setSelectedFiles([
        ...selectedFiles,
        {
          izvor: '',
          opis: '',
          zig: false,
          imgs: response.data,
        },
      ]);
    }
  }, [response]);

  const onChangeHandler = event => {
    setFilesToUpload(event.target.files);
    setTimeout(() => {
      setCanMakeRequest(true);
    }, 100);
  };

  const renderImages = (imgs, hasZig, isLiveImage) => {
    return imgs.map(imgVal => {
      const imgSrc = !hasZig ? imgVal : imgVal.replace('.', '-z.');
      return (
        <div key={imgVal} className={'galerija-upload__container'}>
          <LazyLoadImage width={100} height={100}
                         src={isLiveImage ? getLiveImage('/' + imgSrc) : getTempImage('/' + imgSrc)}/>
          <span onClick={() => removeImage(imgVal)}>x</span>
        </div>
      );
    });
  };

  const getImagesPreviewArray = () => {
    const imagesArr = [];
    selectedFiles.map(file => {
      file.imgs.map(image => {
        const imgSrc = !file.zig ? image : image.replace('.', '-z.');
        imagesArr.push(getTempImage('/' + imgSrc));
      });
    });
    return imagesArr;
  };

  const removeImage = img => {
    const newArr = selectedFiles
      .map(file => {
        return {
          ...file,
          imgs: file.imgs.filter(el => el !== img),
        };
      })
      .filter(file => file.imgs.length > 0);
    setSelectedFiles(newArr);
  };

  const zigEvent = (elementIndex, isChecked) => {
    setSelectedFiles(
      selectedFiles.map((el, index) => {
        return { ...el, zig: elementIndex === index ? isChecked : el.zig };
      }),
    );
  };

  const { response: responseForUpdate, error: updateError, loading: loadingForUpdate } = useSadrzajEvent(
    API.SADRZAJ.UPDATE.ROOT +
    API.SADRZAJ.UPDATE.ID.replace(':id', state.isEditingTekst.id) +
    API.SADRZAJ.UPDATE.GALERIJA,
    'POST',
    updateEvent,
    {
      galerija: selectedFiles.map(el=> {console.log(el); return el}),
    },
    false,
  );

  useEffect(() => {
    if (responseForUpdate || updateError) {
      toast(responseForUpdate ? 'Galerija sačuvana.' : 'Greška.');
      sendDispatchSave();
    }
  }, [responseForUpdate]);

  const sendDispatchSave = () => {
    const arrayForSaving = [];
    selectedFiles.map(file => {
      file.imgs.map(img => {
        const imgSrc = !file.zig ? img : img.replace('.', '-z.');
        arrayForSaving.push({ izvor: file.izvor, opis: file.opis, img: imgSrc });
      });
    });

    dispatch({
      type: actionTypes.SADRZAJ_TEKSTA_SAVE_GALLERY,
      payload: arrayForSaving,
    });
  };

  return (
    <div style={{ textAlign: 'left', minWidth: '760px' }}>
      <GaleryModalPreview
        open={previewGallery}
        setPreviewGallery={setPreviewGallery}
        images={getImagesPreviewArray()}
      />
      <h2>Dodaj galeriju </h2>
      <hr/>
      <div className={'inline-txt'}>
        <span>Dodaj fotografiju/e:</span>
        <input type={'file'} accept="image/*" onChange={onChangeHandler} multiple="multiple"/>
        <hr/>
        { (loading || loadingForUpdate) && <Loader/>}
      </div>

      {selectedFiles &&
      selectedFiles.map((el, index) => {
        return (
          <div key={`galerija-${index}`}>
            <div className={'galerija-upload'}>{renderImages(el.imgs, el.zig, !!el.id)}</div>

            <div className={'inline-txt'}>
              <span>Dodaj izvor:</span>
              <input
                type={'text'}
                onChange={event => {
                  setSelectedFiles(
                    selectedFiles.map((file, currentIndex) => {
                      return {
                        ...file,
                        izvor: currentIndex === index ? event.target.value : file.izvor,
                      };
                    }),
                  );
                }}
                value={el.izvor}
              />
            </div>

            <div className={'inline-txt'}>
              <span>Dodaj opis:</span>
              <input
                type={'text'}
                onChange={event => {
                  setSelectedFiles(
                    selectedFiles.map((file, currentIndex) => {
                      return {
                        ...file,
                        opis: currentIndex === index ? event.target.value : file.opis,
                      };
                    }),
                  );
                }}
                value={el.opis}
              />
            </div>

            {el.zig !== null && (
              <div className={'inline-txt'}>
                <span>Dodaj žig</span>
                <input
                  type={'checkbox'}
                  checked={el.zig}
                  onChange={event => zigEvent(index, event.target.checked)}
                />
              </div>
            )}

            <hr/>
          </div>
        );
      })}

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {selectedFiles.length > 0 && (
          <button
            className={'btn-save'}
            onClick={() => {
              setPreviewGallery(true);
            }}>
            Preview
          </button>
        )}

        {selectedFiles.length > 0 && (
          <button
            className={'btn-save'}
            onClick={() => {
              if (state.isEditingTekst.hasValue) {
                setUpdateEvent(true);
                return;
              }
              sendDispatchSave();
            }}>
            Sačuvaj
          </button>
        )}
      </div>
    </div>
  );
};
