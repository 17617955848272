import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import SignIn from '../pages/signIn';
import Dashboard from '../pages/dashboard';
import Zaposleni from '../pages/zaposleni';
import ZaposleniNovi from '../pages/zaposleni/novi';
import ZaposleniPregled from '../pages/zaposleni/pregled';

import Statistika from '../pages/statistika';
import Arhiv from '../pages/arhiv';
import NoviSadrzajRoot from '../pages/noviSadrzaj';
import NovaRubrika from '../pages/noviSadrzaj/rubrika';
import NoviPrimarniSadrzaj from '../pages/noviSadrzaj/primarni';
import NoviSekundardniSadrzaj from '../pages/noviSadrzaj/sekundardni';
import PrimarniSadrzajEdit from '../pages/noviSadrzaj/primarni/tekst';

import KomentPanel from '../pages/komentPanel';
import Servisi from '../pages/servisi';
import Marketing from '../pages/marketing';
import Opcije from '../pages/opcije';
import ZaposleniAnalitika from '../pages/zaposleni/pregled/analitika';
import PushNotifikacije from '../pages/push-notifikacije';
import UrediSadrzaj from '../pages/urediSadrzaj';
import PozicionirajSadrzaj from '../pages/urediSadrzaj/pozicioniraj';
import RepozicionirajSadrzaj from '../pages/urediSadrzaj/repozicioniraj';
import Banners from '../pages/baneri';
import GalleryPreview from '../common/components/GalleryPreview';

import { API } from '../common/constants/api';
import { sadrzajType } from '../common/constants/constants';

export default function Routes() {
    return (
        <Switch>
            <Route path="/" exact component={SignIn}/>
            <Route path={API.DASHBOARD} component={Dashboard} isPrivate/>

            <Route path={API.ZAPOSLENI.ROOT + API.ZAPOSLENI.PREGLED} exact component={ZaposleniPregled} isPrivate/>
            <Route path={API.ZAPOSLENI.ROOT + API.ZAPOSLENI.NOVI} exact component={ZaposleniNovi} isPrivate/>
            <Route path={API.ZAPOSLENI.ROOT} component={Zaposleni} exact isPrivate/>
            <Route path={API.ZAPOSLENI.ROOT + API.ZAPOSLENI.SINGLE} exact component={ZaposleniAnalitika} isPrivate/>

          <Route path={API.BANERI_SOLO} exact component={Banners} isPrivate/>

          <Route path={API.STATISTIKA} exact component={Statistika} isPrivate/>
            <Route path={API.ARHIV} exact component={Arhiv} isPrivate/>

            <Route path={API.GALLERY_PREVIEW.ROOT} exact component={GalleryPreview} isPrivate/>

            <Route path={API.NOVI_SADRZAJ.ROOT} exact component={NoviSadrzajRoot} isPrivate/>
            <Route path={API.NOVI_SADRZAJ.ROOT + API.NOVI_SADRZAJ.RUBRIKA} exact component={NovaRubrika} isPrivate/>
            <Route
                path={API.NOVI_SADRZAJ.ROOT + API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.ROOT}
                exact
                component={NoviPrimarniSadrzaj}
                isPrivate
            />
            <Route
                path={
                    API.NOVI_SADRZAJ.ROOT +
                    API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.ROOT +
                    API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.TEKST
                }
                exact
                component={PrimarniSadrzajEdit}
                isPrivate
            />
            <Route
                path={
                    API.NOVI_SADRZAJ.ROOT +
                    API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.ROOT +
                    API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.TEKST +
                    API.SADRZAJ.SINGLE
                }
                exact
                component={PrimarniSadrzajEdit}
                isPrivate
            />

            <Route
                path={
                    API.NOVI_SADRZAJ.ROOT +
                    API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.ROOT +
                    API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.FOTO
                }
                exact
                component={() => PrimarniSadrzajEdit({ tipSadrzaja: sadrzajType.FOTO })}
                isPrivate
            />

            <Route
                path={
                    API.NOVI_SADRZAJ.ROOT +
                    API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.ROOT +
                    API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.FOTO +
                    API.SADRZAJ.SINGLE
                }
                exact
                component={() => PrimarniSadrzajEdit({ tipSadrzaja: sadrzajType.FOTO })}
                isPrivate
            />

            <Route
                path={
                    API.NOVI_SADRZAJ.ROOT +
                    API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.ROOT +
                    API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.VIDEO
                }
                exact
                component={() => PrimarniSadrzajEdit({ tipSadrzaja: sadrzajType.VIDEO })}
                isPrivate
            />

            <Route
                path={
                    API.NOVI_SADRZAJ.ROOT +
                    API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.ROOT +
                    API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.VIDEO +
                    API.SADRZAJ.SINGLE
                }
                exact
                component={() => PrimarniSadrzajEdit({ tipSadrzaja: sadrzajType.VIDEO })}
                isPrivate
            />

            <Route
                path={
                    API.NOVI_SADRZAJ.ROOT +
                    API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.ROOT +
                    API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.LIVEBLOG
                }
                exact
                component={() => PrimarniSadrzajEdit({ tipSadrzaja: sadrzajType.LIVE_BLOG })}
                isPrivate
            />

            <Route
                path={
                    API.NOVI_SADRZAJ.ROOT +
                    API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.ROOT +
                    API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.LIVEBLOG +
                    API.SADRZAJ.SINGLE
                }
                exact
                component={() => PrimarniSadrzajEdit({ tipSadrzaja: sadrzajType.LIVE_BLOG })}
                isPrivate
            />

            <Route
                path={
                    API.NOVI_SADRZAJ.ROOT +
                    API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.ROOT +
                    API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.KOLUMNA
                }
                exact
                component={() => PrimarniSadrzajEdit({ tipSadrzaja: sadrzajType.KOLUMNA })}
                isPrivate
            />

            <Route
                path={
                    API.NOVI_SADRZAJ.ROOT +
                    API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.ROOT +
                    API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.KOLUMNA +
                    API.SADRZAJ.SINGLE
                }
                exact
                component={() => PrimarniSadrzajEdit({ tipSadrzaja: sadrzajType.KOLUMNA })}
                isPrivate
            />

            <Route
                path={API.NOVI_SADRZAJ.ROOT + API.NOVI_SADRZAJ.SEKUNDARDNI_SADRZAJ.ROOT}
                exact
                component={NoviSekundardniSadrzaj}
                isPrivate
            />

            <Route
                path={API.UREDI_SADRZAJ.ROOT}
                exact
                component={UrediSadrzaj}
                isPrivate
            />

                <Route
                    path={API.UREDI_SADRZAJ.ROOT+ API.UREDI_SADRZAJ.POZICIONIRAJ+API.UREDI_SADRZAJ.RUBRIKA}
                    exact
                    component={PozicionirajSadrzaj}
                    isPrivate
                />

            <Route
                path={API.UREDI_SADRZAJ.ROOT+ API.UREDI_SADRZAJ.POZICIONIRAJ}
                exact
                component={PozicionirajSadrzaj}
                isPrivate
            />

            <Route
                path={API.UREDI_SADRZAJ.ROOT + API.UREDI_SADRZAJ.REPOZICIONIRAJ}
                exact
                component={RepozicionirajSadrzaj}
                isPrivate
            />

                <Route
                    path={API.UREDI_SADRZAJ.ROOT + API.UREDI_SADRZAJ.REPOZICIONIRAJ+API.UREDI_SADRZAJ.RUBRIKA}
                    exact
                    component={RepozicionirajSadrzaj}
                    isPrivate
                />

            <Route path={API.PUSH_NOTIFIKACIJE} exact component={PushNotifikacije} isPrivate/>

            <Route path={API.KOMENT_PANEL} exact component={KomentPanel} isPrivate/>
            <Route path={API.SERVISI} exact component={Servisi} isPrivate/>
            <Route path={API.MARKETING} exact component={Marketing} isPrivate/>
            <Route path={API.OPCIJE} exact component={Opcije} isPrivate/>

            <Route component={SignIn}/>
        </Switch>
    );
}
