import React, { useContext, useEffect } from 'react';
import { Context } from '../../contexts/Store';
import { checkVowels } from '../../common/helpers/strings';
import { useRubrikeEvent } from '../../services/api';
import { API } from '../../common/constants/api';
import { METHOD_TYPES } from '../../common/helpers/api';
import actionTypes from '../../common/constants/actionTypes';

const namesToCheck = [
    {name:'aleksandar', value: 'Aleksandre'},
    {name: 'tarik', value: 'Tariče'},
    {name: 'vuk', value: 'Vuče'},
    {name: 'andrej', value: 'Andrej'},
    {name: 'petar', value: 'Petre'},
    {name: 'matej', value: 'Matej'},
    {name: 'oleg', value: 'Oleže'},
]
export default () => {
    const [state,dispatch] = useContext(Context)

    const usernameSplit = state.user.username.split(" ");
    const prettyName = usernameSplit.length > 1 && usernameSplit[0].toLowerCase();
    const name =
        namesToCheck.find( el => el.name === prettyName) || prettyName+(checkVowels(prettyName) ? "":"e")

    const { response: rubrikeResponse } = useRubrikeEvent(
        API.RUBRIKE.SVE,
        METHOD_TYPES.GET,
        state.ucitaneRubrike === undefined || state.ucitaneRubrike === null,
        null,
        false
    );

    useEffect(() => {
        if (rubrikeResponse) {
            dispatch({ type: actionTypes.UCITAJ_RUBRIKE, payload: rubrikeResponse.data });
        }
    }, [rubrikeResponse]);

    return <div>
        <h1 style={{textAlign:'center',textTransform:'capitalize'}}>
            Dobrodošli {name}
        </h1>
    </div>
}
