import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../../../contexts/Store';
import Loader from '../../Loader';
import { useSadrzajEvent } from '../../../../services/api';
import { API } from '../../../constants/api';
import { getTempImage } from '../../../helpers/api';
import { generateImageEndString } from '../../../helpers/strings';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { toast } from 'react-toastify';

export default () => {
    const [state,dispatch] = useContext(Context);
    const [selectedFile, setSelectedFile] = useState({
        img: {
            value: null,
            error: '',
            preview: null
        },
        executeRequest : false
    });
    const onChangeHandler = event => {
        setSelectedFile({
            ...selectedFile,
            state: 'loading',
            img: { value: event.target.files[0], error: '', preview: null }
        });
    };
    //IZJAVA_DANA

    // AKO JE SEKUNDARNDI SADRZAJ
    // OVO JE ENDPOINT ZA CUVANJE
    const {response : saveResponse, loading : saveLoading, error } = useSadrzajEvent(
        API.SADRZAJ.SEKUNDARDNI_SADRZAJ.ROOT+API.SADRZAJ.SEKUNDARDNI_SADRZAJ.IZJAVA_DANA,
         'POST',
         selectedFile.executeRequest,
        {
            path: selectedFile.img.preview
        },false
    );

    useEffect(() => {
        if(saveResponse || error){
            if(error){
                setSelectedFile({...selectedFile,executeRequest: false})
                toast("Greška - "+error);
            }else {
                toast("Izjava dana sačuvana.");
            }
        }
    },[saveResponse,error]);

    const { response, loading } = useSadrzajEvent(
        API.SADRZAJ.UPLOAD_MULTIPLE_PICTURES,
        'POST',
        selectedFile.img.value,
        {
            file: [selectedFile.img.value]
        },
        true
    );

    useEffect(() => {
        if (response) {
            setSelectedFile({
                ...selectedFile,
                img: { value: null, error: '', preview: response.data[0], state: null }
            });
        }
    }, [response]);

    const imagePath =
        selectedFile.img.preview &&
        generateImageEndString(selectedFile.img.preview, selectedFile.zig, selectedFile.profilnaFotografija);

    const previewImage = () => {
        window.open(getTempImage('/' + imagePath), '_blank', 'height=500,width=500');
    };

    return (
        <div>
            <h2>Dodaj izjavu dana </h2>
            <hr />
            <div className={'inline-txt'}>
                <span>
                    Dodaj fajl: <span className={'error'}> {selectedFile.img.error}</span>
                </span>
                <input type={'file'} accept="image/*" onChange={onChangeHandler} />
                {(loading || saveLoading) && <Loader />}
            </div>

            {selectedFile.img.preview && (
                <div className={'new-photo__quick-preview'}>
                    <LazyLoadImage width={150} height={150} src={getTempImage('/' + imagePath)} />
                    <span
                        onClick={() => {
                            setSelectedFile({
                                ...selectedFile,
                                img: {
                                    value: null,
                                    error: '',
                                    preview: null
                                }
                            });
                        }}
                    >
                        X
                    </span>
                </div>
            )}

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {selectedFile.img.preview !== null && (
                    <button className={'btn-save'} onClick={previewImage}>
                        Preview
                    </button>
                )}

                {selectedFile.img.preview && (
                    <button
                        className={'btn-save'}
                        onClick={() => {
                            setSelectedFile({...selectedFile,executeRequest: true})
                        }}>
                        Sačuvaj
                    </button>
                )}
                <button
                    className={'btn-close'}
                    onClick={() => {
                        dispatch({ type: 'HIDE_MODAL' });
                    }}>
                    Zatvori
                </button>
            </div>
        </div>
    );
};
