import './FixedCardList.scss';
import React, { useContext } from 'react';
import { IMAGE_SIZES } from '../helpers/strings';
import SmallCard from './SmallCard';
import { ContextPozicije } from '../../contexts/Pozicioniranje';

export default ({
    hasPicture = true,
    rowNumber = 3,
    additionalClass = '',
    imageModifier = IMAGE_SIZES.WIDTH_113,
    additionalClassNames = null,
    txtContainerClassName = null,
    rows = [],
    rubrikaType
}) => {
    const joinedClassName = additionalClass === '' ? '' : ' ' + additionalClass;


    return (
        <div className={'fixed-cardList-container' + joinedClassName}>
            {rows.map((el, index) => {
                return (
                    <SmallCard
                        includeImage={hasPicture}
                        imageModifier={imageModifier}
                        additionalClassNames={additionalClassNames}
                        txtContainerClassName={txtContainerClassName}
                        key={'el-t3-' + index}
                        position={el}
                        rubrikaType={rubrikaType}
                    />
                );
            })}
        </div>
    );
};
