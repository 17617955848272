import React, { useState } from 'react';

export default ({
    sadrzaj,
    setSadrzaj,
    object,
    naslov = 'Autor(i):',
    placeholder = 'Pretražite autora',
    inputEvent
}) => {
    const [inputTag, setInputTag] = useState('');

    const addTag = val => {
        const tagExists = sadrzaj[object].values.find(tag => tag === val);

        if (!tagExists && val === '') {
            setInputTag('');
            return;
        }
        setSadrzaj({
            ...sadrzaj,
            [object]: {
                error: '',
                values: tagExists ? [...sadrzaj[object].values] : [...sadrzaj[object].values, val]
            }
        });
        setInputTag('');
    };

    const removeTag = kword => {
        const newTags = sadrzaj[object].values.filter(word => word !== kword);

        setSadrzaj({
            ...sadrzaj,
            [object]: {
                error: '',
                values: newTags
            }
        });
    };

    return (
        <div className={'new-tekst__tags'} style={{ marginTop: '16px', marginBottom: '16px' }}>
            <h2>
                {naslov} <span className={'error'}>{sadrzaj[object].error}</span>
            </h2>
            <input
                type={'text'}
                placeholder={placeholder}
                value={inputTag}
                onChange={event => setInputTag(event.target.value)}
                onKeyUp={event => {
                    if (inputEvent) {
                        inputEvent(event.target.value);
                    } else {
                        if (event.key === 'Enter') {
                            addTag(event.target.value);
                        }
                    }
                }}
            />
            <div className={'new-tekst__tags__container'}>
                {sadrzaj[object].values.map(kword => {
                    return (<div key={typeof kword === "string" ? kword : kword.id} className={'new-tekst__tag'}>
                        {typeof kword === "string" ? kword : kword.username}
                        <span className={'new-tekst__tag__close'} onClick={() => removeTag(kword)}>
                            x
                        </span>
                    </div>)
                })
                }
            </div>
        </div>
    );
};
