import React, { useContext, useState, useEffect } from 'react';
import './anketa.scss';
import ModalCloseBtn from '../../modalCloseBtn';
import anketaValidator from './anketaValidator';
import AnketaModalPreview from './AnketaModalPreview';
import { anketaTypes, chartTypes as chartType } from '../../../constants/constants';
import actionTypes from '../../../constants/actionTypes';
import { Context } from '../../../../contexts/Store';
import { useSadrzajEvent } from '../../../../services/api';
import { API } from '../../../constants/api';
import Loader from '../../Loader';
import { toast } from 'react-toastify';
import moment from 'moment';


export default ({tipAnkete = anketaTypes.TEKST}) => {

    const [state,dispatch] = useContext(Context);
    const[anketaType,setTipeAnketa] = useState(tipAnkete);
    const[sekundardniSadrzajAnketa,setSekundardniSadrzajAnketa] = useState(tipAnkete === anketaTypes.SEKUNDARDNI_SADRZAJ);
    const[updateEvent,setUpdateEvent] = useState(false);

    const [anketa, setAnketa] = useState({
        voteCount: 0,
        datumObjave: null,
        pitanje: {
            error: '',
            value: state.sadrzaj_teksta_anketa ?  state.sadrzaj_teksta_anketa.pitanje : ''
        },
        odgovori: state.sadrzaj_teksta_anketa ? state.sadrzaj_teksta_anketa.odgovori.map(odgovor=> {
            return {...odgovor,value: odgovor.odgovor, error: ''}
        }) : [
            {
                value: '',
                error: ''
            },
            {
                value: '',
                error: ''
            }
        ],
        chartType: state.sadrzaj_teksta_anketa ? state.sadrzaj_teksta_anketa.chartType : chartType.BAR_CHART
    });

    const calculateVoteCount = (answers) => {
        let sum = 0;
        for (const answer of answers){
            if(answer["odgovorVotes"]){
                sum += answer.odgovorVotes.length
            }
        }
        return sum;
    }
    // AKO JE SEKUNDARNDI SADRZAJ
    // OVO JE ENDPOINT ZA CUVANJE
    const {response, loading, error } = useSadrzajEvent(
        API.SADRZAJ.SEKUNDARDNI_SADRZAJ.ROOT+API.SADRZAJ.SEKUNDARDNI_SADRZAJ.ANKETA,
        anketa.pitanje.value === '' ? 'GET' : 'POST',
        tipAnkete = anketaTypes.SEKUNDARDNI_SADRZAJ && sekundardniSadrzajAnketa,
        {
            pitanje: anketa.pitanje.value,
            odgovori: anketa.odgovori.map(el => {return el.value}),
            chartType: anketa.chartType
        },false
    );
      useEffect(() => {
         if(response || error){
             setSekundardniSadrzajAnketa(false);
             if(response && response.data){
                 // INITIAL LOAD
                 if(anketa.pitanje.value === ''){
                     setAnketa({
                         voteCount: response.data.odgovori && response.data.odgovori.length > 0 ? calculateVoteCount(response.data.odgovori) : 0,
                         datumObjave: moment(response.data.createdAt).format('DD-MM-YYYY HH:mm'),
                         pitanje: {
                             value: response.data.pitanje,
                             error: ''
                         },
                         odgovori: response.data.odgovori.map( odgovor => {
                             {return {...odgovor,value: odgovor.odgovor, error: '', voteCount: odgovor.odgovorVotes && odgovor.odgovorVotes.length > 0 ? odgovor.odgovorVotes.length : 0}}
                         }),
                         chartType: response.data.chartType === 0 ? chartType.BAR_CHART : chartType.PIE_CHART
                     })
                 }else {
                     toast(response.data);
                 }
             }
         }
        },[response,error]);


    const { response: responseForUpdate, error: updateError, loading: loadingForUpdate } = useSadrzajEvent(
        API.SADRZAJ.UPDATE.ROOT +
        API.SADRZAJ.UPDATE.ID.replace(':id', state.isEditingTekst.id) +
        API.SADRZAJ.UPDATE.ANKETA,
        'POST',
        updateEvent,
        {
            anketa: {
                pitanje: anketa.pitanje.value,
                odgovori: anketa.odgovori.map(el => {return el.value}),
                chartType: anketa.chartType,
            }
        },
        false
    );

    useEffect(() => {
        if (responseForUpdate || updateError) {
            toast(responseForUpdate ? 'Anketa izmjenjena.' : 'Greška.');
            sendDispatchSave();
        }
    }, [responseForUpdate]);

    const dodajOdgovor = () => {
        setAnketa({ ...anketa, odgovori: [...anketa.odgovori, { value: '',error: ''}] });
    };

    const ukloniOdgovor = inputIndex => {
        const odgovori = anketa.odgovori.filter((el, index) => index !== inputIndex);
        setAnketa({ ...anketa, odgovori: odgovori });
    };

    const izmjeniOdgovor = (newValue, inputIndex) => {
        const odgovori = anketa.odgovori.map((el, index) => {
            return index === inputIndex ? {value: newValue, error:''} : el;
        });
        setAnketa({ ...anketa, odgovori: odgovori });
    };

    const sendDispatchSave = () => {
        dispatch({
            type: actionTypes.SADRZAJ_TEKSTA_SAVE_ANKETA,
            payload: {
                pitanje: anketa.pitanje.value,
                odgovori: anketa.odgovori.map(el => {return {odgovor:el.value,value:el.value,error:''}}),
                chartType: anketa.chartType,
            }
        });
    }

    const saveAnketa = () => {
        const validateAnketa = anketaValidator({anketa,setAnketa});
        if(validateAnketa){
            if(anketaType === anketaTypes.SEKUNDARDNI_SADRZAJ){
                setSekundardniSadrzajAnketa(true);
            }else{
                if (state.isEditingTekst.hasValue) {
                    setUpdateEvent(true);
                    return;
                }
                sendDispatchSave();
            }
        }
    };

    const previewAnketaEvent = () => {
        if(anketaValidator({anketa,setAnketa})){
            setPreviewAnketa(true)
        }
    }
    const [previewAnketa, setPreviewAnketa] = useState(false);

    return (
        <div className={'anketa-edit'}>
            <AnketaModalPreview
                open={previewAnketa}
                setPreviewAnketa={setPreviewAnketa}
                anketa={anketa}
            />
            <h2>Anketa</h2>
            <hr />

            {
                loading || loadingForUpdate ? <Loader/> : <>
                    <p style={{marginBottom: "12px"}}><b>Datum objave:</b> {anketa.datumObjave ?? ""}</p>
                    <p><b>Ukupan broj glasova:</b> {anketa.voteCount ?? ""}</p>
                    <hr/>
                    <div className={'inline-txt'}>
                        <span>
                    <b>Dodaj pitanje:</b>
                </span>{' '}
                        <input
                            type={'text'}
                            placeholder={'Pitanje...'}
                            value={anketa.pitanje.value}
                            onChange={event =>
                                setAnketa({ ...anketa, pitanje: { error: '', value: event.target.value } })
                            }
                        />
                        <span className={'error'}>{anketa.pitanje.error}</span>
                    </div>

                    {anketa.odgovori.map((odgovor, index) => {
                        return (
                            <div className={'inline-txt'} key={`odgovor-${index}`}>
                                <span className={'votC'}><b>Glasovi:</b> {odgovor.voteCount}</span>
                                <span> Dodaj odgovor {index + 1}: </span>{' '}
                                <input
                                    value={odgovor.value}
                                    onChange={e => {
                                        izmjeniOdgovor(e.target.value, index);
                                    }}
                                />
                                {index > 1 ? (
                                    <button style={{ marginLeft: '10px' }} onClick={() => ukloniOdgovor(index)}>
                                        {' '}
                                        Ukloni{' '}
                                    </button>
                                ) : null}
                                <span className={'error'}>{odgovor.error}</span>
                            </div>
                        );
                    })}

                    <button className={'add-btn'} onClick={dodajOdgovor}>
                        <span role={"img"} aria-label={'plus'}>➕</span> Dodaj još odgovora
                    </button>

                    <div className={'inline-txt'} style={{ marginTop: '12px',display:'block' }}>
                        <span>Odaberi prikaz rezultata:</span>
                        <input type="radio" name={'chart'} checked={anketa.chartType === chartType.PIE_CHART}
                               onChange={(event => {
                                   setAnketa({...anketa,chartType : chartType.PIE_CHART})
                               })}
                        /> Piechart
                        <input type="radio" name={'chart'} style={{marginLeft:'12px'}}
                               checked={anketa.chartType === chartType.BAR_CHART}
                               onChange={(event => {
                                   setAnketa({...anketa,chartType : chartType.BAR_CHART})
                               })}
                        /> Barchart (Standardni)
                    </div>

                    <div>
                        <button className={'btn-save'} onClick={()=>previewAnketaEvent()}>Preview</button>
                        <button
                            className={'btn-save'}
                            onClick={() => {
                                saveAnketa();
                            }}>
                            Sačuvaj
                        </button>
                    </div>

                </>
            }


        </div>
    );
};
