import React, { useContext } from 'react';
import { Context } from '../../../contexts/Store';
import actionTypes from '../../constants/actionTypes';

export default () => {
  const [state, dispatch] = useContext(Context)
  return (
    <>
      <h2>Jeste li sigurni da želite obrisati ovaj profil</h2>
      <p className={'custom-modal__info'}>
            Sledeći postupak će povući sa sobom i brisanje svih analtičkih i statističkih podataka o korisnku.
      </p>

      <button className={'custom-modal__btn-da'}
        onClick={
          () => {
            dispatch({ type: 'HIDE_MODAL' })
            dispatch({ type: actionTypes.DELETE_ZAPOSLENI_CONFIRM, payload: true })
          }
        }
      >Da</button>
      <button className={'custom-modal__btn-ne'} onClick={() => {
        dispatch({ type: 'HIDE_MODAL' })
      }}>Zatvori</button>
    </>

  )
}
