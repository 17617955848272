import React, { useContext, useEffect, useState } from 'react';
import './opcije.scss';
import { Context } from '../../contexts/Store';
import { useZaposleniEvent } from '../../services/api';
import { API } from '../../common/constants/api';
import Loader from 'react-loader-spinner';
import validator from '../zaposleni/novi/validator';
import { toast } from 'react-toastify';

const states = {
    UPDATING_USER: 'UPDATING_USER',
    NO_ACTION: null
};

const allNotifications = [
    {
        id: 1,
        name: 'Spoljne izmjene u članku'
    },
    {
        id: 2,
        name: 'Vaš članak nije objavljen'
    },
    {
        id: 3,
        name: 'Vaš sačuvani članak je objavljen'
    },
    {
        id: 4,
        name: 'Vaš sačuvani članak je obrisan'
    },
    {
        id: 5,
        name: 'Vaš članak je repozicioniran'
    }
];

export default () => {
    const [state, dispatch] = useContext(Context);

    const [userState, setUserState] = useState({
        notifikacije: state.user.notificationTypes,
        image: {
            value: '',
            preview: ''
        },
        username: { error: '', value: state.user.username },
        password: { error: '', value: '' },
        sakrijIme: state.user.sakrijIme,
        actionState: states.NO_ACTION
    });

    const { response, error, loading } = useZaposleniEvent(
        API.ZAPOSLENI.SELF,
        'POST',
        userState.actionState === states.UPDATING_USER,
        {
            file: userState.image.value,
            username: userState.username.value,
            password: userState.password.value,
            sakrijIme: userState.sakrijIme,
            notifikacije: userState.notifikacije
        },
        true
    );

    useEffect(() => {
        if (response || error) {
            setUserState({ ...userState, actionState: states.NO_ACTION });
            if(response){
                const token = response.data.access_token;
                if(token){
                    dispatch({ type: 'LOGIN', payload: token });
                    toast('Izmjene sačuvane.');
                }else {
                    toast(response.data.error);
                }
            }
        }
    }, [response,error]);

    const notificationEvent = (event, selectedNotification) => {
        const exists = userState.notifikacije.find(el => el.id === selectedNotification.id);

        if (event.target.checked) {
            if (!exists){
                setUserState({ ...userState, notifikacije: [...userState.notifikacije, selectedNotification] });
            }
        } else {
            if (exists){
                setUserState({
                    ...userState,
                    notifikacije: userState.notifikacije.filter(el => el.id !== selectedNotification.id)
                });
            }

        }
    };

    const renderNotifikacije = () =>
        allNotifications.map(notifikacija => {
            return (
                <div className={'opcije__item'} key={notifikacija.name}>
                    <span>{notifikacija.name}</span>
                    <input
                        type={'checkbox'}
                        checked={userState.notifikacije.find(notif => notif.id === notifikacija.id)}
                        onChange={event => notificationEvent(event, notifikacija)}
                    />
                </div>
            );
        });

    const imagePreview = event => {
        const fileData = event.target.files[0];
        if (event.target.files && event.target.files[0]) {
            var reader = new FileReader();
            reader.onload = function(e) {
                setUserState({ ...userState, image: { value: fileData, preview: e.target.result } });
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    };

    const sendData = () => {
        const isValid = validator(setUserState, userState, true);

        if (isValid) {
            setUserState({ ...userState, actionState: states.UPDATING_USER });
        }
    };

    const notifikacijeContainer = () => {
        return !['ceo','administrator'].includes(state.user.role) &&
            <div className={'opcije__notifikacije'}>
                <h3>Omogući notifikacije:</h3>
                <hr />
                {renderNotifikacije()}
            </div>
    }

    return (
        <div className={'opcije'}>
            <h1>OPCIJE</h1>


            {loading ? (
                <Loader type="Oval" color="#00BFFF" height={32} width={32} />
            ) : (
                <div className={'opcije__container'} style={{ }}>
                    {notifikacijeContainer()}

                    <div className={'opcije__profil'}>
                        <h3>Profil:</h3>
                        <hr />
                        <div className={'opcije__item'}>
                            <span>Sakrij ime pri objavi</span>
                            <input
                                type={'checkbox'}
                                checked={userState.sakrijIme}
                                onChange={e => setUserState({ ...userState, sakrijIme: e.target.checked })}
                            />
                        </div>
                        <div className={'inline-txt'}>
                            <span>korisničko ime:</span>
                            <input
                                type={'text'}
                                value={userState.username.value}
                                onChange={e =>
                                    setUserState({ ...userState, username: { value: e.target.value, error: '' } })
                                }
                            />
                            <p className={'error'}>{userState.username.error}</p>
                        </div>

                        <div className={'inline-txt'}>
                            <span>Password:</span>
                            <input
                                type={'password'}
                                value={userState.password.value}
                                onChange={e =>
                                    setUserState({ ...userState, password: { value: e.target.value, error: '' } })
                                }
                            />
                            <p className={'error'}>{userState.password.error}</p>
                        </div>

                        <div className={'opcije__item'}>
                            <span>Promijeni fotografiju</span>
                            <input type={'file'} accept={'image/*'} onChange={imagePreview} />
                            {userState.image.preview && (
                                <p>
                                    <img src={userState.image.preview} className={'opcije__item__img'} />
                                </p>
                            )}
                        </div>

                        <div>
                            <button className={'btn-save'} onClick={() => sendData()}>
                                Sačuvaj izmjene
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
