import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../contexts/Store';
import ArhivFilters from './ArhivFilters';
import './Arhiv.scss';
import ArhivResults from './ArhivResults';
import { API } from '../../common/constants/api';
import { METHODS, TIPOVI_PRETRAGE } from '../../common/constants/constants';
import { useSadrzajEvent } from '../../services/api';
import Loader from '../../common/components/Loader';
import moment from 'moment';

export const emptyRubrika = { id: -1, name: '', path: '', podrubrika: [{ id: -32, name: '', path: '' }] };
export const defaultSearchState = {
    tipPretrage: [
        {
            tip: TIPOVI_PRETRAGE.OBJAVLJENI,
            selected: true
        },
        {
            tip: TIPOVI_PRETRAGE.DRAFTS,
            selected: false
        },
        {
            tip: TIPOVI_PRETRAGE.Izbrisani,
            selected: false
        }
    ],
    currentPage: 0,
    naslov: '',
    autori: {
        values: [],
        error: ''
    },
    dateFrom: moment()
        .subtract(1, 'month')
        .toDate(),
    dateTo: moment()
        .endOf('day')
        .toDate(),
    tema: '',
    kljucnaRijec: '',
    recivedData: [],
    doRequest: true
};
export default () => {
    const [state, dispatch] = useContext(Context);
    const [searchState, setSearchState] = useState({
        ...defaultSearchState,
        rubrike: state.ucitaneRubrike ? [emptyRubrika, ...state.ucitaneRubrike] : null,
        selectedRubrika: state.ucitaneRubrike ? emptyRubrika : null,
        selectedPodrubrika: state.ucitaneRubrike ? emptyRubrika.podrubrika[0] : null,
        returnWithPagination: false,
        sortByNajnovije: true
    });

    const { error, response, loading } = useSadrzajEvent(
        API.ARHIV,
        METHODS.POST,
        searchState.doRequest,
        searchState,
        false
    );

    useEffect(() => {
        if (error) {
            setSearchState({ ...searchState, doRequest: false });
            return;
        }
        //code
        if (response) {
            setSearchState({
                ...searchState,
                recivedData: response.data.list ? response.data.list.map(el => {
                    return { ...el, dropdown: false };
                }): [],
                pageCount: response.data.pageCount ? response.data.pageCount : 0,
                doRequest: false
            });
        }
    }, [response, error]);

    const tipPretrageChange = tip => {
        const newTipoviPretrage = searchState.tipPretrage.map(el => {
            return { ...el, selected: tip === el.tip };
        });

        setSearchState({
            ...searchState,
            tipPretrage: newTipoviPretrage,
            doRequest: true
        });
    };
    const renderHeader = () => {
        return (
            <div className={'arhiv__header'}>
                <span className={'arhiv__header__title'}>Arhiv</span>
                <div>
                    {searchState.tipPretrage.map((el, index) => {
                        return (
                            <button
                                onClick={() => tipPretrageChange(el.tip)}
                                key={'tip-pretrage-' + index}
                                className={`btn-save ${el.selected ? ' btn-save--selected' : ''}`}>
                                {el.tip}
                            </button>
                        );
                    })}
                </div>
            </div>
        );
    };
    const selectedTipPretrage = searchState.tipPretrage.find(el => el.selected).tip;

    return (
        <div className={'arhiv'}>
            {renderHeader()}

            <ArhivFilters
                selectedTipPretrage={selectedTipPretrage}
                searchState={searchState}
                setSearchState={setSearchState}
            />

            {loading ? (
                <Loader />
            ) : (
                <ArhivResults
                    setSearchState={setSearchState}
                    searchState={searchState}
                    selectedTipPretrage={selectedTipPretrage}
                />
            )}
        </div>
    );
};
