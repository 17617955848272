import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

import DefaultLayout from '../pages/layout/default';
import AuthLayout from '../pages/layout/auth';
import { Context } from '../contexts/Store';

export default function RouteWrapper({ component: Component, isPrivate, ...rest }) {
    const [state] = useContext(Context);
    const signed = state.userToken !== null;

    /**
     * Redirect user to SignIn page if he tries to access a private route
     * without authentication.
     */
    if (isPrivate && !signed) {
        return <Redirect to="/" />;
    }

    /**
     * Redirect user to Main page if he tries to access a non private route
     * (SignIn or SignUp) after being authenticated.
     */
    if (!isPrivate && signed) {
        return <Redirect to="/dashboard" />;
    }

    const Layout = signed ? AuthLayout : DefaultLayout;

    /**
     * If not included on both previous cases, redirect user to the desired route.
     */
    return (
        <Route
            {...rest}
            render={props => (
                <Layout>
                    <Component {...props} />
                </Layout>
            )}
        />
    );
}

RouteWrapper.propTypes = {
    isPrivate: PropTypes.bool,
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
};

RouteWrapper.defaultProps = {
    isPrivate: false
};
