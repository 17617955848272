import React, { memo, useRef, useEffect } from 'react';
import CKEditor from 'ckeditor4-react';
import { ckEditorConfig } from '../../../../common/constants/misc';

CKEditor.editorUrl = '/ckeditor/ckeditor.js';


export default memo(({ sadrzaj, setSadrzaj }) => {
  const myRef = useRef(null);
  const handleChange = event => {
    setSadrzaj(prevsState => {
      return { ...prevsState, tekst: { value: event.editor.getData(), error: '' } };
    });
  };

  const addListener = (ckeditor) => {
    ckeditor.on('beforeCommandExec', function(event) {
      if (event.data.name === 'paste') {
        event.editor._.forcePasteDialog = true;
      }
      if (event.data.name === 'pastetext' && event.data.commandData.from === 'keystrokeHandler') {
        event.cancel();
      }
    });
  };

  return (
    <CKEditor
      onBeforeLoad={CKEDITOR => {
        CKEDITOR.disableAutoInline = true;
      }}
      onInstanceReady={instance => {
        addListener(instance.editor)
      }}
      type="classic"
      ref={myRef}
      data={sadrzaj.tekst.value}
      onChange={handleChange}
      config={ckEditorConfig(450)}
    />
  );
});
