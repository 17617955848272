import React from 'react';
import LeaderboardCard from '../../../../common/components/LeaderboardCard';
import TwoBigThreeSmallCards from '../../../../common/components/TwoBigThreeSmallCards';
import { rubrikeTypes } from '../../../../common/helpers/strings';
import BigCard from '../../../../common/components/BigCard';
import SectionTitle from '../../../../common/components/SectionTitle';
import TwoHalfRowCards from '../../../../common/components/TwoHalfRowCards';
import FixedCardList from '../../../../common/components/FixedCardList';
import OneBigTwoMediumCards from '../../../../common/components/OneBigTwoMediumCards';

export default ({ title , podrubrikaId }) => {
    return (
        <div style={{ marginTop: '30px' }}>
            <SectionTitle title={title} />
            <div className={'fostav-container'}>
                <TwoHalfRowCards positions={['1', '2']} rubrikaType={podrubrikaId} />
                <div className={'fostav-container__bottom'}>
                    <FixedCardList hasPicture={true} rows={['3', '4', '5']} rubrikaType={podrubrikaId} />
                    <BigCard position={'6'} rubrikaType={podrubrikaId} />
                    <BigCard position={'7'} rubrikaType={podrubrikaId} />
                </div>
            </div>
        </div>
    );
};
