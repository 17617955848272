import validateMail from '../../../utils/validateMail';

export default (setData, data, isEditingUser = false) => {
  if (data.username && data.username.value === '' || data.username.value.length < 8) {
    setData(prevState => ({
      ...prevState, username: { ...prevState.username, error: 'Morate unijeti username' }
    }))
    return false
  }
  if (data.password && data.password.value === '' && isEditingUser === false) {
    setData(prevState => ({
      ...prevState, password: { ...prevState.password, error: 'Morate unijeti password' }
    }))
    return false
  }
  if (data.email && data.email.value === '') {
    setData(prevState => ({
      ...prevState, email: { ...prevState.email, error: 'Morate unijeti email' }
    }))
    return false
  }

  if (data.email && !validateMail(data.email.value)) {
    setData(prevState => ({
      ...prevState, email: { ...prevState.email, error: 'Morate unijeti pravilan email' }
    }))
    return false
  }

  if (data.datumAktivacije && data.datumAktivacije === data.datumIsteka.value) {
    setData({ ...data, datumIsteka: { value: data.datumIsteka.value, error: 'Datumi ne mogu biti isti' } })
    return false
  }
  if (data.datumAktivacije && data.datumAktivacije.getTime() > data.datumIsteka.value.getTime()) {
    setData({
      ...data,
      datumIsteka: { value: data.datumIsteka.value, error: 'Datum isteka mora biti veci od datuma aktivacije.' }
    })
    return false
  }

  return true
}
