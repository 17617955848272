import React, { useContext } from 'react';
import { Context } from '../../../contexts/Store';

export default () => {

    const [state, dispatch] = useContext(Context)


    return <div style={{marginTop : '32px'}}>
        <p>Jeste li sigurni da želite postaviti rubriku</p>
        <p> pod imenom <b>{state.showModal.data}</b> </p>

        <div style={{display:'flex',justifyContent:'center'}}>
            <button className={'btn-save'}>Da</button>
            <button>Ne</button>

        </div>
    </div>;
};
