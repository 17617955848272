import React, { useContext, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import Select from '../../common/components/Select';
import { useRubrikeEvent } from '../../services/api';
import { API } from '../../common/constants/api';
import { METHOD_TYPES } from '../../common/helpers/api';
import actionTypes from '../../common/constants/actionTypes';
import { Context } from '../../contexts/Store';
import './ArhivFilters.scss';
import { TIPOVI_PRETRAGE } from '../../common/constants/constants';
import SearchAutor from './SearchAutor';
import moment from 'moment';
import { rubrikeToSkip } from '../noviSadrzaj/primarni/tekst/Kategorije';

const emptyRubrika = { id: -1, name: '', path: '', podrubrika: [{ id: -32, name: '', path: '' }] };

export default React.memo(({ selectedTipPretrage = TIPOVI_PRETRAGE.SAV_SADRZAJ, searchState, setSearchState,resetTipPretrage }) => {
    const [state, dispatch] = useContext(Context);

    const { response: rubrikeResponse } = useRubrikeEvent(
        API.RUBRIKE.SVE,
        METHOD_TYPES.GET,
        state.ucitaneRubrike === null,
        null,
        false
    );

    useEffect(() => {
        if (rubrikeResponse) {
            dispatch({ type: actionTypes.UCITAJ_RUBRIKE, payload: rubrikeResponse.data });
            setSearchState({
                ...searchState,
                rubrike: [emptyRubrika, ...rubrikeResponse.data],
                selectedRubrika: emptyRubrika,
                selectedPodrubrika: emptyRubrika.podrubrika
            });
            // setRubrike(rubrikeResponse.data);
        }
    }, [rubrikeResponse]);

    const rubrikaChangeEvent = event => {
        console.log(event.target.value);
        setSearchState({
            ...searchState,
            selectedRubrika: searchState.rubrike.find(el => el.name === event.target.value)
        });
        //setSelectedRubrika(rubrike.find(el => el.name === event.target.value));
    };
    const podrubrikaChangeEvent = event => {
        const selectedPodrubrikaName = event.target.value;
        const selectedPodrubrika = searchState.selectedRubrika.podrubrika.find(
            el => el.name === selectedPodrubrikaName
        );

        setSearchState({ ...searchState, selectedPodrubrika: selectedPodrubrika });
    };

    const tipPretrageChangeEvent = tip => {
        const tipString = tip.target.value;

        const newTekstStatus = searchState.tekstStatus.map(el => {
            return { ...el, selected: el.value === tipString };
        });

        setSearchState({ ...searchState, tekstStatus: newTekstStatus });
    };
    return (
        <div className={'arhiv-filters'}>
            <div className={'arhiv-filters__top'}>
                <div className={'arhiv-filters__naslov'}>
                    <input
                        type={'text'}
                        value={searchState.naslov}
                        onChange={event => setSearchState({ ...searchState, naslov: event.target.value })}
                    />
                </div>

                <div className={'arhiv-filters__author'}>
                    <SearchAutor searchState={searchState} setSearchState={setSearchState} />
                </div>

                <div className={'arhiv-filters__datum-od'}>
                    <DatePicker
                        selected={searchState.dateFrom}
                        onChange={date => {
                            setSearchState({ ...searchState, dateFrom: date });
                        }}
                        dateFormat="dd.MM.Y"
                    />
                </div>

                <div className={'arhiv-filters__datum-do'}>
                    <DatePicker
                        dateFormat="dd.MM.Y"
                        selected={searchState.dateTo}
                        onChange={date => {
                            setSearchState({ ...searchState, dateTo: date });
                        }}
                    />
                </div>

                <div className={'arhiv-filters__rubrika'}>
                    <Select
                        data={searchState.rubrike.filter(rubrika => rubrikeToSkip(rubrika))}
                        changeEvent={rubrikaChangeEvent}
                        selectedItemId={searchState.selectedRubrika ? searchState.selectedRubrika.id : null}
                    />
                </div>
                <div className={'arhiv-filters__podrubrika'}>
                    <Select
                        data={searchState.selectedRubrika && searchState.selectedRubrika.podrubrika}
                        changeEvent={podrubrikaChangeEvent}
                        selectedItemId={searchState.selectedPodrubrika ? searchState.selectedPodrubrika.id : null}
                    />
                </div>

                <div className={'arhiv-filters__tema'}>
                    <input
                        type={'text'}
                        value={searchState.tema}
                        onChange={event => setSearchState({ ...searchState, tema: event.target.value })}
                    />
                </div>

                <div className={'arhiv-filters__kword'}>
                    <input
                        type={'text'}
                        value={searchState.kljucnaRijec}
                        onChange={event => setSearchState({ ...searchState, kljucnaRijec: event.target.value })}
                    />
                </div>
            </div>

            <div className={'arhiv-filters__footer'}>
                <button className={'btn-save'} onClick={() => setSearchState({ ...searchState, doRequest: true })}>
                    Pretraži
                </button>
                <button
                    className={'btn-save'}
                    onClick={() => {
                        const newTipoviPretrage = searchState.tipPretrage.map((el,index) => {
                            return { ...el, selected: index === 0 };
                        });
                        setSearchState({
                            ...searchState,
                            tipPretrage: newTipoviPretrage,
                            doRequest: true,
                            naslov: '',
                            tema: '',
                            kljucnaRijec: '',
                            selectedRubrika: searchState.rubrike[0],
                            selectedPodrubrika: searchState.rubrike[0].podrubrika[0],
                            dateFrom: moment()
                                .subtract(1, 'month')
                                .toDate(),
                            dateTo: moment()
                                .endOf('day')
                                .toDate(),
                            autori: {
                                values: [],
                                error: ''
                            }
                        });
                    }}>
                    Resetuj filtere
                </button>
            </div>
        </div>
    );
});
