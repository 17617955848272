import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './Authed.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Context } from '../../../contexts/Store';
import jwtDecode from 'jwt-decode';
import { Link } from 'react-router-dom';
import { API } from '../../../common/constants/api';
import rolePages from '../../../common/constants/rolePages';
import facePlaceholder from '../../../common/images/face-placeholder.png';

import { useHistory } from 'react-router';
import { ToastContainer } from 'react-toastify';
import Modal from '../../../common/components/modal';
import { getUserAvatar } from '../../../common/helpers/api';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useAuthorDetailsEvent, useSelfEvent } from '../../../services/api';

export default function AuthLayout({ children }) {
    const [state, dispatch] = useContext(Context);
    const history = useHistory();
    const[userCheck,setUserCheck] = useState(true)
    const user = jwtDecode(state.userToken);

    const logout = () => {
        dispatch({ type: 'LOGOUT' });
        history.push('/');
    };

    const { loading, response } = useSelfEvent(
      API.ZAPOSLENI.ROOT,
      userCheck
    );

  useEffect(() => {
    if(response){
      setUserCheck(undefined)
      const decoded = jwtDecode(response.data.access_token);
      const active = decoded.active.data[0] === 1
      if(!active){
        logout();
      }
    }
  },[response]);



  const currentPage = history.location.pathname;
    const isUrediSadrzaj = currentPage.toLowerCase().includes('uredi-sadrzaj/');

    const selectedNavObject = rolePages[user.role].find(option => currentPage.includes(option.link));

    const renderNavOptions =
        selectedNavObject !== undefined && selectedNavObject.navOptions !== undefined
            ? selectedNavObject.navOptions.map(option => {
                  return (
                      <Link
                          key={option.link}
                          to={option.link}
                          className={currentPage.includes(option.link) ? 'active' : ''}>
                          {option.name}
                      </Link>
                  );
              })
            : null;

    return (
        <div className={'main-page'}>
            <ToastContainer className={'my-toast'} />
            <Modal open={false} openDefault={false} />

            <div className={'main-page__sidebar'}>
                <Link to={'/opcije'}>
                    <LazyLoadImage
                        height={96}
                        src={user.image !== '' ? getUserAvatar(user.image) : facePlaceholder}
                        width={96}
                        className={'main-page__sidebar__avatar'}
                        effect={'blur'}
                    />
                </Link>

                {!isUrediSadrzaj ? (
                    <div className={'main-page__opcije'}>
                        <Link to={API.OPCIJE}>
                            <button className={'main-page__opcije__btn'}>Korisničke opcije</button>
                        </Link>
                        <button className={'main-page__opcije__btn'} onClick={logout}>
                            Log out
                        </button>
                    </div>
                ) : null}

                <div className={`main-page__track ${isUrediSadrzaj ? 'main-page__track--uredi-sadrzaj' : ''} `}>
                    <div className={'main-page__author'} style={{ textTransform: 'capitalize' }}>
                        <p>{user.username}</p>
                        <b>{user.role}</b>
                    </div>

                    <div className={'main-page__track-options'}>{renderNavOptions}</div>
                </div>

                <div className={'main-page__side-nav'}>
                    {rolePages[user.role].map(nav => {
                      if(nav.link.indexOf("https://") > -1){
                        return <a href={nav.link} target={"_blank"} rel="noreferrer"    key={nav.link} >{nav.name}</a>
                      }
                        return (
                            <Link
                                key={nav.link}
                                to={nav.link}
                                className={currentPage.toLowerCase().includes(nav.link.toLowerCase()) ? 'active' : ''}>
                                {nav.name}
                            </Link>
                        );
                    })}
                </div>
            </div>
            <div className={`main-page__content ${isUrediSadrzaj ? 'main-page__content--uredi-sadrzaj' : ''}`}>
                {children}
            </div>
        </div>
    );
}

AuthLayout.propTypes = {
    children: PropTypes.element.isRequired
};
