import './LeaderboardCard.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import placeholder from '../images/naslovna_placeholder.jpg';

import React, { useContext } from 'react';
import actionTypes from '../constants/actionTypes';
import { ContextPozicije } from '../../contexts/Pozicioniranje';
import { checkPositionSelected } from '../helpers/strings';

export default ({ position = '1', rubrikaType }) => {
    const [state, dispatch] = useContext(ContextPozicije);
    return (
        <a className={'leaderboard-card'}>
            <div
                className={`leaderboard-card__txt-container ${
                    checkPositionSelected(state, position, rubrikaType) ? 'wireframeSelected' : ''
                }`}
                onClick={() => {
                    dispatch({
                        type: actionTypes.SELECTED_WIREFRAME_POSITION,
                        payload: {
                            position: position,
                            rubrika: rubrikaType
                        }
                    });
                }}>
                {position}
            </div>
            <LazyLoadImage src={placeholder} className={`leaderboard-card__image`} alt={''} />
        </a>
    );
};
