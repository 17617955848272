import React from 'react';
import Modal from 'react-responsive-modal';
import ImageSlider from '../../ImageSlider';

export default React.memo(({ open = false, setPreviewGallery, images  }) => {


    return (
        <Modal
            open={open}
            onClose={() => setPreviewGallery(false)}
            classNames={{
                modal: 'modal-gallery-preview'
            }}
            overlayId={'galery-preview-modal'}
        >
            <ImageSlider images={images}/>
        </Modal>
    );
});
