import './TwoBigThreeSmallCards.scss';
import BigCard from './BigCard';
import FixedCardList from './FixedCardList';
import React from 'react';

export default ({ hasPicture , bigPositions = ['3','4'], smallPositions = ['5','6','7'], rubrikaType}) => {
    return (
        <div className={'two-big-three-small'}>
            <BigCard position={bigPositions[0]} rubrikaType={rubrikaType}/>
            <BigCard position={bigPositions[1]} rubrikaType={rubrikaType}/>
            <FixedCardList hasPicture={hasPicture} rows={smallPositions} rubrikaType={rubrikaType}/>
        </div>
    );
};
