import React, { memo, useEffect, useState } from 'react';
import { useZaposleniEvent } from '../../services/api';
import { API } from '../../common/constants/api';
import { METHOD_TYPES } from '../../common/helpers/api';
import MultiLineInput from '../noviSadrzaj/primarni/tekst/MultiLineInput';
import { debounce } from '../../utils/commonFunctions';

export default memo(({ searchState, setSearchState }) => {
    const [autorToSearch, setAutorToSearch] = useState(null);
    const [fetchedAutori, setFetchedAutori] = useState([]);

    const { response: autoriResponse } = useZaposleniEvent(
        API.ZAPOSLENI.PREGLED,
        METHOD_TYPES.GET,
        fetchedAutori.length === 0
    );

    const autoriInputEvent = debounce(async nameToSearch => {
        setFetchedAutori(
            fetchedAutori.map(autor => {
                return {
                    ...autor,
                    visible: nameToSearch !== '' && autor.username.toLowerCase().includes(nameToSearch.toLowerCase())
                };
            })
        );
        setAutorToSearch(nameToSearch);
    }, 100);

    useEffect(() => {
        if (autoriResponse) {
            setFetchedAutori(
                autoriResponse.data.map(autor => {
                    return { ...autor, visible: false };
                })
            );
        }
    }, [autoriResponse]);

    const selectAutor = autor => {
        const autorExists = searchState.autori.values.find(
            selectedAutor => selectedAutor.username.toLowerCase() === autor.username.toLowerCase()
        );
        if (autorExists) return;

        const autori = [...searchState.autori.values, autor];

        setSearchState({ ...searchState, autori: { values: autori, error: '' } });
        setFetchedAutori(
            fetchedAutori.map(autor => {
                return { ...autor, visible: false };
            })
        );
    };



    return (
        <div>
            <MultiLineInput
                sadrzaj={searchState}
                setSadrzaj={setSearchState}
                object={'autori'}
                inputEvent={autoriInputEvent}
                naslov={'Autor'}
            />
            <div className={'new-tekst__autori-dropdown'}>
                {fetchedAutori &&
                fetchedAutori.length > 0 &&
                fetchedAutori.map(el => {
                    return (
                        el.visible && (
                            <div key={el.username} onClick={e => selectAutor(el)}>
                                {el.username}
                            </div>
                        )
                    );
                })}
                {fetchedAutori.find(autor => autor.visible) && (
                    <span
                        className={'close'}
                        onClick={() => {
                            setFetchedAutori(
                                fetchedAutori.map(autor => {
                                    return { ...autor, visible: false };
                                })
                            );
                        }}>
                        x
                    </span>
                )}
            </div>
        </div>
    );
});
