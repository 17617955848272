import React, { createContext, useReducer } from 'react';
import Reducer from '../reducer';
import jwtDecode from 'jwt-decode';
import moment from 'moment';

const initialState = {
  posts: [],
  error: null,
  userToken: localStorage.getItem('userToken') || null,
  showModal: false,
  user: null,
  ucitaneRubrike : JSON.parse(localStorage.getItem('ucitaneRubrike')) || null,
  isEditingTekst: {
    hasValue: false,
    id:-1,
  },
};

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  if(state.userToken !== null){
    state.user = jwtDecode(state.userToken)
    if(moment.now() > moment.unix(state.user.exp) ){
      dispatch({ type: 'LOGOUT' })
    }
  }

  return (
    <Context.Provider value={[state, dispatch]}>
      {children}
    </Context.Provider>
  )
}

export const Context = createContext(initialState)
export default Store
