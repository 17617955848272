import React, { useContext, useState } from 'react';
import './novaRubrika.scss';
import actionTypes from '../../../common/constants/actionTypes';
import { Context } from '../../../contexts/Store';

export default () => {
  const [data, setData] = useState({
    naslovRubrike: { value: '', error: '' }
  })

  const [state,dispatch] = useContext(Context);


  const saveRubrika = () => {
    if (data.naslovRubrike.value.length < 3) {
      setData({ ...data, naslovRubrike: { value: data.naslovRubrike.value, error: 'Morate unijeti naslov' } })
    }else{
      dispatch({ type: 'SHOW_MODAL', payload: { name: actionTypes.SAVE_RUBRIKA, data: data.naslovRubrike.value } })
    }
  }
  return <div className={'nova-rubrika'}>
    <h2>Nova rubrika</h2>
    <h4>Naslov rubrike</h4>
    <div><input type={'text'} value={data.naslovRubrike.value} onChange={ (e) =>
      setData({ ...data, naslovRubrike: { error: '', value: e.target.value } })
    }/>
    <span className={'error'}>{data.naslovRubrike.error}</span>
    </div>
    <div>
      <span>Specijalno markirati</span>
      <input type={'checkbox'} checked={false} className={'nova-rubrika__marker'}/>
    </div>
    <div>
      <p>Rubriku dalje sortirati van početne stranice u: </p>
      <select>
        <option>Infos</option>
        <option>Sport</option>
      </select>
    </div>

    <button className={'btn-save'} onClick={saveRubrika}>Postavi rubriku</button>
  </div>
}
