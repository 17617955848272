import { API } from './api';

const baseRole = [
  {
    name: 'Idi na portal',
    link: API.SITE_URL
  },
  {
    name: 'Arhiv',
    link: API.ARHIV
  }
];


const novinar = [
  {
    name: 'Dodaj novi sadržaj',
    link: API.NOVI_SADRZAJ.ROOT,
    navOptions: [
      // {
      //   name: 'Dodaj rubriku',
      //   link: API.NOVI_SADRZAJ.ROOT + API.NOVI_SADRZAJ.RUBRIKA
      // },
      {
        name: 'Dodaj primarni sadržaj',
        link: API.NOVI_SADRZAJ.ROOT + API.NOVI_SADRZAJ.PRIMARNI_SADRZAJ.ROOT
      },
      {
        name: 'Dodaj sekundardni sadržaj',
        link: API.NOVI_SADRZAJ.ROOT + API.NOVI_SADRZAJ.SEKUNDARDNI_SADRZAJ.ROOT
      }
    ]
  },
  {
    name: 'Uredi sadržaj',
    link: API.UREDI_SADRZAJ.ROOT
  },
  {
    name: 'Koment panel',
    link: API.KOMENT_PANEL
  },
  {
    name: 'Push notifikacije',
    link: API.PUSH_NOTIFIKACIJE
  },
  ...baseRole
]

const urednik = [
  ...novinar,
  // {
  //   name: 'Analitika i statistika',
  //   link: API.STATISTIKA
  // }
]

const administrator = [
  // {
  //   name: 'Servisi',
  //   link: API.SERVISI
  // },
  {
    name: 'Baneri',
    link: API.BANERI_SOLO
  },
  ...urednik
]

const ceo = [
  {
    name: 'Zaposleni',
    link: API.ZAPOSLENI.ROOT,
    navOptions: [
      {
        name: 'Dodaj novog zaposlenog',
        link: API.ZAPOSLENI.ROOT + API.ZAPOSLENI.NOVI
      },
      {
        name: 'Pregled zaposlenih',
        link: API.ZAPOSLENI.ROOT + API.ZAPOSLENI.PREGLED
      }
    ]
  },
  // {
  //   name: 'Statistika i analitika',
  //   link: API.STATISTIKA
  // },
  ...administrator
]

const rolePages = {
  ceo: ceo,
  novinar: novinar,
  urednik: urednik,
  administrator: administrator
}

export default rolePages
