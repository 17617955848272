import React from 'react';

export default ({sadrzaj,setSadrzaj}) => {
    return <>
        <h2>
            Nadnaslov <span className={'error'}>{sadrzaj.nadnaslov.error}</span>
        </h2>
        <input
            autoFocus={true}
            type={'text'}
            placeholder={'Nadnaslov...'}
            onChange={ev => {
                setSadrzaj({ ...sadrzaj, nadnaslov: { value: ev.target.value, error: '' } });
            }}
            value={sadrzaj.nadnaslov.value}
        />
        <h2>
            Naslov <span className={'error'}>{sadrzaj.naslov.error}</span>
        </h2>
        <input
            type={'text'}
            placeholder={'Naslov...'}
            onChange={ev => {
                setSadrzaj({ ...sadrzaj, naslov: { value: ev.target.value, error: '' } });
            }}
            value={sadrzaj.naslov.value}
        />
        <h2>
            Podnaslov <span className={'error'}>{sadrzaj.podnaslov.error}</span>
        </h2>
        <textarea
            placeholder={'Podnaslov...'}
            rows={5}
            onChange={ev => {
                setSadrzaj({ ...sadrzaj, podnaslov: { value: ev.target.value, error: '' } });
            }}
            value={sadrzaj.podnaslov.value}
        />
    </>;
};
