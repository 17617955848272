export default ({anketa,setAnketa}) => {
    if(anketa.pitanje.value === ''){
        setAnketa({...anketa,pitanje: {...anketa.pitanje, error: 'Unesite pitanje.'}});
        return false;
    }
    const odgovori = anketa.odgovori.map(odgovor => {
        return {value : odgovor.value,error: odgovor.value === '' ? "Unesite odgovor" : ''}
    })
    const hasErrors = odgovori.find( odgovor => odgovor.error !== '');
    if(hasErrors){
        setAnketa({...anketa,odgovori: odgovori});
        return false;
    }else {
        return true;
    }
}
