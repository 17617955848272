import React, { useContext, useEffect, useState } from 'react';
import { useSadrzajEvent } from '../../../../services/api';
import { API } from '../../../constants/api';
import Loader from '../../Loader';
import { getLiveImage, getTempImage } from '../../../helpers/api';
import { Context } from '../../../../contexts/Store';
import './newPhoto.scss';
import actionTypes from '../../../constants/actionTypes';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { sadrzajType } from '../../../constants/constants';
import { generateImageEndString } from '../../../helpers/strings';
import { toast } from 'react-toastify';

export default () => {
    const [state, dispatch] = useContext(Context);

    const isKolumna = state.showModal.data && state.showModal.data.tipSadrzaja === sadrzajType.KOLUMNA;

    const [selectedFile, setSelectedFile] = useState({
        img: {
            value: null,
            error: '',
            preview: state.sadrzaj_teksta_photo ? state.sadrzaj_teksta_photo.img : null
        },
        izvor: state.sadrzaj_teksta_photo ? state.sadrzaj_teksta_photo.izvor : '',
        opis: state.sadrzaj_teksta_photo ? state.sadrzaj_teksta_photo.opis : '',
        zig: false,
        profilnaFotografija: false,
        state: null,
        canShowZigField: !state.sadrzaj_teksta_photo,
        hasNewPhotoUploaded: false,
        doUpdateAction: false
    });

    const { response, loading } = useSadrzajEvent(
        API.SADRZAJ.UPLOAD_MULTIPLE_PICTURES,
        'POST',
        selectedFile.img.value,
        {
            file: [selectedFile.img.value],
            isKolumna: isKolumna
        },
        true
    );

    useEffect(() => {
        if (response) {
            setSelectedFile({
                ...selectedFile,
                img: { value: null, error: '', preview: response.data[0], state: null }
            });
        }
    }, [response]);

    const onChangeHandler = event => {
        setSelectedFile({
            ...selectedFile,
            state: 'loading',
            img: { value: event.target.files[0], error: '', preview: null },
            canShowZigField: true,
            hasNewPhotoUploaded: true
        });
    };
    const imagePath =
        selectedFile.img.preview &&
        generateImageEndString(selectedFile.img.preview, selectedFile.zig, selectedFile.profilnaFotografija);

    const miniPreviewImagePath =
        state.isEditingTekst.hasValue && !selectedFile.hasNewPhotoUploaded
            ? getLiveImage('/' + imagePath)
            : getTempImage('/' + imagePath);

    const previewImage = () => {
        window.open(miniPreviewImagePath, '_blank', 'height=500,width=500');
    };

    const { response: responseForUpdate, error: updateError, loading: loadingForUpdate } = useSadrzajEvent(
        API.SADRZAJ.UPDATE.ROOT +
            API.SADRZAJ.UPDATE.ID.replace(':id', state.isEditingTekst.id) +
            API.SADRZAJ.UPDATE.NASLOVNA,
        'POST',
        selectedFile.doUpdateAction,
        {
            naslovnaFotografija: {
                img: imagePath,
                izvor: selectedFile.izvor,
                opis: selectedFile.opis,
                zig: selectedFile.zig
            }
        },
        false
    );
    useEffect(() => {
        if (responseForUpdate || updateError) {
            toast(responseForUpdate ? 'Fotografija izmjenjena' : 'Greška.');
            sendDispatchSave();
        }
    }, [responseForUpdate]);

    const sendDispatchSave = () =>{
        dispatch({
            type: actionTypes.SADRZAJ_TEKSTA_SAVE_PHOTO,
            payload: {
                img: imagePath,
                izvor: selectedFile.izvor,
                opis: selectedFile.opis,
                zig: selectedFile.zig
            }
        });
    };
    return (
        <div style={{ textAlign: 'left' }} className={'new-photo'}>
            <h2>Dodaj naslovnu fotografiju </h2>
            <hr />
            <div className={'inline-txt'}>
                <span>
                    Dodaj fotografiju: <span className={'error'}> {selectedFile.img.error}</span>
                </span>
                <input type={'file'} accept="image/*" onChange={onChangeHandler} />
                {(loading || loadingForUpdate) && <Loader />}
            </div>

            {selectedFile.img.preview && (
                <>
                    <div className={'new-photo__quick-preview'}>
                        <LazyLoadImage width={150} height={150} src={miniPreviewImagePath} />
                        <span
                            onClick={() => {
                                setSelectedFile({
                                    ...selectedFile,
                                    img: {
                                        value: null,
                                        error: '',
                                        preview: null
                                    }
                                });
                            }}>
                            X
                        </span>
                    </div>
                    <div className={'inline-txt'}>
                        <span>Dodaj izvor:</span>
                        <input
                            type={'text'}
                            value={selectedFile.izvor}
                            onChange={event => {
                                setSelectedFile({ ...selectedFile, izvor: event.target.value });
                            }}
                        />
                    </div>

                    <div className={'inline-txt'}>
                        <span>Dodaj opis:</span>
                        <input
                            type={'text'}
                            value={selectedFile.opis}
                            onChange={event => {
                                setSelectedFile({ ...selectedFile, opis: event.target.value });
                            }}
                        />
                    </div>

                    {selectedFile.canShowZigField && (
                        <div className={'inline-txt'}>
                            <span>Dodaj žig</span>
                            <input
                                type={'checkbox'}
                                checked={selectedFile.zig}
                                onChange={e => {
                                    setSelectedFile({ ...selectedFile, zig: e.target.checked });
                                }}
                            />
                        </div>
                    )}

                    {isKolumna && (
                        <div className={'inline-txt'}>
                            <span>Dodaj naslovnu fotografiju</span>
                            <input
                                type={'checkbox'}
                                checked={selectedFile.profilnaFotografija}
                                onChange={e => {
                                    setSelectedFile({ ...selectedFile, profilnaFotografija: e.target.checked });
                                }}
                            />
                        </div>
                    )}
                </>
            )}

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {selectedFile.img.preview !== null && (
                    <button className={'btn-save'} onClick={previewImage}>
                        Preview
                    </button>
                )}

                {selectedFile.img.preview && (
                    <button
                        className={'btn-save'}
                        onClick={() => {
                            if (state.isEditingTekst.hasValue) {
                                setSelectedFile({ ...selectedFile, doUpdateAction: true });
                                return;
                            }
                            sendDispatchSave();
                        }}>
                        Sačuvaj
                    </button>
                )}
            </div>
        </div>
    );
};
