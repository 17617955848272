import React, { useContext } from 'react';
import actionTypes from '../../../common/constants/actionTypes';
import { Context } from '../../../contexts/Store';

const actions = [
    {
        name: 'Anketa',
        MODAL_TYPE: actionTypes.SEKUNDARDNI_SADRZAJ_ANKETA
    },
    {
        name: 'Izjava dana',
        MODAL_TYPE: actionTypes.SEKUNDARDNI_SADRZAJ_IZJAVA_DANA
    }
];

export default () => {
    const [state,dispatch] = useContext(Context);

    return (
        <div className={'navigation-step1 flex-start'}>
            {actions.map(el => (
                <button
                    className={'btn-save'}
                    key={el.name}
                    onClick={() => {
                        dispatch({
                            type: actionTypes.SHOW_MODAL,
                            payload: { name: el.MODAL_TYPE, data: null }
                        });
                    }}>
                    {el.name}
                </button>
            ))}
        </div>
    );
};
