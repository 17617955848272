import React, { useContext, useEffect, useState } from 'react';
import RubrikaZaPozicioniranje from './rubrikaZaPozicioniranje';
import { rubrikeTypes } from '../../../common/helpers/strings';
import { useHistory, useRouteMatch } from 'react-router';
import GridNav from '../gridNav';
import { usePozicijeEvent } from '../../../services/api';
import { API } from '../../../common/constants/api';
import { METHOD_TYPES } from '../../../common/helpers/api';
import Loader from '../../../common/components/Loader';
import { toast } from 'react-toastify';
import { Context } from '../../../contexts/Store';

const pocetnaRubrike = [
    {
        title: 'Breaking news',
        rubrikaType: rubrikeTypes.BREAKING_NEWS,
        path: '/bnews',
        positionCount: 11
    },
    {
        title: 'Istraživali smo',
        rubrikaType: rubrikeTypes.ISTRAZIVALI_SMO,
        path: '/istrazivali_smo',
        positionCount: 2
    },
    {
        title: 'Infos',
        rubrikaType: rubrikeTypes.INFOS,
        path: '/infos',
        positionCount: 7
    },
    {
        title: 'Sport',
        rubrikaType: rubrikeTypes.SPORT,
        path: '/sport',
        positionCount: 11
    },
    {
        title: 'Dijaspora',
        rubrikaType: rubrikeTypes.DIJASPORA,
        path: '/dijaspora',
        positionCount: 6
    },
    {
        title: 'Magazin',
        rubrikaType: rubrikeTypes.MAGAZIN,
        path: '/magazin',
        positionCount: 8
    },
    {
        title: 'Svijet',
        rubrikaType: rubrikeTypes.SVIJET,
        path: '/svijet',
        positionCount: 7
    }
];
export default () => {
    const history = useHistory();
    const rubrika = useRouteMatch().params.rubrika;
    const isPocetna = !useRouteMatch().params.rubrika;
    const[globalState] = useContext(Context);
    const [pozicijeData, setPozicijeData] = useState({
        value: null,
        doRequest: true,
        rubrika: rubrika
    });

    const { response, error, loading } = usePozicijeEvent(
        API.SADRZAJ.POZICIJE.GET_POZICIJE_BY_RUBRIKA.replace(':rubrika', isPocetna ? 'pocetna' : rubrika),
        METHOD_TYPES.GET,
        pozicijeData.doRequest + useRouteMatch().params.rubrika,
        null
    );

    const { response: updateResponse, loading: updateLoading, error:errorUpdating } = usePozicijeEvent(
        API.SADRZAJ.POZICIJE.BULK_UPDATE,
        METHOD_TYPES.POST,
        pozicijeData.updateRequest,
      {...pozicijeData, rubrika: rubrika ?? 'pocetna'}
    );

    useEffect(() => {
        if (updateResponse) {
            toast('Izmjene sačuvane.');
            setPozicijeData({ ...pozicijeData, doRequest: false, updateRequest: false });
        }
        if(errorUpdating){
            toast('Greška u čuvanju.',{type: "error"});
            setPozicijeData({ ...pozicijeData, doRequest: false, updateRequest: false });
        }
    }, [updateResponse,errorUpdating]);
    useEffect(() => {
        if (response) {
            setPozicijeData({
                doRequest: false,
                updateRequest: false,
                toDelete: [],
                rubrika: pozicijeData.rubrika,
                value: response.data.map(pos => {
                    return { ...pos, newValue: pos.value };
                })
            });
        }
    }, [response]);

    const renderPocetnaRubrike = () => {
        return pocetnaRubrike.map((statickaRubrika, index) => {
            const data = pozicijeData.value
                .filter(pozicija => (pozicija.rubrika ? pozicija.rubrika.path : pozicija.podrubrika.path  ) === statickaRubrika.path)
                .sort((a, b) => (a.value > b.value ? 1 : -1));
            return (
                <RubrikaZaPozicioniranje
                    imeRubrike={statickaRubrika.title}
                    rubrikaType={statickaRubrika.rubrikaType}
                    key={`staticka-rubrika-${index}`}
                    data={data}
                    pozicijeData={pozicijeData}
                    setPozicijeData={setPozicijeData}
                    maxPositions={statickaRubrika.positionCount}
                />
            );
        })
    }

    const renderPodrubrike = () => {
        if(pozicijeData === null || pozicijeData.value === null || !globalState.ucitaneRubrike){
            return  <span/>
        }
        const currentRubrika = globalState.ucitaneRubrike.find(el => el.path.indexOf(rubrika)>-1);

         return currentRubrika.podrubrika.map((podrubrika,index) => {
             const data = pozicijeData.value
                 .filter(pozicija => (pozicija.rubrika ? pozicija.rubrika.path : pozicija.podrubrika.path  ) === podrubrika.path)
                 .sort((a, b) => (a.value > b.value ? 1 : -1));

             return (
                 <RubrikaZaPozicioniranje
                     imeRubrike={podrubrika.name}
                     key={`podrubrika-${index}`}
                     data={data}
                     pozicijeData={pozicijeData}
                     setPozicijeData={setPozicijeData}
                     maxPositions={7}
                 />
             );
         })
    }

    return (
        <div>
            <h2 style={{ textAlign: 'center', marginTop: '32px', marginBottom: '32px' }}>
                Repozicioniraj / Izbriši pozicije
            </h2>
            <div className={'wire-nav'} style={{ marginBottom: '24px', display: 'flex', alignItems: 'center' }}>
                <GridNav isPocetna={isPocetna} marginLeft={'60px'} navPath={API.UREDI_SADRZAJ.REPOZICIONIRAJ} />
                <button
                    className={'btn-save btn-save--green btn-save--right-absolute'}
                    onClick={() => {
                        setPozicijeData({ ...pozicijeData, updateRequest: true });
                    }}>
                    Sačuvaj izmjene
                </button>
                    <button className={'btn-save btn-save--right-absolute btn-save--down-32'} style={{top: "-20px" , left: "230px", fontWeight: "bold" }} onClick={()=>{
                        history.push(API.UREDI_SADRZAJ.ROOT+API.UREDI_SADRZAJ.POZICIONIRAJ);
                    }}>Nazad</button>
            </div>
            {loading || updateLoading ? (
                <div style={{display:'flex',justifyContent:'center',marginTop:'32px'}}><Loader /></div>
            ) : (
                pozicijeData.value !== null && isPocetna ? renderPocetnaRubrike() : renderPodrubrike()

            )}
        </div>
    );
};
