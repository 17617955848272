import React, { createContext, useReducer } from 'react';
import PozicioniranjeReducer from '../reducer/pozicioniranje';

const initialState = {
    positions: [],
    selectedWireFramePosition : null,
    selectedWireFrameRubrika : null,
    selectedSadrzaj: null,
    automatskoPozicioniranje: false,
};

const PozicioniranjeStore = ({ children }) => {
    const [state, dispatch] = useReducer(PozicioniranjeReducer, initialState);

    return <ContextPozicije.Provider value={[state, dispatch]}>{children}</ContextPozicije.Provider>;
};

export const ContextPozicije = createContext(initialState);
export default PozicioniranjeStore;
