import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../../contexts/Store';
import actionTypes from '../../constants/actionTypes';
import { useCommentsEvent } from '../../../services/api';
import { API } from '../../constants/api';
import Loader from '../Loader';
import { toast } from 'react-toastify';

export default (minHeight = '180px') => {
    const [state, dispatch] = useContext(Context);

    const [komentarObjekat, setKomentarObjekat] = useState({ ...state.showModal.data, shouldDelete: false });

    const { response, error, loading } = useCommentsEvent(
        API.KOMENTARI.DELETE_KOMENTAR,
        'POST',
        komentarObjekat.shouldDelete,
        komentarObjekat
    );

    useEffect(() => {
        if (response || error) {
            const status = response ? response.data : 'Greška - ' + error.toString();
            toast(status);
            if (error) {
                setKomentarObjekat({ ...komentarObjekat, shouldDelete: false });
            } else {
                dispatch({ type: 'HIDE_MODAL' });
                dispatch({ type: actionTypes.KOMENTAR_KORISNIKA_UPDATE_DELETE_STATUS, payload: komentarObjekat });
            }
        }
    }, [response, error]);

    const deleteKomentStyle = {
        paddingTop: '24px',
        border: '1px solid #00000024',
        padding: '24px',
        marginTop: '17px',
        borderRadius: '6px',
        width: '100%',
        minHeight: minHeight
    };

    return (
        <div style={{ padding: '32px', minWidth: '400px' }}>
            <h3>Delete komentara </h3>

            {loading ? (
                <Loader />
            ) : (
                <div>
                    <p style={deleteKomentStyle}>{komentarObjekat.commentDetail}</p>

                    <div>
                        <button
                            className={'custom-modal__btn-da'}
                            onClick={() => {
                                setKomentarObjekat({ ...komentarObjekat, shouldDelete: true });
                            }}
                            style={{ marginRight: '32px', marginTop: '22px' }}>
                            Potvrdi brisanje
                        </button>
                        <button
                            className={'custom-modal__btn-ne'}
                            onClick={() => {
                                dispatch({ type: 'HIDE_MODAL' });
                            }}>
                            Zatvori
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};
