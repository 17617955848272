export default (sadrzaj) => {
    return {
        nadNaslov: sadrzaj.nadnaslov.value,
        naslov: sadrzaj.naslov.value,
        podnaslov: sadrzaj.podnaslov.value,
        tekst: sadrzaj.tekst.value,
        teme: sadrzaj.teme.values,
        tipSadrzaja: sadrzaj.tipSadrzaja,
        liveBlogNewTekst: sadrzaj.liveBlogNewTekst,
        kwords: sadrzaj.kljucneRijeci.values,
        autori: sadrzaj.autori.values,
        rubrika: sadrzaj.selectedRubrika,
        podrubrika: sadrzaj.selectedPodrubrika,
        naslovnaFotografija: sadrzaj.NASLOVNA.value,
        galerija: sadrzaj.GALERIJA.value,
        dodatneOpcije: sadrzaj.DODATNE_OPCIJE.value,
        anketa: sadrzaj.ANKETA.value ? {
            chartType: sadrzaj.ANKETA.value.chartType,
            pitanje: sadrzaj.ANKETA.value.pitanje,
            odgovori: sadrzaj.ANKETA.value.odgovori.map(el => el.value),
        } : null,
        // kontaktUs: sadrzaj.KONTAKT.value,
        schedule: sadrzaj.SCHEDULE.value,
        createdAt: sadrzaj.createdAt ?? null,
        updatedAt: sadrzaj.updatedAt ?? null,
        status: sadrzaj.statusSadrzaja,
        endLiveBlog: sadrzaj.endLiveBlog
    }
}
