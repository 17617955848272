import React, { useContext, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Context } from '../../../../contexts/Store';
import actionTypes from '../../../constants/actionTypes';

export default () => {
    const [state, dispatch] = useContext(Context);
    const [stateDate, setStateDate] = useState({
        value: state.sadrzaj_teksta_schedule_date ? new Date(state.sadrzaj_teksta_schedule_date.dateValue) : null
    });


    return (
        <div style={{ width: '600px', padding: '32px' }}>
            <h2>Zakaži objavu</h2>
            <hr />
            <DatePicker
                showTimeSelect
                selected={stateDate.value !== null ? stateDate.value : null}
                inline
                onChange={date => {
                    setStateDate({ value: date });
                }}
                timeIntervals={5}
                timeCaption="time"
                timeFormat="HH:mm"
                dateFormat="dd.MM.Y h:mm aa"
            />
            <div style={{ marginTop: '32px' }}>
                <button
                    className={'btn-save'}
                    onClick={() => {
                        dispatch({
                            type: actionTypes.SADRZAJ_TEKSTA_SAVE_SHEDULE_DATE,
                            payload: {
                                dateValue: stateDate.value
                            }
                        });
                    }}>
                    Sačuvaj
                </button>
                <button className={'btn-close'} onClick={() => dispatch({ type: 'HIDE_MODAL' })}>
                    Zatvori
                </button>
            </div>
        </div>
    );
};
