import React, { useState } from 'react';
import './Pozicioniraj.scss';
import WireFrame from './WireFrame';
import NajnovijeVijesti from './NajnovijeVijesti';
import PozicioniranjeStore from '../../../contexts/Pozicioniranje';

export default () => {
    return (
        <PozicioniranjeStore>
            <div className={'pozicioniraj'}>
                <WireFrame  />
                <NajnovijeVijesti />
            </div>
        </PozicioniranjeStore>
    );
};
