import React from 'react';
import { API } from '../../common/constants/api';
import { wireframeRubrike } from './pozicioniraj/WireFrame';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';

export default ({ isPocetna , marginLeft='0px', navPath= API.UREDI_SADRZAJ.POZICIONIRAJ}) => {
    const urlParams = useRouteMatch();

    return <div style={{marginLeft}}>
        {wireframeRubrike.map(el => {
            return (
                <Link
                    to={API.UREDI_SADRZAJ.ROOT + navPath + el.path}
                    key={'render-rub-' + el.path}>
                    <button
                        className={`btn-save ${
                            el.path.indexOf(urlParams.params.rubrika) > -1 || (el.path === '/' && isPocetna)
                                ? 'btn-save--selected'
                                : ''
                        }`}>
                        {el.value}
                    </button>
                </Link>
            );
        })}
    </div>;
};
